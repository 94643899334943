
import React, { useState, useEffect } from 'react';
import { ClaySelect, ClayInput } from '@clayui/form';
import { getProductAndForms } from "../../../../api/nebProductService";
import { getTotalSelloutRelatedForms } from '../../../../api/nebFormTemplateService';
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NumericFormat } from 'react-number-format';
import { exportToPdf, exportToExcel } from './exportToPdf';
import { Row, Col, Card, Form, InputGroup, Modal, Button, Table, Badge, Accordion } from 'react-bootstrap';

const FuelBalanceTableCheckingDP = ({ agencyId, checkingReport, setTotalSellouts }) => {

  const [fbHeaders, setFBHeaders] = useState([]);
  const [fbRows, setFBRows] = useState([]);
  const [plantRatio, setPlantRatio] = useState(0);
  const [productAndFormsList, setProductAndFormsList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fuelValues, setFuelValues] = useState([]);
  const [productAndUnit, setProductAndUnit] = useState([]);
  const [inputsPopulated, setInputsPopulated] = useState(false);
  const [currentUnit, setCurrentUnit] = useState("");
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [totalSelloutRelatedForms, setTotalSelloutRelatedForms] = useState([]);
  const [reportBasicInfo, setReportBasicInfo] = useState();

  const numInputStyle = {
    textAlign: "right",
    width: "100%",
    height: "40px",
    marginRight: "5px",
    borderRadius: "5px",
    background: "#f8f8f8",
    color: "black"
  };

  const selectInputStyle = {
    width: "100%",
    height: "40px",
    marginRight: "5px",
    borderRadius: "5px",
    background: "#f8f8f8",
    color: "black"
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getProductAndForms(agencyId, 'FB');
        console.log('FETCHING P&F' + JSON.stringify(result, null, 2));
        setProductAndFormsList(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchTotalSelloutRelatedForms = async () => {
      try {
        const result = await getTotalSelloutRelatedForms();
        console.log('FETCHING TSR forms' + JSON.stringify(result, null, 2));
        setTotalSelloutRelatedForms(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    fetchTotalSelloutRelatedForms()
  }, []);


  const handleFuelValueChange = (
    e,
    index,
    productName,
    parentFormFieldCode,
    formFieldName,
    formCode,
    subtractive,
    inKtoe
  ) => {
    // Extract floatValue from e, with a fallback to 0
    const { floatValue = 0 } = e || {};

    // Ensure the value is a valid number or fallback to 0
    let value = !isNaN(floatValue) ? parseFloat(floatValue) : 0;

    console.log("inktoe: " + inKtoe);

    // Make value negative based on form type (subtractive)
    if (subtractive) {
      value = -Math.abs(value);
    }

    // Update state
    setFuelValues((prevFuelValues) => {
      // Check if an object with the matching index exists
      const exists = prevFuelValues.some((item) => item.index === index);

      if (exists) {
        // Update the existing object
        return prevFuelValues.map((item) => {
          if (item.index === index) {
            // Find the form based on parentFormFieldCode
            let formIndex = item.forms.findIndex(
              (form) => form.formCode === parentFormFieldCode
            );

            let updatedForms;

            if (formIndex !== -1) {
              // Update the existing form
              updatedForms = item.forms.map((form, idx) => {
                if (idx === formIndex) {
                  let updatedCategories = form.categories.map((category) => {
                    if (category.formCode === formCode) {
                      return {
                        formCode: formCode,
                        value: value,
                        description: formFieldName,
                      };
                    }
                    return category;
                  });

                  // If the category doesn't exist, add it
                  if (!updatedCategories.some((category) => category.formCode === formCode)) {
                    updatedCategories.push({
                      formCode: formCode,
                      value: value,
                      description: formFieldName,
                    });
                  }

                  return { ...form, categories: updatedCategories };
                }
                return form;
              });
            } else {
              // Add a new form if it doesn't exist
              updatedForms = [
                ...item.forms,
                {
                  formCode: parentFormFieldCode,
                  categories: [
                    {
                      formCode: formCode,
                      value: value,
                      description: formFieldName,
                    },
                  ],
                },
              ];
            }

            // Recalculate the balance based on the updated forms
            const balance = updatedForms.reduce(
              (sum, form) =>
                sum + form.categories.reduce((acc, category) => acc + (category.value || 0), 0),
              0
            );

            return {
              ...item,
              forms: updatedForms,
              balance: isNaN(balance) ? 0 : balance,
            };
          }
          return item;
        });
      } else {
        // Add a new object if it doesn't exist
        const newItem = {
          index: index,
          productName: productName,
          balance: value,
          forms: [
            {
              formCode: parentFormFieldCode,
              categories: [
                {
                  formCode: formCode,
                  value: value,
                  description: formFieldName,
                },
              ],
            },
          ],
          inKtoe: inKtoe,
        };

        // Recalculate the balance for the new item
        const balance = newItem.forms.reduce(
          (sum, form) =>
            sum + form.categories.reduce((acc, category) => acc + (category.value || 0), 0),
          0
        );

        return [...prevFuelValues, { ...newItem, balance: isNaN(balance) ? 0 : balance }];
      }
    });
  };

  //plant efficiency calculation
  useEffect(() => {
    console.log("fuels: " + JSON.stringify(fuelValues, null, 2))
    let f2Sum = 0;
    let f3Sum = 0;

    fuelValues.forEach((fuel) => {
      const product = productAndUnit.find(item => item.fieldName === fuel.productName);
      const inKtoe = product ? product.inKtoe : 0;
      fuel.forms.forEach((form) => {
        form.categories.forEach((category) => {
          if (category.formCode === "F2-PS") {
            // Convert to ktoe and ensure positive value
            f2Sum += Math.abs(category.value * inKtoe);
            console.log(`plantRatio: product: ${product}, value: ${category.value}, ktoe: ${inKtoe}`);
          }
          if (category.formCode === "F3-CO") {
            // Convert to ktoe and ensure positive value
            f3Sum += Math.abs(category.value * inKtoe);
            console.log(`plantRatio: product: ${product}, value: ${category.value}, ktoe: ${inKtoe}`);
          }
        });
      });
    });

    let percent = (f2Sum / f3Sum) * 100;

    setPlantRatio(isNaN(Number(percent)) ? 0 : Number(percent.toFixed(2)));
    console.log(" ratio fuel: " + percent);
  }, [fuelValues, productAndUnit])

  useEffect(() => {
    console.log("products: ", JSON.stringify(productAndUnit, null, 2));
  }, [productAndUnit]);

  const handleUnitChange = (e, index, productName, inKtoe) => {
    const { value: unit } = e.target;

    setProductAndUnit(prevProductAndUnit => {
      // Check if an object with matching productName exists
      const exists = prevProductAndUnit.some(item => item.fieldName === productName);

      if (exists) {
        // Update the existing object
        return prevProductAndUnit.map(item => {
          if (item.fieldName === productName) {
            return {
              ...item,
              unit: unit,
              inKtoe: inKtoe
            };
          } else {
            return item;
          }
        });
      } else {
        // Add a new object
        const newItem = {
          fieldName: productName,
          unit: unit,
          inKtoe: inKtoe
        };

        return [
          ...prevProductAndUnit,
          newItem
        ];
      }
    });
  };


  // After productList changes, rows are formed
  useEffect(() => {
    if (productAndFormsList.length === 0) return;
    const dynamicRows = productAndFormsList.map((product, index) => {
      const fuelObject = fuelValues.find(fuel => fuel.index === index + 1);
      console.log("fobj: " + JSON.stringify(fuelObject, null, 2))
      return {
        no: index + 1,
        product: product.productName,
        balance: (<span style={{ "padding-right": "10px", display: "block", textAlign: "right" }}>{fuelObject ? <NumericFormat displayType="text" value={fuelObject.balance.toFixed(3)} thousandSeparator /> : 0}</span>),
        unit: (
          <ClaySelect
            style={selectInputStyle}
            disabled
            key={`selectUnit_${product.productName}`}
            id={`selectUnit_${product.productName}`}
            onChange={(e) => handleUnitChange(e, index, product.productName, product.units[e.target.selectedIndex - 1] ? product.units[e.target.selectedIndex - 1].inKtoe : "")}>
            <option value={""}>Select...</option>
            {product.units.map((unit, unitIndex) => (
              <option
                key={unitIndex}
                value={unit.productUnitName}
              >
                {unit.productUnitName}
              </option>
            ))}
          </ClaySelect>
        ),
        ...product.forms.reduce((acc, form) => {
          acc[form.formFieldCode] = (
            <NumericFormat disabled
              key={`${index + 1}_${form.formFieldCode}`}
              style={numInputStyle}
              valueIsNumericString={true}
              id={`num_${product.productName}_${form.formFieldCode}`}
              customInput={ClayInput} thousandSeparator
              onValueChange={(values) => {
                const { floatValue } = values; // Extract the numeric value
                handleFuelValueChange(
                  values,
                  index + 1,
                  product.productName,
                  form.parentFormFieldCode,
                  form.formFieldName,
                  form.formFieldCode,
                  form.subtractive
                );
              }}
            />
          );
          return acc;
        }, {}),
        totalSellout: productAndFormsList[0].forms.some(form => form.parentFormFieldCode === "F4")
          ? fuelObject ?
            (() => {
              //total sell out value must always be +ve eventhough from subtractive form

              let currentF4Totals = fuelObject.forms.find(form => form.formCode === "F4")
                ?.categories.filter(category => totalSelloutRelatedForms.includes(category.formCode))
                .reduce((sum, category) => sum + category.value, 0);

              currentF4Totals = isNaN(currentF4Totals) ? 0 : Math.abs(currentF4Totals);

              setTotalSellouts(prevTotalSellouts => {
                const existingProductIndex = prevTotalSellouts.findIndex(
                  entry => Object.keys(entry)[0] === product.productName
                );

                if (existingProductIndex !== -1) {
                  // Update existing entry
                  const updatedSellouts = [...prevTotalSellouts];
                  updatedSellouts[existingProductIndex] = {
                    [product.productName]: currentF4Totals
                  };
                  return updatedSellouts;
                } else {
                  // Add new entry
                  return [
                    ...prevTotalSellouts,
                    { [product.productName]: currentF4Totals }
                  ];
                }
              });

              // Apply NumericFormat for the displayed total
              return (
                <NumericFormat
                  displayType="text"
                  value={currentF4Totals.toFixed(3)}
                  thousandSeparator
                />
              );
            })()
            : 0
          : 0
      }
    });

    setFBRows(dynamicRows);

    setProductList(productAndFormsList.map(item => item.productName));
  }, [productAndFormsList, fuelValues, totalSelloutRelatedForms]);

  useEffect(() => {
    console.log("tsl: " + JSON.stringify(productList, null, 2))
  }, [productList])


  //After rows changes, headers is formed
  useEffect(() => {
    if (productAndFormsList.length === 0) return;

    console.log("prd;" + JSON.stringify(productAndFormsList, null, 2))

    let containsF4SEU = productAndFormsList[0].forms.some(form => form.parentFormFieldCode === "F4");
    let dynamicHeaders = [];

    if (containsF4SEU) {
      dynamicHeaders = [
        { id: 'no', name: 'No.' },
        { id: 'product', name: 'Product' },
        { id: 'unit', name: 'Unit' },
        { id: 'balance', name: 'Balance' },
        ...productAndFormsList[0].forms.map(form => ({
          id: form.formFieldCode,
          name: `${form.parentFormFieldCode} - ${form.formFieldName}`
        })),

        { id: 'totalSellout', name: 'Total Sell Out' }
      ];
    } else {
      dynamicHeaders = [
        { id: 'no', name: 'No.' },
        { id: 'product', name: 'Product' },
        { id: 'unit', name: 'Unit' },
        { id: 'balance', name: 'Balance' },
        ...productAndFormsList[0].forms.map(form => ({
          id: form.formFieldCode,
          name: `${form.parentFormFieldCode} - ${form.formFieldName}`
        }))
      ];
    }

    setFBHeaders(dynamicHeaders);
  }, [fbRows]);

  useEffect(() => {
    if (checkingReport && productAndFormsList.length > 0 && !inputsPopulated) {
      // Iterate through the products in the report
      checkingReport.products.forEach((product) => {
        product.forms.forEach((form) => {
          form.categories.forEach((category) => {
            // NUMERICAL INPUTS
            // Construct the id to match the <input> elements
            const numInputId = `num_${product.productName}_${category.formCode}`;
            const numInputElement = document.getElementById(numInputId);

            if (numInputElement) {
              const value = category.value; // Assuming the value is now directly under the category
              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
              nativeInputValueSetter.call(numInputElement, value);

              // Dispatch the 'input' event to trigger onChange in React
              const event = new Event('input', { bubbles: true });
              numInputElement.dispatchEvent(event);
            }

            // DROPDOWN INPUTS
            // Construct the id to match the <select> elements
            const selectInputId = `selectUnit_${product.productName}`;
            const selectElement = document.getElementById(selectInputId);

            if (selectElement) {
              const value = product.unit; // Unit is now directly at the product level
              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, 'value').set;
              nativeInputValueSetter.call(selectElement, value);

              // Dispatch the 'input' event to trigger onChange in React
              const event = new Event('change', { bubbles: true });
              selectElement.dispatchEvent(event);
            }
          });
        });
      });

      setInputsPopulated(true);

      // Set basic report information
      setReportBasicInfo({
        year: checkingReport.reportPeriodYear,
        quarter: checkingReport.reportQuarter,
        agency: checkingReport.agency.agencyName,
        dataProvider: checkingReport.dataProvider.name,
        status: checkingReport.reportStatus,
        type: "Fuel Balance (Original Units)"
      });
    }
  }, [checkingReport, fbHeaders]);

  useEffect(() => {
    console.log("rbi: " + JSON.stringify(reportBasicInfo, null, 2));
  }, [reportBasicInfo])

  return (
    <div>
        {/* Export to PDF */}
        <FontAwesomeIcon
          title="Download as PDF"
          style={{ cursor: 'pointer' }}
          onClick={() => exportToPdf("fb-originalunit-table", reportBasicInfo)}
          className='mr-2 text-primary'
          size='xl'
          icon={faFilePdf}
        />

        {/* Export to Excel */}
        <FontAwesomeIcon
          title="Download as Excel"
          style={{ cursor: 'pointer' }}
          onClick={() => exportToExcel("fb-originalunit-table", reportBasicInfo)}
          className='text-primary'
          size='xl'
          icon={faFileExcel}
        />

        <Table responsive bordered hover size="sm" className="mt-3" id="fb-originalunit-table">
          <thead>
            <tr>
              {fbHeaders.map((column) => (
                <th
                  key={column.id}
                  className={
                    column.id === 'no' || column.id === 'balance' || column.id === 'totalSellout' ?
                      "table-cell-no-width"
                      : "table-cell-minw-200"}
                  style={
                    column.id === 'product'
                      ? {
                        position: 'sticky',
                        left: 0,  // First sticky column
                        backgroundColor: '#ECECEF',
                        zIndex: 1,

                      }
                      : column.id === 'balance'
                        ? {
                          position: 'sticky',
                          left: '199px',  // Adjust this based on the width of the first column
                          backgroundColor: '#ECECEF',
                          zIndex: 1,

                        }
                        : {}
                  }
                >
                  {column.name}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {fbRows.map(row => (
              <tr key={row.no}>
                {fbHeaders.map(column => (
                  <td
                    key={column.id}
                    className={
                      column.id === 'no' || column.id === 'balance' || column.id === 'totalSellout'
                        ? "table-cell-no-width"
                        : "table-cell-minw-200"
                    }
                    style={
                      column.id === 'product'
                        ? {
                          position: 'sticky',
                          left: 0,  // First sticky column
                          backgroundColor: '#f7f7f7',
                          zIndex: 1,  // Ensure it's above other cells
                        }
                        : column.id === 'balance'
                          ? {
                            position: 'sticky',
                            left: '199px',  // Adjust based on column width
                            backgroundColor: '#f7f7f7',
                            zIndex: 1,  // Ensure it's above other cells
                          }
                          : {}
                    }
                  >
                    {row[column.id]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>

        </Table>
        
    </div>
  );
};

export default FuelBalanceTableCheckingDP;

