import React, { useState, useCallback, useEffect } from 'react';
import { getProductAndForms } from "../../../../api/nebProductService";
import { ClaySelect, ClayInput } from '@clayui/form';
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { exportToPdf, exportToExcel } from './exportToPdf';
import { NumericFormat } from 'react-number-format';
import { Row, Col, Card, Form, InputGroup, Modal, Button, Table, Badge, Accordion } from 'react-bootstrap';

const C1TableCheckingDP = ({ agencyId, checkingReport, totalSellouts }) => {

    const [headers, setHeaders] = useState([]);
    const [rows, setRows] = useState([]);
    const [inputsPopulated, setInputsPopulated] = useState(false);
    const [productAndFormsList, setProductAndFormsList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [formList, setFormList] = useState([]);
    const [reportBasicInfo, setReportBasicInfo] = useState();

    const numInputStyle = {
        textAlign: "right",
        width: "100%",
        height: "40px",
        marginRight: "5px",
        borderRadius: "5px",
        background: "#f8f8f8",
        color: "black"
    };

    const selectInputStyle = {
        width: "100%",
        height: "40px",
        marginRight: "5px",
        borderRadius: "5px",
        background: "#f8f8f8",
        color: "black"
    }
    const [fuelValues, setFuelValues] = useState([]);

    const createRows = () => {
        return productAndFormsList.map((product, index) => {
            const fuelObject = fuelValues.find((fuel) => fuel.index === index + 1);
            const isStandalone = product.standalone;
            let totalSelloutBalance = 0;

            if (isStandalone) {
                const totalSellout = totalSellouts.find(
                    (sellout) => sellout[product.productName] !== undefined
                );
                totalSelloutBalance = totalSellout
                    ? totalSellout[product.productName]
                    : 0;
            }

            const row = {
                no: index + 1,
                product: product.productName,
                unit: (
                    <ClaySelect
                        id={`draft_c1_selectUnit_${product.productName}`}
                        onChange={(e) => handleUnitChange(e, index, product.productName)}
                        disabled
                    >
                        <option value="">Select...</option>
                        {product.units.map((unit, unitIndex) => (
                            <option key={unitIndex} value={unit.productUnitName}>
                                {unit.productUnitName}
                            </option>
                        ))}
                    </ClaySelect>
                ),
                ...product.forms.reduce((acc, form) => {
                    acc[form.formFieldCode] = (
                        <NumericFormat
                            className="no-spinner"
                            key={`${index + 1}_${form.formFieldCode}`}
                            disabled
                            style={numInputStyle}
                            id={`draft_c1_num_${product.productName}_${form.formFieldCode}`}
                            onValueChange={(values) => {
                                handleFuelValueChange(
                                    values,
                                    index + 1,
                                    product.productName,
                                    form.parentFormFieldCode,
                                    form.formFieldName,
                                    form.formFieldCode,
                                    form.subtractive
                                );
                            }}
                            valueIsNumericString={true}
                            customInput={ClayInput}
                            thousandSeparator
                        />
                    );
                    return acc;
                }, {}),
                balance: (
                    <span
                        style={{
                            paddingRight: "10px",
                            display: "block",
                            textAlign: "right",
                        }}
                    >
                        {fuelObject ? (
                            <NumericFormat
                                displayType="text"
                                value={(fuelObject.balance + totalSelloutBalance).toFixed(2)}
                                thousandSeparator
                            />
                        ) : (
                            <NumericFormat
                                displayType="text"
                                value={(0).toFixed(2)}
                                thousandSeparator
                            />
                        )}
                    </span>
                ),
            };

            return row;
        });
    };

    const populateData = (rows) => {
        console.log("totalSellOuts >>>>> ", totalSellouts);

        const newFuelValues = []; // Initialize new fuelValues

        if (checkingReport) {
            const initialProductAndUnit = [];

            //set Product And Unit Initially

            if (productAndUnit.length == 0) {
                checkingReport.forms.forEach((formCategory) => {
                    formCategory.categories.forEach((category) => {
                        category.products.forEach((fetchedProduct) => {
                            const existingProduct = initialProductAndUnit.find(
                                (item) => item.fieldName === fetchedProduct.fieldName
                            );
                            if (!existingProduct) {
                                initialProductAndUnit.push({
                                    fieldName: fetchedProduct.fieldName,
                                    unit: fetchedProduct.unit || "",
                                });
                            }
                        });
                    });
                });
                console.log("Fetched ProductAndUnit:", initialProductAndUnit); // Debugging log
                setProductAndUnit(initialProductAndUnit);
            }

            const updatedRows = rows.map((row, rowIndex) => {
                const product = productAndFormsList[rowIndex];

                // Fetch selected unit from productAndUnit (directly from state)
                const selectedUnit =
                    productAndUnit.find((item) => item.fieldName === product.productName)
                        ?.unit || "";

                // Process fetched report data
                checkingReport.forms.forEach((formCategory) => {
                    formCategory.categories.forEach((category) => {
                        category.products.forEach((fetchedProduct) => {
                            if (fetchedProduct.fieldName === product.productName) {
                                product.forms.forEach((form) => {
                                    if (category.formCode === form.formFieldCode) {
                                        const formValue = fetchedProduct.value;

                                        // Add or update `fuelValues`
                                        let productFuel = newFuelValues.find(
                                            (fv) => fv.index === rowIndex + 1
                                        );
                                        if (!productFuel) {
                                            productFuel = {
                                                index: rowIndex + 1,
                                                productName: product.productName,
                                                balance: 0, // Temporary, balance will be updated later
                                                forms: [],
                                            };
                                            newFuelValues.push(productFuel);
                                        }

                                        // Find or add the form
                                        let fuelForm = productFuel.forms.find(
                                            (f) => f.formCode === form.parentFormFieldCode
                                        );
                                        if (!fuelForm) {
                                            fuelForm = {
                                                formCode: form.parentFormFieldCode,
                                                categories: [],
                                            };
                                            productFuel.forms.push(fuelForm);
                                        }

                                        // Add the category
                                        fuelForm.categories.push({
                                            formCode: form.formFieldCode,
                                            value: formValue || 0,
                                            description: form.formFieldName,
                                        });

                                        // Update the row for UI rendering
                                        row[form.formFieldCode] = (
                                            <NumericFormat
                                                className="no-spinner"
                                                key={`${rowIndex + 1}_${form.formFieldCode}`}
                                                disabled
                                                style={numInputStyle}
                                                id={`draft_c1_num_${product.productName}_${form.formFieldCode}`}
                                                value={formValue}
                                                onValueChange={(values) => {
                                                    handleFuelValueChange(
                                                        values,
                                                        rowIndex + 1,
                                                        product.productName,
                                                        form.parentFormFieldCode,
                                                        form.formFieldName,
                                                        form.formFieldCode,
                                                        form.subtractive
                                                    );
                                                }}
                                                valueIsNumericString={true}
                                                customInput={ClayInput}
                                                thousandSeparator
                                            />
                                        );
                                    }
                                });
                            }
                        });
                    });
                });

                // Handle unit selection in rows
                console.log("selectedUnit >>>>", selectedUnit, productAndUnit);
                row.unit = (
                    <ClaySelect
                        id={`draft_c1_selectUnit_${product.productName}`}
                        onChange={(e) =>
                            handleUnitChange(e, rowIndex + 1, product.productName)
                        }
                        disabled
                        value={selectedUnit}
                    >
                        <option value="">Select...</option>
                        {product.units.map((unit, unitIndex) => (
                            <option key={unitIndex} value={unit.productUnitName}>
                                {unit.productUnitName}
                            </option>
                        ))}
                    </ClaySelect>
                );

                // Calculate balance for standalone products
                const totalSellout = totalSellouts.find(
                    (sellout) => sellout[product.productName] !== undefined
                );
                const totalSelloutBalance = totalSellout
                    ? totalSellout[product.productName]
                    : 0;

                const sumOfValues = newFuelValues
                    .filter((fuelItem) => fuelItem.productName === product.productName)
                    .reduce((sum, fuelItem) => {
                        return (
                            sum +
                            fuelItem.forms.reduce((formSum, form) => {
                                return (
                                    formSum +
                                    form.categories.reduce((categorySum, category) => {
                                        return categorySum + Math.abs(category.value || 0);
                                    }, 0)
                                );
                            }, 0)
                        );
                    }, 0);

                const balance = totalSelloutBalance - sumOfValues;

                row.balance = (
                    <span
                        style={{
                            paddingRight: "10px",
                            display: "block",
                            textAlign: "right",
                        }}
                    >
                        <NumericFormat
                            displayType="text"
                            value={balance.toFixed(2)}
                            thousandSeparator
                        />
                    </span>
                );

                const fuelItem = newFuelValues.find(
                    (fuel) => fuel.productName === product.productName
                );
                if (fuelItem) {
                    fuelItem.balance = balance;
                }

                return row;
            });

            // Update `fuelValues` state after processing all rows
            setFuelValues(newFuelValues);

            return updatedRows;
        }
        return rows;
    };


    const handleFuelValueChange = (values, index, productName, parentFormFieldCode, formFieldName, formCode, subtractive) => {
        const { floatValue = 0 } = values;
        let value = isNaN(Number(floatValue)) ? 0 : Number(floatValue);

        // Make value negative based on form type
        if (subtractive) {
            value = -Math.abs(value);
        }

        // Find the product in productList to check if it's standalone
        const product = productAndFormsList.find(p => p.productName === productName);
        const isStandalone = product ? product.standalone : false;

        // Get the totalSellout value if standalone is true
        let totalSelloutBalance = 0;
        const totalSellout = totalSellouts.find(sellout => sellout[productName] !== undefined);
        totalSelloutBalance = totalSellout ? totalSellout[productName] : 0;

        setFuelValues(prevFuelValues => {
            const exists = prevFuelValues.some(item => item.index === index);

            if (exists) {
                return prevFuelValues.map(item => {
                    if (item.index === index) {
                        let formIndex = item.forms.findIndex(form => form.formCode === parentFormFieldCode);
                        let updatedForms;

                        if (formIndex !== -1) {
                            updatedForms = item.forms.map((form, idx) => {
                                if (idx === formIndex) {
                                    let updatedCategories = form.categories.map(category => {
                                        if (category.formCode === formCode) {
                                            return { formCode: formCode, value: value, description: formFieldName };
                                        } else {
                                            return category;
                                        }
                                    });

                                    if (!updatedCategories.some(category => category.formCode === formCode)) {
                                        updatedCategories.push({ formCode: formCode, value: value, description: formFieldName });
                                    }

                                    return { ...form, categories: updatedCategories };
                                } else {
                                    return form;
                                }
                            });
                        } else {
                            updatedForms = [...item.forms, { formCode: parentFormFieldCode, categories: [{ formCode: formCode, value: value, description: formFieldName }] }];
                        }

                        // Calculate balance by adding both the form balance and totalSelloutBalance if standalone is true
                        const originalBalance = updatedForms.reduce((sum, form) => {
                            return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
                        }, 0);
                        const balance = originalBalance + totalSelloutBalance;

                        return { ...item, forms: updatedForms, balance: balance };
                    } else {
                        return item;
                    }
                });
            } else {
                const newItem = {
                    index: index,
                    balance: value,
                    productName: productName,
                    forms: [
                        {
                            formCode: parentFormFieldCode,
                            categories: [
                                { formCode: formCode, value: value, description: formFieldName }
                            ]
                        }
                    ]
                };

                const originalBalance = newItem.forms.reduce((sum, form) => {
                    return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
                }, 0);

                const balance = originalBalance + (isStandalone ? totalSelloutBalance : 0);

                return [
                    ...prevFuelValues,
                    { ...newItem, balance: balance }
                ];
            }
        });
    };

    const [productAndUnit, setProductAndUnit] = useState([]);

    const handleUnitChange = (e, index, productName) => {
        const { value: unit } = e.target;

        setProductAndUnit(prevProductAndUnit => {
            // Check if an object with matching productName exists
            const exists = prevProductAndUnit.some(item => item.fieldName === productName);

            if (exists) {
                // Update the existing object
                return prevProductAndUnit.map(item => {
                    if (item.fieldName === productName) {
                        return {
                            ...item,
                            unit: unit
                        };
                    } else {
                        return item;
                    }
                });
            } else {
                // Add a new object
                const newItem = {
                    fieldName: productName,
                    unit: unit
                };

                return [
                    ...prevProductAndUnit,
                    newItem
                ];
            }
        });
    };

    /**
     * Use Effect start here
     */

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getProductAndForms(agencyId, 'C1-FECS');
                console.log('FETCHING P&F' + JSON.stringify(result, null, 2));
                setProductAndFormsList(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);


    useEffect(() => {
        // Step 1: Create basic rows dynamically
        const baseRows = createRows();
        const populatedRows = populateData(baseRows, true);

        // Update rows only if there's a difference
        setRows(populatedRows);
        setProductList(productAndFormsList.map((item) => item.productName));

        console.log("productAndFormsList is : ", productAndFormsList);
        console.log("populatedRows -- ", populatedRows);

        setInputsPopulated(true);

        //Get report basic info
        setReportBasicInfo(
            {
                "year": checkingReport.reportPeriodYear,
                "quarter": checkingReport.reportQuarter,
                "agency": checkingReport.agency.agencyName,
                "dataProvider": checkingReport.dataProvider.name,
                "status": checkingReport.reportStatus,
                "type": "Final Energy Consumption Sectors (C1) (Original Units)"
            }
        )
    }, [checkingReport, productAndFormsList, totalSellouts, productAndUnit]);

    //After rows changes, headers is formed
    useEffect(() => {
        if (productAndFormsList.length === 0) return;

        const dynamicHeaders = [
            { id: 'no', name: 'No.' },
            { id: 'product', name: 'Product' },
            { id: 'unit', name: 'Unit' },
            { id: 'balance', name: 'Balance' },
            ...productAndFormsList[0].forms.map(form => ({
                id: form.formFieldCode,
                name: `${form.formFieldName}`
            })),
            // totalSellouts
            //   ?
            //   { id: 'totalSellout', name: 'Total Sell Out' }
            //   : null,
        ];
        setHeaders(dynamicHeaders);
        console.log("dynamicHeaders: " + JSON.stringify(dynamicHeaders, null, 2))
    }, [rows]);

    return (
        <div>
            {/* Export to PDF */}
            <FontAwesomeIcon
                title="Download as PDF"
                style={{ cursor: 'pointer' }}
                onClick={() => exportToPdf("c1-originalunit-table", reportBasicInfo)}
                className='mr-2 text-primary'
                size='xl'
                icon={faFilePdf}
            />

            {/* Export to Excel */}
            <FontAwesomeIcon
                title="Download as Excel"
                style={{ cursor: 'pointer' }}
                onClick={() => exportToExcel("c1-originalunit-table", reportBasicInfo)}
                className='text-primary'
                size='xl'
                icon={faFileExcel}
            />

            <Table responsive bordered hover size="sm" className="mt-3" id="c1-originalunit-table">
                <thead>
                    <tr>
                        {headers.map((column) => (
                            <th
                                key={column.id}
                                className={
                                    column.id === 'no' || column.id === 'balance' || column.id === 'totalSellout' ?
                                        "table-cell-no-width"
                                        : "table-cell-minw-200"}
                                style={
                                    column.id === 'product'
                                        ? {
                                            position: 'sticky',
                                            left: 0,  // First sticky column
                                            backgroundColor: '#ECECEF',
                                            zIndex: 1,  // Ensure it's above other cells
                                        }
                                        : column.id === 'balance'
                                            ? {
                                                position: 'sticky',
                                                left: '199px',  // Adjust based on column width
                                                backgroundColor: '#ECECEF',
                                                zIndex: 1,  // Ensure it's above other cells
                                            }
                                            : {}
                                }
                            >
                                {column.name}
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {rows.map(row => (
                        <tr key={row.no}>
                            {headers.map(column => (
                                <td
                                    key={column.id}
                                    className={
                                        column.id === 'no' || column.id === 'balance' || column.id === 'totalSellout' ?
                                            "table-cell-no-width"
                                            : "table-cell-minw-200"}
                                    style={
                                        column.id === 'product'
                                            ? {
                                                position: 'sticky',
                                                left: 0,  // First sticky column
                                                backgroundColor: '#f7f7f7',
                                                zIndex: 1,  // Ensure it's above other cells
                                            }
                                            : column.id === 'balance'
                                                ? {
                                                    position: 'sticky',
                                                    left: '199px',  // Adjust based on column width
                                                    backgroundColor: '#f7f7f7',
                                                    zIndex: 1,  // Ensure it's above other cells
                                                }
                                                : {}
                                    }
                                >
                                    {row[column.id]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>

        </div>
    );
};

export default C1TableCheckingDP;
