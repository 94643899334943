import React, { useEffect, useRef, useState } from "react";
import profilePlaceholder from "./profile-svgrepo-com.svg";
import useAuthStore from "../../../store/useAuthStore";
import { getAgencyByLiferayId } from "../../../api/nebAgencyService";
import { getDataProviderByAgencyId } from "../../../api/nebDataProviderService";
import {
  fetchUserProfileImage,
  uploadUserImage,
} from "../../../api/userService";
import DetailsSection from "./DetailsSection";
import { fetchCurrentUser } from "../../../api/userService";
import { format } from "date-fns";
import { Row, Col, Card, Form, InputGroup, Modal, Button, Table, Badge } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import * as solidIcons from '@fortawesome/free-solid-svg-icons';

const Profile = () => {
  const [profileData, setProfileData] = useState({
    name: "loading",
    role: "loading",
    designation: "loading",
    agency: "loading",
    email: "loading",
    phoneNumber: "loading",
    contactPerson: {
      name: "loading",
      email: "loading",
      phoneNumber: "loading",
    },
    productList: [],
    formList: [],
    profileImageUrl: profilePlaceholder, // Default placeholder image
  });

  const user = useAuthStore((state) => state.user); // Zustand store for user data
  const logout = useAuthStore((state) => state.logout); // Zustand logout function
  const fileInputRef = useRef(null); // Ref for the hidden file input
  
  const [lastLoginDate, setLastLoginDate] = useState("");

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!user?.id) return;

      try {
        console.log("user id profile: ", user.id);
        const agency = await getAgencyByLiferayId(user.id);
        const dp = await getDataProviderByAgencyId(agency.id);

        // Fetch the profile image as a base64-encoded string
        const profileImageBase64 = await fetchUserProfileImage();

        setProfileData({
          name: dp.name,
          role: "Data Provider", // Example role
          designation: "Agency Representative", // Example designation
          agency: agency.agencyName,
          email: dp.email,
          phoneNumber: dp.phoneNo,
          contactPerson: {
            name: dp.agency.cpdName,
            email: dp.agency.cpdEmail,
            phoneNumber: dp.agency.cpdPhoneNumber,
          },
          productList: extractProductList(dp),
          formList: extractFormFields(dp),
          profileImageUrl: profileImageBase64 || profilePlaceholder, // Set base64 URL for image
        });
      } catch (error) {
        console.error("Error fetching profile data:", error);
        logout(); // Handle token invalidation
      }
    };

    if (user?.id) {
      fetchProfileData();
    }
  }, [user, logout]);

  // get last login date
  useEffect(() => {
    const getLastLoginDate = async () => {
      try {
        const user = await fetchCurrentUser();
        console.log("Current User", user);

        // Set the user's last login
        setLastLoginDate(formatDate(user.lastLoginDate));

      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getLastLoginDate();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy h:mm:ss aa");
  };

  // Trigger file input click
  const handleProfileImageClick = () => {
    fileInputRef.current.click(); // Trigger the hidden file input when the image is clicked
  };

  // Handle file selection and upload
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("image", file);

        // Upload the image
        const response = await uploadUserImage(formData);
        if (response.status == 200) {
          const updatedProfileImageBase64 = await fetchUserProfileImage(); // Fetch the updated profile image
          setProfileData((prevState) => ({
            ...prevState,
            profileImageUrl: updatedProfileImageBase64 || profilePlaceholder,
          }));
        }
      } catch (error) {
        console.error("Error uploading profile image:", error);
      }
    }
  };

  const extractProductList = (data) => {
    return data.agency.categories.flatMap((category) =>
      category.products.map((product) => ({
        productName: product.productName,
      }))
    );
  };

  const extractFormFields = (data) => {
    if (!data?.agency?.forms) return [];

    const formsList = data.agency.forms
      .map((form) => {
        const filteredFields = form.formFields.filter(
          (field) => !field.formFieldCode.includes("-NA")
        );
        if (filteredFields.length === 0) return null;

        return {
          formType: form.formType,
          formDescription: form.formDescription,
          formFields: filteredFields.map((field) => ({
            formFieldCode: field.formFieldCode,
            formFieldName: field.formFieldName,
          })),
        };
      })
      .filter(Boolean);

    if (data.agency.installedCapacity) {
      formsList.push({ formDescription: "Installed Capacity", formFields: [] });
    }
    if (data.agency.powerPlantPerformance) {
      formsList.push({
        formDescription: "Power Plant Performance",
        formFields: [],
      });
    }
    if (data.agency.netCalorificValue) {
      formsList.push({
        formDescription: "Net Calorific Value",
        formFields: [],
      });
    }

    return formsList;
  };

  const {
    name,
    role,
    designation,
    agency,
    email,
    phoneNumber,
    contactPerson,
    productList,
    formList,
    profileImageUrl,
  } = profileData;

  return (
    <Card className="mt-4"> 
      <Card.Header>
        <Link to="/neb-profile/edit" className="btn btn-sm btn-primary">Edit Profile & Password</Link>
      </Card.Header>

      <Card.Body>
        {/* Profile */}
        <Row>
          <Col className="text-center">
            <img
              src={profileImageUrl}
              alt="Profile"
              className="profile-image mb-3"
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={handleProfileImageClick} />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept="image/*" />
            <h3 className="profile-name">{name}</h3>
            <p className="mb-0">{role}</p>
            <p className="mb-0">{designation}</p>
          </Col>
        </Row>

        {/* My Details */}
        <Row className="mt-4">
          <Col>
            <Badge pill text="white" className="badge-color1 text-md">
              <FontAwesomeIcon icon={solidIcons['faTags']} className="mr-2" /> MY DETAILS
            </Badge>
              
            <dl className="row mt-3">
              <dt className="col-sm-3 col-lg-2">Agency :</dt>
                <dd className="col-sm-9 col-lg-10">{agency}</dd>
              <dt className="col-sm-3 col-lg-2">Email :</dt>
                <dd className="col-sm-9 col-lg-10">{email}</dd>
              <dt className="col-sm-3 col-lg-2">Phone Number :</dt>
                <dd className="col-sm-9 col-lg-10">{phoneNumber}</dd>
              <dt className="col-sm-3 col-lg-2">Last Login :</dt>
                <dd className="col-sm-9 col-lg-10">{lastLoginDate}</dd>
            </dl>
          </Col>
        </Row>
          
        {/* Contact Person */}
        <Row className="mt-3">
          <Col>
            <Badge pill text="white" className="badge-color1 text-md">
              <FontAwesomeIcon icon={solidIcons['faTags']} className="mr-2" /> CONTACT PERSON
            </Badge>

            <dl className="row mt-3">
              <dt className="col-sm-3 col-lg-2">Name :</dt>
                <dd className="col-sm-9 col-lg-10">{contactPerson.name}</dd>              
              <dt className="col-sm-3 col-lg-2">Email :</dt>
                <dd className="col-sm-9 col-lg-10">{contactPerson.email}</dd>                
              <dt className="col-sm-3 col-lg-2">Phone Number :</dt>
                <dd className="col-sm-9 col-lg-10">{contactPerson.phoneNumber}</dd>
            </dl>
          </Col>
        </Row>

        {/* Product & Form */}
        <Row className="mt-3">
          <Col>
            <Badge pill text="white" className="badge-color1 text-md">
              <FontAwesomeIcon icon={solidIcons['faTags']} className="mr-2" /> PRODUCT & FORM
            </Badge>
            <br /><br />
              
            <h5>PRODUCT LIST</h5>
            <ol type="1">
              {productList.length ? (
                productList.map((product, index) => (
                  <li key={index} className="text-sm">
                    {product.productName}
                  </li>
                ))
              ) : (
                <li className="text-sm">No Products Available</li>
              )}
            </ol>
            <br />

            <h5>FORM LIST</h5>
            <ol type="1">
              {formList.length ? (
                formList.map((form, index) => (
                  <li key={index} className="mt-2">
                    <div>{form.formDescription}</div>
                    <ul type="disc">
                      {form.formFields.map((field, fieldIndex) => (
                        <li key={fieldIndex} className="text-sm">
                          {field.formFieldCode} ({field.formFieldName})
                        </li>
                      ))} 
                    </ul>
                  </li>
                ))
              ) : (
                <li className="text-sm">No Forms Available</li>
              )}
            </ol>
          </Col>
        </Row>

      </Card.Body>
    </Card>
  );
};

export default Profile;
