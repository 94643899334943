import axiosInstance from '../api/axiosInstance';

// Fetch user details
export const fetchUserData = async (accessToken) => {
    try {
        const response = await axiosInstance.get('/useraccount/whoami', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.data; // Return the user data
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
};

// Fetch user roles
export const fetchUserRoles = async (accessToken) => {
    try {
        const response = await axiosInstance.get('/useraccount/roles', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        // Transform the roles
        const transformedRoles = response.data.roles.map((role) =>
            role.name.toUpperCase().replace(/\s+/g, '_')
        );

        return transformedRoles;
    } catch (error) {
        console.error('Error fetching user roles:', error);
        throw error;
    }
};


// Fetch sites for the current user
export const fetchUserSites = async () => {
    try {
        const response = await axiosInstance.get('/useraccount/mysites');
        return response.data;
    } catch (error) {
        console.error('Error fetching user sites:', error);
        throw error;
    }
};

// Fetch user groups for the current user
export const fetchUserGroups = async () => {
    try {
        const response = await axiosInstance.get('/useraccount/myusergroups');
        return response.data;
    } catch (error) {
        console.error('Error fetching user groups:', error);
        throw error;
    }
};

// Fetch the current user account information
export const fetchCurrentUser = async () => {
    try {
        const response = await axiosInstance.get('/useraccount/whoami');
        return response.data;
    } catch (error) {
        console.error('Error fetching current user:', error);
        throw error;
    }
};

// Ping the server
export const pingServer = async () => {
    try {
        const response = await axiosInstance.get('/useraccount/ping');
        return response.data;
    } catch (error) {
        console.error('Error pinging server:', error);
        throw error;
    }
};

// Update user password
export const updatePassword = async (passwordData) => {
    try {
        const response = await axiosInstance.post('/useraccount/update-password', passwordData);
        return response.data;
    } catch (error) {
        console.error('Error updating password:', error);
        throw error;
    }
};

export const uploadUserImage = async (formData) => {
    try {
        const response = await axiosInstance.post('/useraccount/upload-image', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        console.log(response);
        return response;
    } catch (error) {
        console.error('Error uploading user image:', error);
        throw error;
    }
};

export const fetchUserProfileImage = async () => {
    try {
        const response = await axiosInstance.get('/useraccount/user-portrait', { responseType: 'arraybuffer' });

        // Convert arraybuffer to base64 string
        const base64 = btoa(
            new Uint8Array(response.data).reduce((data, byte) => {
                return data + String.fromCharCode(byte);
            }, '')
        );

        // Create the base64 image source
        const base64Image = `data:image/png;base64,${base64}`;

        return base64Image; // Return the base64 image to be used as src
    } catch (error) {
        console.error('Error fetching user profile image:', error);
        throw error;
    }
};

// Validate user password
export const validatePassword = async (currentPassword) => {
    try {
        const response = await axiosInstance.post(
            '/useraccount/validate-password',
            new URLSearchParams({ currentPassword }),
            {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }
        );
        return response.data; // Return the success message
    } catch (error) {
        console.error('Error validating password:', error);
        throw error;
    }
};
