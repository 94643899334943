import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import { useNavigate, useParams, useLocation } from "react-router";
/* import axios from "axios"; */
import { getReportById, submitReport, updateReport } from "../../../api/nebReportService";
import { getAgencyByLiferayId, getAgencyById } from "../../../api/nebAgencyService";
import { getDataProviderByAgencyId } from "../../../api/nebDataProviderService";
import useAuthStore from "../../../store/useAuthStore";
import { Row, Col, Card, Form, InputGroup, Modal, Button, Table, Badge } from 'react-bootstrap';
import { Link } from "react-router-dom";
/* import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab"; */
import ClayAlert from '@clayui/alert';
import DynamicTabsDP from "./DynamicTabsDP";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as solidIcons from '@fortawesome/free-solid-svg-icons';
import {
  faUserCircle, faTrash
} from "@fortawesome/free-solid-svg-icons";

const SubmitNewReportDP = () => {
  const intervalRef = useRef(null);
  const [autoSaveActive, setAutoSaveActive] = useState(false);
  const { reportId, viewMode, paramsAgencyId, paramsQuarter, paramsYear, paramsReportStatus } = useParams();
  const location = useLocation();
  const { yearAndQuarters } = location.state || {}; // Extract yearAndQuarters from state
  const [combinedStructure, setCombinedStructure] = useState(null);
  const [reportStatus, setReportStatus] = useState("Not Submitted");
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState('info');
  const [isCurrentFormSaved, setIsCurrentFormSaved] = useState(false);
  //fb balance and unit checking
  const [fbNotBalanced, setFBNotBalanced] = useState(false);
  const [fbUnitIsMissing, setFbUnitIsMissing] = useState(false);
  const [fuelValuesFBChecking, setFuelValuesFBChecking] = useState();
  const [productAndUnitFBChecking, setProductAndUnitFBChecking] = useState();
  //c1 balance and unit checking
  const [c1NotBalanced, setC1NotBalanced] = useState(false);
  const [c1UnitIsMissing, setC1UnitIsMissing] = useState(false);
  const [fuelValuesC1Checking, setFuelValuesC1Checking] = useState();
  const [productAndUnitC1Checking, setProductAndUnitC1Checking] = useState();
  //net calorific unit checking
  const [netUnitIsMissing, setNETUnitIsMissing] = useState(false);
  const [fuelValuesNETChecking, setFuelValuesNETChecking] = useState();
  const [productAndUnitNETChecking, setProductAndUnitNETChecking] = useState();
  //submit's flag
  const [submitBlocked, setSubmitBlocked] = useState(true);
  //count parent forms
  const [parentFormsCount, setParentFormsCount] = useState(0);
  //count checkedForms
  const [checkedFormsCount, setCheckedFormsCount] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);

  //get current year and quarter
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentQuarter = "Q" + Math.ceil((date.getMonth() + 1) / 3);

  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [selectedYear, setSelectedYear] = useState();
  const handleQSelectChange = (event) => {
    setSelectedQuarter(event.target.value);
  };
  const handleYSelectChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const [showModal, setShowModal] = useState(false);
  const handleShowConfirmationModal = () => setShowModal(true);
  const handleCloseConfirmationModal = () => setShowModal(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [lifeId, setLifeId] = useState();
  const [role, setRole] = useState([]);
  const [agencyId, setAgencyId] = useState();
  const [agencyName, setAgencyName] = useState();
  const [dpID, setDPId] = useState();
  const [dpName, setDPName] = useState();
  const navigate = useNavigate();
  const [yearList, setYearList] = useState([]);
  const [submittedQuarterList, setSubmittedQuarterList] = useState([]);
  const [quarterList, setQuarterList] = useState(["Q1", "Q2", "Q3", "Q4"]);
  const [remark, setRemark] = useState();
  const [remarks, setRemarks] = useState([]);
  const currentDateTime = new Date().toLocaleString();
  const [energySector, setEnergySector] = useState();
  const [region, setRegion] = useState();
  const [statusAction, setStatusAction] = useState();
  const [isDisabled, setDisabled] = useState(false);
  const [isDisabledYearQuarter, setDisabledYearQuarter] = useState(false);


  // Get the user object from the Zustand store
  const user = useAuthStore((state) => state.user);

  function handleShowModal(action) {
    setStatusAction(action)
    setShowModal(true);
  }

  const handleCloseModal = () => setShowModal(false);

  

  const onCancelClick = () => {
    const confirmation = window.confirm(
      "Are you sure you want to cancel this process?"
    );
    if (confirmation) {
      toast.success(`The process have been cancel`);
      setTimeout(() => {
        navigate(`/neb-data-submission/neb-data-submission-list`);
      }, 2000);
    }
  };

  useEffect(() => {
    if (viewMode !== "draft" && viewMode !== "pending") {
      toast.info("Select Year and Quarter");
    }
  }, [viewMode])

  useEffect(() => {
    if (user && user.id) {
      setLifeId(user.id);
    }

    const filteredRoles = user.roleBriefs
      .filter((role) => role.name.startsWith("NEB"))
      .map((role) => role.name);

    let isDisabled;

    // Check if role contains "NEB Data Provider" or "NEB Admin"
    if (filteredRoles.includes("NEB Admin")) {
      // NEB Admin: Disable status is always true
      isDisabled = true;
      setDisabledYearQuarter(true);
    } else if (filteredRoles.includes("NEB Data Provider")) {
      // NEB Data Provider: Apply specific rules
      if (paramsReportStatus === "Draft" || paramsReportStatus === "Pending for Amendment") {
        isDisabled = false;
        setDisabledYearQuarter(true);
      } else if (paramsReportStatus === "Approved" || paramsReportStatus === "Pending for Approval") {
        isDisabled = true;
        setDisabledYearQuarter(true);
      }
    } else {
      isDisabled = false;
      setDisabledYearQuarter(false);
    }

    setDisabled(isDisabled);

    console.log("authstore: " + JSON.stringify(user, null, 2))

    const startYear = 2010;
    const yearList = Array.from({ length: currentYear - startYear + 1 }, (_, i) => (currentYear - i).toString());

    setYearList(yearList);

  }, [user, yearAndQuarters]);


  useEffect(() => {
    if (viewMode === "draft" || viewMode === "pending") {
      setReportStatus(paramsReportStatus);
      setSelectedQuarter(paramsQuarter);
      setSelectedYear(paramsYear);
    }
  }, [paramsReportStatus, paramsQuarter, paramsYear]);

  //if viewMode = draft, need to fetch report and populate non disabled inputs
  const [fetchedReport, setFetchedReport] = useState();

  const [isSubmitPopupDisabled, setIsSubmitPopupDisabled] = useState(true);

  useEffect(() => {
    if (viewMode === "draft" || viewMode === "pending") {
      const fetchData = async () => {
        try {
          const result = await getReportById(reportId);
          console.log('FETCHING DRAFT REPORT' + JSON.stringify(result, null, 2));
          //console.log("reportRegion: " + result.data.reportRegion.description);
          setFetchedReport(result.data);
          setRemarks(result.data.remarks);
          setAgencyId(result.data.agency.id);
          //setRegion(result.data.reportRegion.description);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    } else {
      setSelectedYear("YYYY");
    }
  }, []);

  // Function to handle increment or decrement
  const updateCheckedFormsCount = (change) => {
    setCheckedFormsCount(prevCounter => prevCounter + change);
  };

  //get parent forms count
  useEffect(() => {
    console.log("parent forms count: " + parentFormsCount);
    if (checkedFormsCount === parentFormsCount) {
      setIsSubmitPopupDisabled(false);
    } else {
      setIsSubmitPopupDisabled(true);
    }
  }, [parentFormsCount, checkedFormsCount])

  //get agency with liferay id
  const getAgency = async (id) => {
    try {
      const response = await getAgencyByLiferayId(id);
      return response;
    } catch (error) {
      console.error('Error getting agency:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchAgency = async () => {
      console.log("params agency " + paramsAgencyId);
      console.log(paramsAgencyId);
      if (lifeId && !paramsAgencyId) {  // Ensure lifeId is set before fetching agency
        try {
          const agency = await getAgency(lifeId);
          setAgencyName(agency.agencyName);
          setAgencyId(agency.id);
          setEnergySector(agency.sectorId);
        } catch (error) {
          console.error('Error fetching agency: getting direct from param', error);
          const agency = await getAgencyById(paramsAgencyId);
          setAgencyName(agency.agencyName);
          setAgencyId(agency.id);
          setEnergySector(agency.sectorId);
        }
      } else if (paramsAgencyId) {
        const agency = await getAgencyById(paramsAgencyId);
        setAgencyName(agency.agencyName);
        setAgencyId(agency.id);
        setEnergySector(agency.sectorId);
      }
    };

    fetchAgency();
  }, [lifeId]);

  //get data provider with agency id
  const getDP = async (id) => {
    try {
      const response = await getDataProviderByAgencyId(id);
      return response;
    } catch (error) {
      console.error('Error getting agency:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchDP = async () => {
      if (agencyId) {  // Ensure agency is set before fetching dp
        try {
          const dp = await getDP(agencyId);
          setDPName(dp.name);
          setDPId(parseInt(dp.userId));
          console.log("detDP: " + JSON.stringify(agencyId, null, 2));
        } catch (error) {
          console.error('Error fetching agency:', error);
        }
      }
    };

    fetchDP();
  }, [agencyId]);

  const [reportMap, setReportMap] =
    useState({
      reportNo: "",
      reportStatus: reportStatus,
      dataProvider: {
        id: "",
        name: ""
      },
      agency: {
        id: "",
        agencyName: ""
      },
      reportType: {
        reportTypeId: "",
        description: ""
      },
      reportPeriodYear: selectedYear,
      reportQuarter: selectedQuarter,
      forms: [],
      remarks: []
    });

  useEffect(() => {
    if (agencyId && agencyName && dpID && dpName) {
      setReportMap(prevState => ({
        ...prevState,
        dataProvider: {
          id: dpID,
          name: dpName
        },
        agency: {
          id: agencyId,
          agencyName: agencyName
        }
      }));
    }
  }, [agencyId, agencyName, dpID, dpName]);

  const handleRemarkChange = (event) => {
    const currentRemark = {
      "id": generateRemarkId(),
      "sender": dpName,
      "message": event.target.value,
      "date": currentDateTime
    }
    setRemark(currentRemark);
  }

  function generateRemarkId() {
    const characters = '0123456789abcdef'; // Hex characters
    let result = '';
    let length = 24;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }

    return result;
  }

  const handleSubmitRemark = () => {
    if (!remark) {
      return; // Exits if remark is undefined, null, or an empty string
    } else {
      setRemarks([...remarks, remark]);
    }
  }

  const handleDeleteRemark = (id) => {
    setRemarks(prevRemarks => prevRemarks.filter(remark => remark.id !== id))
  }

  useEffect(() => {
    console.log("remarks: " + JSON.stringify(remarks, null, 2))
    setReportMap((prevReportMap) => ({
      ...prevReportMap,
      remarks: remarks // Update only the remarks portion
    }));
  }, [remarks]); // Add any dependency if needed

  const trySubmitReport = async (reportStatus) => {

    if (selectedQuarter.trim() === "") {
      toast.warn("Please select Quarter for your submission.");
      return;
    }

    try {
      setSubmitLoading(true);
      console.log("obj " + combinedStructure);
      if (combinedStructure) {
        combinedStructure.reportStatus = reportStatus;
        const result = await submitReport(combinedStructure);
        console.log("result: " + JSON.stringify(result, null, 2));
        if (result.status === 200 && result.data) {
          if (reportStatus === "Pending for Approval") {
            //setAlertMessage(`Your ${selectedQuarter} ${selectedYear} Submission has been submitted.`);
            handleCloseConfirmationModal();
            toast.success(`Your ${selectedQuarter} ${selectedYear} Submission has been submitted.`);
            setTimeout(() => {
            }, 5000);
          }
          else {
            toast.success(`Your ${selectedQuarter} ${selectedYear} Submission has been saved as Draft.`);
            setTimeout(() => {
            }, 5000);
          }
          navigate("/neb-data-submission/neb-data-submission-list");
          setIsCurrentFormSaved(true);
        } else {
          console.log("not 200: " + result.status);
          toast.error("Report could not be sent. Error: " + result.status)
          setIsCurrentFormSaved(false);
        }
      } else {
        toast.error("Please try again. Report could not be sent. Error: 'combinedStructure is not ready")
      }
    } catch (error) {
      toast.error("Please try again. Report could not be sent. Error: " + error)
    } finally {
      setSubmitLoading(false);
    }
  };

  const tryUpdateReport = async (reportStatus) => {
    try {
      setSubmitLoading(true);
      if (combinedStructure) {
        combinedStructure.reportStatus = reportStatus;
        const result = await updateReport(reportId, combinedStructure);
        console.log("updating report: " + JSON.stringify(combinedStructure, null, 2));
        if (result.status === 200 && result.data) {
          if (reportStatus === "Pending for Approval") {
            handleCloseConfirmationModal();
            toast.success(`Your ${selectedQuarter} ${selectedYear} Submission has been submitted.`)
          } else if (reportStatus === "Approved") {
            toast.success(`Submission by ${agencyName} ${selectedQuarter} ${selectedYear} has been approved.`);
            handleCloseModal();
            setTimeout(() => {
              navigate("/neb-verification/neb-verification-list");
            }, 5000);
          } else if (reportStatus === "Pending for Amendment") {
            toast.success(`Sent Request For Amendment to ${agencyName}`);
            handleCloseModal();
            setTimeout(() => {
              navigate("/neb-verification/neb-verification-list");
            }, 5000);
          } else {
            toast.success(`Your ${selectedQuarter} ${selectedYear} Submission has been saved as Draft.`)
          }
          //navigate("/neb-data-submission/neb-data-submission-list");
          setAlertType('success');
          setIsCurrentFormSaved(true);
        } else {
          console.log("not 200: " + result.status);
          toast.error("Could not save report. Error: " + result.status)
          setIsCurrentFormSaved(false);
        }
      } else {
        console.error("combine structure " + JSON.stringify(combinedStructure));
      }
    } catch (error) {
      toast.error("Please try again. Could not save report. Error: " + error)
    } finally {
      setSubmitLoading(false);
    }
  };

  const startAutoSave = async () => {
    if (paramsReportStatus == "Draft" || paramsReportStatus == "Pending for Amendment") {
      const interval = setInterval(() => {
        const button = document.getElementById('btn-draft');

        console.log(button);
        // Check if the button is enabled
        if (button && !button.disabled) {
          button.click();
        }
      }, 60000); // 60000 ms = 1 minute
    }
  };

  const stopAutoSave = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    startAutoSave();

    return () => {
      // Cleanup interval on component unmount
      stopAutoSave();
    };
  }, []);


  const handleSaveAsDraft = () => {
    tryUpdateReport("Draft");
    setAutoSaveActive(true); // Activate auto-save on first manual save
  };


  useEffect(() => {
    console.log("Combined Structure: ", JSON.stringify(combinedStructure, null, 2));
  }, [combinedStructure]);

  const toastContainerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1050, // Ensure it's above other elements
    display: 'flex',
    justifyContent: 'center', // Center the alert content
  };

  const toastContentStyle = {
    width: '100%',
    maxWidth: '1200px', // Optional: limit the maximum width
  };

  const roundBalancesTo3DP = (fuelValues) => {
    return fuelValues.map(item => ({
      ...item,
      balance: Number(item.balance.toFixed(3)) // Rounding balance to 3 decimal places
    }));
  };

  // fb balance and unit checking
  useEffect(() => {
    if (fuelValuesFBChecking && productAndUnitFBChecking) {
      console.log("in&del: " + JSON.stringify(fuelValuesFBChecking, null, 2));
      console.log("in&del: " + JSON.stringify(productAndUnitFBChecking, null, 2));

      // Check for non-zero balance
      const nonZeroBalance = roundBalancesTo3DP(fuelValuesFBChecking).some((fuel) => fuel.balance !== 0);
      console.log("okey comparing..." + JSON.stringify(roundBalancesTo3DP(fuelValuesFBChecking), null, 2))
      setFBNotBalanced(nonZeroBalance);

      // Check if any product is missing its unit (considering the category values)
      const missingUnit = fuelValuesFBChecking.some((fuel) => {
        // Check if all values in the categories are 0
        const allCategoriesZero = fuel.forms.every((form) =>
          form.categories.every((category) => (category.value ?? 0) === 0)
        );

        // If all category values are zero, means no data. so, unit is not needed, therefore not missing
        if (allCategoriesZero) {
          console.log("unit missing fb: allCategoriesZero");
          return false;
        } else {
          console.log("unit missing fb: NotallCategoriesZero");
          const foundMatch = productAndUnitFBChecking.some(
            (unit) => unit.fieldName === fuel.productName
          );
          if (foundMatch) { return false; }
          else { return true; }
        }

      });

      setFbUnitIsMissing(missingUnit);
    }
  }, [fuelValuesFBChecking, productAndUnitFBChecking]);


  //c1 balance and unit checking
  useEffect(() => {
    if (fuelValuesC1Checking && productAndUnitC1Checking) {
      // Check for non-zero balance
      const nonZeroBalance = roundBalancesTo3DP((fuelValuesC1Checking)).some((fuel) => fuel.balance !== 0);
      setC1NotBalanced(nonZeroBalance);

      // Check if any product is missing its unit (considering the category values)
      const missingUnit = fuelValuesC1Checking.some((fuel) => {
        // Check if all values in the categories are 0
        const allCategoriesZero = fuel.forms.every((form) =>
          form.categories.every((category) => (category.value ?? 0) === 0)
        );

        // If all category values are zero, means no data. so, unit is not needed, therefore not missing
        if (allCategoriesZero) {
          return false;
        } else {
          const foundMatch = productAndUnitC1Checking.some(
            (unit) => unit.fieldName === fuel.productName
          );
          if (foundMatch) { return false; }
          else { return true; }
        }
      });

      setC1UnitIsMissing(missingUnit);
    }
  }, [fuelValuesC1Checking, productAndUnitC1Checking]);

  //net calorific unit checking
  useEffect(() => {
    if (fuelValuesNETChecking && productAndUnitNETChecking) {
      console.log("net received: " + JSON.stringify(fuelValuesNETChecking, null, 2))
      console.log("net received: " + JSON.stringify(productAndUnitNETChecking, null, 2))
      // Check if any product is missing its unit (considering the category values)

      const missingUnit = fuelValuesNETChecking.some((fuel) => {
        // Check if all values in the categories are 0
        const allCategoriesZero = fuel.forms.every((form) =>
          form.categories.every((category) => (category.value ?? 0) === 0)
        );

        // If all category values are zero, means no data. so, unit is not needed, therefore not missing
        console.log("allcategorieszero: " + allCategoriesZero);
        if (allCategoriesZero) {
          return false;
        } else {
          const foundMatch = productAndUnitNETChecking.some(
            (unit) => unit.fieldName === fuel.productName
          );
          if (foundMatch) { return false; }
          else { return true; }
        }

      });

      setNETUnitIsMissing(missingUnit);
    }
  }, [fuelValuesNETChecking, productAndUnitNETChecking]);

  //block submit if any of the conditions is met
  useEffect(() => {
    console.log("unit missing fb: " + fbUnitIsMissing);
    console.log("unit missing c1: " + c1UnitIsMissing);
    console.log("unit missing net: " + netUnitIsMissing);
    if (
      fbNotBalanced ||
      fbUnitIsMissing ||
      c1NotBalanced ||
      c1UnitIsMissing ||
      netUnitIsMissing
    ) {
      console.log("okey fbNotBalanced: " + fbNotBalanced);
      console.log("okey fbUnitIsMissing: " + fbUnitIsMissing);
      console.log("okey c1NotBalanced: " + c1NotBalanced);
      console.log("okey c1UnitIsMissing: " + c1UnitIsMissing);
      console.log("okey netUnitIsMissing: " + netUnitIsMissing);
      setSubmitBlocked(true);
    } else {
      setSubmitBlocked(false);
    }
  }, [fbNotBalanced, fbUnitIsMissing, c1NotBalanced, c1UnitIsMissing, netUnitIsMissing]);

  //set quarter options based on selected year
  // useEffect(() => {
  //   if (yearAndQuarters && yearAndQuarters.length > 0) {
  //     const quarters = getQuartersByYear(yearAndQuarters, String(selectedYear))
  //     console.log(`submittedqlist: ${selectedYear}` + JSON.stringify(yearAndQuarters, null, 2))
  //     if (quarters && quarters.length > 0) {
  //       setSubmittedQuarterList(quarters);
  //     }
  //   }
  // }, [selectedYear, yearAndQuarters]);

  useEffect(() => {
    console.log("yandq: " + JSON.stringify(yearAndQuarters, null, 2));

    if (yearAndQuarters) {
      // Find the entry in yearAndQuarters for the selectedYear
      const yearData = yearAndQuarters.find((yq) => yq.year === selectedYear);

      if (yearData) {
        const submittedQuarters = yearData.quarters;

        console.log("submittedQuarters: " + JSON.stringify(submittedQuarters, null, 2));

        console.log("selectedQuarter: " + selectedQuarter);

        const allQuarters = ["Q1", "Q2", "Q3", "Q4"];

        // Ensure selectedQuarter is included in filteredQuarters
        const filteredQuarters = allQuarters.filter(
          (quarter) =>
            !submittedQuarters.includes(quarter) || quarter === selectedQuarter
        );

        // Set the quarter list based on isDisabled status
        if (!isDisabled) {
          setQuarterList(filteredQuarters);
        } else {
          setQuarterList(allQuarters);
        }
      } else {
        // If no data exists for the selected year, reset quarterList to all quarters
        setQuarterList(["Q1", "Q2", "Q3", "Q4"]);
      }
    } else {
      // Take from paramsQuarter, paramsYear
      if (paramsYear && paramsQuarter) {
        const allQuarters = ["Q1", "Q2", "Q3", "Q4"];

        // Ensure selectedQuarter is included in filteredQuarters
        const filteredQuarters = allQuarters.filter(
          (quarter) =>
            !paramsQuarter.includes(quarter) || quarter === selectedQuarter
        );

        console.log("paramsQuarter: " + JSON.stringify(paramsQuarter, null, 2));
        console.log("paramsYear: " + paramsYear);

        setQuarterList(filteredQuarters);
      } else {
        // Default to all quarters if no paramsQuarter or paramsYear is provided
        setQuarterList(["Q1", "Q2", "Q3", "Q4"]);
      }
    }
  }, [selectedYear, yearAndQuarters, paramsQuarter, paramsYear, selectedQuarter, isDisabled]);


  useEffect(() => {
    console.log("fetchedre: " + JSON.stringify(fetchedReport, null, 2))
  }, [fetchedReport])

  return (
    <div>      
      <div title={(viewMode === "draft" ? `Update Data (${paramsQuarter} ${paramsYear})` : "Submit Data")} />

      {alertVisible && (
        <div style={toastContainerStyle}>
          <div style={toastContentStyle}>
            <ClayAlert displayType={alertType} title={alertType === 'success' ? 'Success' : 'Error'}>
              {alertMessage}
            </ClayAlert>
          </div>
        </div>
      )}
      {/* {alertVisible && (
        <ClayAlert.ToastContainer>
          <ClayAlert displayType={alertType} title={alertType === 'success' ? 'Success' : 'Error'}>
            {`hello`}
          </ClayAlert>
        </ClayAlert.ToastContainer>
      )} */}

      <Card className="mt-4">        
        <Card.Header>{agencyName}</Card.Header>  
        <Card.Body>
          
          <Form.Group className="row form-group-sm mb-2">
            <Form.Label htmlFor="year" className="col-sm-3 col-lg-2">Year :</Form.Label>
              <Form.Select
                bsPrefix={'form-control'}
                className="col-sm-3 col-lg-2"
                disabled={isDisabledYearQuarter}
                id="year"
                value={selectedYear}
                onChange={handleYSelectChange} >
                <option value="">Select...</option>
                {yearList.map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </Form.Select>
          </Form.Group>
          
          <Form.Group className="row form-group-sm mb-2">
            <Form.Label htmlFor="quarter" className="col-sm-3 col-lg-2">Quarter :</Form.Label>
            <Form.Select
              bsPrefix={'form-control'}
              className="col-sm-3 col-lg-2"
              disabled={isDisabledYearQuarter}
              id="quarter"
              value={selectedQuarter}
              onChange={handleQSelectChange} >
              <option value="">Select...</option>
              {quarterList.map((item) => (
                <option key={item} value={item}>{item}</option>
              ))}
            </Form.Select>
          </Form.Group>

            {region && region.trim() !== "" ? (
              <Form.Group className="row form-group-sm mb-2">
                <Form.Label htmlFor="region" className="col-sm-3 col-lg-2">Region :</Form.Label>
                <Form.Control
                  style={{ color: "black" }}
                  disabled
                  value={region} />
              </Form.Group>
            ) : null}
            <br />

            <DynamicTabsDP
              energySector={energySector}
              viewMode={viewMode}
              fetchedReport={fetchedReport}
              updateCheckedFormsCount={updateCheckedFormsCount}
              setParentFormsCount={setParentFormsCount}
              agencyId={agencyId}
              setFuelValuesNETChecking={setFuelValuesNETChecking}
              setProductAndUnitNETChecking={setProductAndUnitNETChecking}
              setFuelValuesC1Checking={setFuelValuesC1Checking}
              setProductAndUnitC1Checking={setProductAndUnitC1Checking}
              setFuelValuesFBChecking={setFuelValuesFBChecking}
              setProductAndUnitFBChecking={setProductAndUnitFBChecking}
              reportMap={reportMap}
              setReportMap={setReportMap}
              selectedQuarter={selectedQuarter}
              selectedYear={selectedYear}
              reportStatus={reportStatus}
              combinedStructure={combinedStructure}
              setCombinedStructure={setCombinedStructure}
              isDisabled={isDisabled} />
          
        </Card.Body>
      </Card>

      <Card className="mt-4">
        <Card.Header>
          <FontAwesomeIcon icon={solidIcons['faTags']} className="mr-1" /> Remarks
        </Card.Header>  

        <Card.Body>
          
          {remarks && remarks.map(remark => (
            <Card className="mb-2 flat-border">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <Card.Title>
                      <div className="d-flex align-items-center">
                        {/* User Profile Icon */}
                        <FontAwesomeIcon
                          icon={faUserCircle}
                          className="rounded-circle mr-3"
                          style={{ width: "30px", height: "30px", fontSize: "30px" }}
                        />
                        <div>
                          <h5 className="mb-0 p-1">{remark.sender}</h5>
                          <h6 className="mb-0 p-1" style={{ fontWeight: 'normal' }}>{remark.date}</h6>
                        </div>
                      </div>
                    </Card.Title>
                    <br />
                    <Card.Text>
                      <p>{remark.message}</p>
                    </Card.Text>
                  </div>

                  {/* Trash (Delete) Icon */
                    viewMode !== "draft" &&
                    <FontAwesomeIcon
                      icon={faTrash}
                      color="#ffa0a0"
                      //className="text-danger"
                      style={{ cursor: "pointer", fontSize: "20px" }}
                      onClick={() => handleDeleteRemark(remark.id)} // Add your delete function here
                    />
                  }
                </div>
              </Card.Body>
            </Card>

          ))}

          <InputGroup className="form-group-sm mt-3">
            <Form.Control
              onChange={(event) => { handleRemarkChange(event) }}
              placeholder="Type here..."/>
            <Button variant="primary"
              disabled={paramsReportStatus === "Approved" || paramsReportStatus === "Pending for Approval" ? true : false} 
              onClick={handleSubmitRemark}>
              Add Remark
            </Button>
          </InputGroup>

        </Card.Body>
      </Card>

      {(!viewMode || viewMode === "draft") && (
        <div className="mt-5">
          {/* BUTTONS STARTS */}
          <Button
            variant="primary"
            size="sm"
            className="mr-1"
            disabled={paramsReportStatus === "Approved" || paramsReportStatus === "Pending for Approval"}
            onClick={() => {
              if (viewMode === "draft") {
                handleSaveAsDraft();
              } else {
                trySubmitReport("Draft");
              }
            }} >
            Save As Draft
          </Button>

          <Button variant="primary" size="sm" className="mr-1"
            disabled={
              paramsReportStatus === "Approved" || paramsReportStatus === "Pending for Approval"
            }
            onClick={handleShowConfirmationModal} >
            Submit
          </Button>

          <Link onClick={onCancelClick} className="btn btn-sm btn-outline-primary">Cancel</Link>
          {/* BUTTONS ENDS */}

          {/* MODAL STARTS */}
          <Modal show={showModal} onHide={handleCloseConfirmationModal}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Submission</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span>
                You are submitting <strong>{selectedQuarter} {selectedYear}</strong> report on behalf of <strong>{agencyName}</strong> agency.
              </span>
              <br />
              <br />
              {submitBlocked && (
                <p style={{ color: "orange" }}>
                  One of the forms is unbalanced or missing units. Make sure everything is filled out completely and try again.
                </p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-outline-secondary mr-1"
                onClick={handleCloseConfirmationModal}
              >
                Close
              </button>
              <button
                disabled={submitLoading}
                className="btn btn-primary mr-1"
                onClick={() =>
                  viewMode === "draft"
                    ? tryUpdateReport("Pending for Approval")
                    : trySubmitReport("Pending for Approval")
                }
              >
                {submitLoading ? "Loading..." : "Submit"}
              </button>
            </Modal.Footer>
          </Modal>
          {/* MODAL ENDS */}
        </div>
      )}

      {viewMode === "pending" && (
        <div className="mt-5">
          {/* BUTTONS STARTS */}
          <Button
            variant="primary"
            size="sm"
            className="mr-1"
            onClick={() => handleShowModal("Pending for Amendment")} >
            Request for Amendment
          </Button>

          <Button
            variant="primary"
            size="sm"
            className="mr-1"
            disabled={reportStatus === "Approved"}
            onClick={() => handleShowModal("Approved")} >
            Approve
          </Button>

          <Link to={`/reportSubmission`} className="btn btn-outline-primary">Cancel</Link>
          {/* BUTTONS ENDS */}
          
          {/* MODAL STARTS */}
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Submission</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span>
                Are you sure you want to set this report to <strong>{statusAction}</strong>?
              </span>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-outline-secondary mr-1"
                onClick={handleCloseModal}
              >
                Close
              </button>
              <button
                disabled={submitLoading}
                className="btn btn-primary mr-1"
                onClick={() => tryUpdateReport(statusAction)}
              >
                {submitLoading ? "Loading..." : "Submit"}
              </button>
            </Modal.Footer>
          </Modal>
          {/* MODAL ENDS */}
        </div>
      )}
      <br />

    </div>
  );
}

export default SubmitNewReportDP;




