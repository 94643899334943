import React, { useEffect, useRef, useState } from "react";
import profilePlaceholder from "./profile-svgrepo-com.svg";
import useAuthStore from "../../../store/useAuthStore";
import { getAgencyByLiferayId } from "../../../api/nebAgencyService";
import {
  getDataProviderByAgencyId,
  updateColumnDataProviderById,
} from "../../../api/nebDataProviderService";
import {
  fetchUserProfileImage,
  uploadUserImage,
} from "../../../api/userService";
import { Row, Col, Card, Form, InputGroup, Modal, Button, Table, Badge } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { toast, Bounce } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { updatePassword, validatePassword } from "../../../api/userService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import * as solidIcons from '@fortawesome/free-solid-svg-icons';

const ProfileEdit = () => {
  const [profileData, setProfileData] = useState({
    name: "loading",
    role: "loading",
    designation: "loading",
    agency: "loading",
    email: "loading",
    phoneNumber: "loading",
    contactPerson: {
      name: "loading",
      email: "loading",
      phoneNumber: "loading",
    },
    productList: [],
    formList: [],
    profileImageUrl: profilePlaceholder, // Default placeholder image
  });

  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);

  const user = useAuthStore((state) => state.user); // Zustand store for user data
  const logout = useAuthStore((state) => state.logout); // Zustand logout function
  const fileInputRef = useRef(null); // Ref for the hidden file input

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!user?.id) return;

      try {
        console.log("user id profile: ", user.id);
        const agency = await getAgencyByLiferayId(user.id);
        const dp = await getDataProviderByAgencyId(agency.id);

        // Fetch the profile image as a base64-encoded string
        const profileImageBase64 = await fetchUserProfileImage();

        setProfileData({
          name: dp.name,
          role: "Data Provider", // Example role
          designation: "Agency Representative", // Example designation
          agency: agency.agencyName,
          email: dp.email,
          phoneNumber: dp.phoneNo,
          contactPerson: {
            name: dp.agency.cpdName,
            email: dp.agency.cpdEmail,
            phoneNumber: dp.agency.cpdPhoneNumber,
          },
          productList: extractProductList(dp),
          formList: extractFormFields(dp),
          profileImageUrl: profileImageBase64 || profilePlaceholder, // Set base64 URL for image
        });
        setPhoneNumber(dp.phoneNo);
      } catch (error) {
        console.error("Error fetching profile data:", error);
        logout(); // Handle token invalidation
      }
    };

    if (user?.id) {
      fetchProfileData();
    }
  }, [user, logout]);

  // Trigger file input click
  const handleProfileImageClick = () => {
    fileInputRef.current.click(); // Trigger the hidden file input when the image is clicked
  };

  const handleCancel = () => {
    const confirmation = window.confirm(
      "Are you sure you want to cancel this process?"
    );
    if (confirmation) {
      toast.success(`The process have been cancel`);
      setTimeout(() => {
        navigate(`/neb-profile/view`);
      }, 2000);
    }
  };

  const handlePhoneChange = (e) => {
    setProfileData({
      ...profileData,
      phoneNumber: e.target.value,
    });
  };

  const handleSave = async () => {
    try {
      if (!user?.id) return;

      // Update phone number if changed
      const agency = await getAgencyByLiferayId(user.id);
      const dp = await getDataProviderByAgencyId(agency.id);

      if (phoneNumber !== existingPhoneNumber) {
        await updateColumnDataProviderById(dp.userId, { phoneNo: phoneNumber });
      }

      // Update password if new and confirm match
      if (password === confirmPassword) {
        const passwordData = {
          password: password,
          currentPassword: currentPassword,
        };
        const response = await updatePassword(passwordData);
        console.log("Password updated successfully:", response);
      } else {
        toast.error("Passwords do not match!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      toast.success(`Edit Profile success!`);
      setTimeout(() => {
        navigate(`/neb-profile/view`);
      }, 2000);
    } catch (error) {
      console.error("Error saving profile changes:", error);

      // Parse the error response
      try {
        const errorData = error.response?.data; // Assuming error has a `response.data` field
        if (errorData?.message?.type === "UserPasswordException.MustMatchCurrentPassword") {
          toast.error("Invalid Current Password. Please check and re-enter.", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error(errorData?.message?.title || "An error occurred. Please try again.", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (parseError) {
        console.error("Failed to parse error response:", parseError);
        toast.error("An unexpected error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };


  // Check if the passwords match while typing
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordMatch(e.target.value === password);
  };

  const handleUpdateRequest = () => {
    const confirmation = window.confirm(
      "Are you sure you want to proceed with the update? You will be redirected to the Report & Resolve Center."
    );
    if (confirmation) {
      toast.success(`You have been redirected to the Report & Resolve Center.`);
      setTimeout(() => {
        navigate(`/neb-data-provider-ticket/new`);
      }, 2000);
    }
  };

  // Handle file selection and upload
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("image", file);

        // Upload the image
        const response = await uploadUserImage(formData);
        if (response.status == 200) {
          const updatedProfileImageBase64 = await fetchUserProfileImage(); // Fetch the updated profile image
          setProfileData((prevState) => ({
            ...prevState,
            profileImageUrl: updatedProfileImageBase64 || profilePlaceholder,
          }));
        }
      } catch (error) {
        console.error("Error uploading profile image:", error);
      }
    }
  };

  const extractProductList = (data) => {
    return data.agency.categories.flatMap((category) =>
      category.products.map((product) => ({
        productName: product.productName,
      }))
    );
  };

  const extractFormFields = (data) => {
    if (!data?.agency?.forms) return [];

    const formsList = data.agency.forms
      .map((form) => {
        const filteredFields = form.formFields.filter(
          (field) => !field.formFieldCode.includes("-NA")
        );
        if (filteredFields.length === 0) return null;

        return {
          formType: form.formType,
          formDescription: form.formDescription,
          formFields: filteredFields.map((field) => ({
            formFieldCode: field.formFieldCode,
            formFieldName: field.formFieldName,
          })),
        };
      })
      .filter(Boolean);

    if (data.agency.installedCapacity) {
      formsList.push({ formDescription: "Installed Capacity", formFields: [] });
    }
    if (data.agency.powerPlantPerformance) {
      formsList.push({
        formDescription: "Power Plant Performance",
        formFields: [],
      });
    }
    if (data.agency.netCalorificValue) {
      formsList.push({
        formDescription: "Net Calorific Value",
        formFields: [],
      });
    }

    return formsList;
  };

  const {
    name,
    role,
    designation,
    agency,
    email,
    phoneNumber: existingPhoneNumber,
    contactPerson,
    productList,
    formList,
    profileImageUrl,
  } = profileData;

  return (
    <Card className="mt-4">
      <Card.Body>
        <Row>
          {/* Profile Image */}
          <Col className="text-center">
            <img
              src={profileImageUrl}
              alt="Profile"
              className="profile-image mb-3"
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={handleProfileImageClick} />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept="image/*" />
            <h3 className="profile-name">{name}</h3>
            <p className="mb-0">{role}</p>
            <p className="mb-0">{designation}</p>
          </Col>
        </Row>

        {/* Product & Form Section */}
        <Row className="mt-4">
          <Col>
            <Badge pill text="white" className="badge-color1 text-md">
              <FontAwesomeIcon icon={solidIcons['faTags']} className="mr-2" /> PRODUCT & FORM
            </Badge>
            <Link className="ml-2" onClick={handleUpdateRequest}>
              <FontAwesomeIcon icon={solidIcons['faChevronCircleRight']} /> Request to Update
            </Link>
            <br /><br />
            {/* <div className="d-flex justify-content-between align-items-center">Text Right</div> */}

            <h5>PRODUCT</h5>
            <ol type="1">
              {productList.length ? (
                productList.map((product, index) => (
                  <li key={index} className="text-sm">{product.productName}</li>
                ))
              ) : (
                <li className="text-sm">No Products Available</li>
              )}
            </ol>
            <br />

            <h5>FORM</h5>
            <ol type="1">
              {formList.length ? (
                formList.map((form, index) => (
                  <li key={index} className="mt-2">
                    <div>{form.formDescription}</div>
                    <ul type="disc">
                      {form.formFields.map((field, fieldIndex) => (
                        <li key={fieldIndex} className="text-sm">{field.formFieldCode} ({field.formFieldName})</li>
                      ))}
                    </ul>
                  </li>
                ))
              ) : (
                <li className="text-sm">No Forms Available</li>
              )}
            </ol>
          </Col>
        </Row>

        {/* Change Password Section */}
        <Row className="mt-4">
          <Col>
            <Badge pill text="white" className="badge-color1 text-md">
              <FontAwesomeIcon icon={solidIcons['faTags']} className="mr-2" /> Change Password
            </Badge>
          </Col>
        </Row>

        <Form className="mt-4">
          <Form.Group className="row mb-2">
            <Form.Label htmlFor="currentPassword" className="col-sm-3 col-lg-2">Current Password:</Form.Label>
            <Col className="col-sm-9 col-lg-10">
              <Form.Control type="password" placeholder="Current Password" value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                autoComplete="off" />
            </Col>
          </Form.Group>

          <Form.Group className="row mb-2">
            <Form.Label htmlFor="newPassword" className="col-sm-3 col-lg-2">New Password:</Form.Label>
            <Col className="col-sm-9 col-lg-10">
              <Form.Control type="password" placeholder="New Password" value={password}
                onChange={(e) => setPassword(e.target.value)} />
            </Col>
          </Form.Group>

          <Form.Group className="row mb-2">
            <Form.Label htmlFor="confirmPassword" className="col-sm-3 col-lg-2">Confirm New Password:</Form.Label>
            <Col className="col-sm-9 col-lg-10">
              <Form.Control type="password" placeholder="Confirm New Password" value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                isInvalid={!passwordMatch} />
            </Col>
          </Form.Group>

          <Form.Group className="row mb-2">
            <Form.Label htmlFor="phoneNumber" className="col-sm-3 col-lg-2">Phone Number:</Form.Label>
            <Col className="col-sm-9 col-lg-10">
              <Form.Control id="phoneNumber" placeholder="Phone Number" value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)} />
            </Col>
          </Form.Group>
        </Form>

        {/* Save & Cancel Buttons */}
        <Row className="mt-4">
          <Col>
            <Button variant="primary" size="sm" className="mr-2" onClick={handleSave} disabled={!passwordMatch}>Save</Button>
            <Button variant="outline-primary" size="sm" onClick={handleCancel}>Cancel</Button>
          </Col>
        </Row>
        <br />

      </Card.Body>
    </Card>
  );
};

export default ProfileEdit;
