import React, { useState } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import glossaryData from "./glossary.json";

function Glossary() {
  const [visibleModuleGuide, setVisibleModuleGuide] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const renderGlossary = (data) => {
    return data.map((item, index) => {
      const isTermMatch = item.term.toLowerCase().includes(searchQuery.toLowerCase());
      const isDefinitionMatch = item.definition.toLowerCase().includes(searchQuery.toLowerCase());
      const isChildMatch = item.children?.some(
        (child) =>
          child.term.toLowerCase().includes(searchQuery.toLowerCase()) ||
          child.definition.toLowerCase().includes(searchQuery.toLowerCase())
      );

      // Show only matching items
      if (!isTermMatch && !isDefinitionMatch && !isChildMatch) {
        return null;
      }

      return (
        <React.Fragment key={index}>
          <tr>
            <td><b>{item.term}</b></td>
            <td>{item.definition}</td>
          </tr>
          {item.children &&
            item.children
              .filter(
                (child) =>
                  child.term.toLowerCase().includes(searchQuery.toLowerCase()) ||
                  child.definition.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((child, childIndex) => (
                <tr key={`${index}-${childIndex}`}>
                  <td style={{ paddingLeft: "2rem" }}>{child.term}</td>
                  <td>{child.definition}</td>
                </tr>
              ))}
        </React.Fragment>
      );
    });
  };

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h3 className="card-title">
          <b className="tm-header">Glossary</b>
        </h3>
        <button
          type="button"
          onClick={() => setVisibleModuleGuide(!visibleModuleGuide)}
          className="btn btn-tool"
          title={visibleModuleGuide ? "Collapse" : "Expand"}
        >
          <i className={`fas ${visibleModuleGuide ? "fa-minus" : "fa-plus"}`} />
        </button>
      </div>
      <div
        className="card-body"
        style={{ display: visibleModuleGuide ? "block" : "none" }}
      >
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search terms or definitions..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Term</th>
              <th>Definition</th>
            </tr>
          </thead>
          <tbody>{renderGlossary(glossaryData)}</tbody>
        </table>
      </div>
    </div>
  );
}

export default Glossary;
