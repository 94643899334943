import React, { useState, useEffect } from 'react';
import { ClaySelect, ClayInput } from '@clayui/form';
import NetCalorificTableCheckingDP from './NetCalorificTableCheckingDP';
import { getProductAndForms } from "../../../../api/nebProductService";
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NumericFormat } from 'react-number-format';
import { exportToPdf, exportToExcel } from './exportToPdf';
import "../../../../App.css";
import { Row, Col, Card, Form, InputGroup, Modal, Button, Table, Badge, Accordion } from 'react-bootstrap';

const NetCalorificTableDP = ({ viewMode, fetchedReport, updateCheckedFormsCount, setFuelValuesNETChecking, setProductAndUnitNETChecking, selectedQuarter, selectedYear, setCombinedStructure, reportMap, setReportMap, reportStatus,isDisabled }) => {
    const [reportBasicInfo, setReportBasicInfo] = useState();

    const [headers, setHeaders] = useState([]);
    const [rows, setRows] = useState([]);
    const [productAndFormsList, setProductList] = useState([]);
    const [inputsPopulated, setInputsPopulated] = useState(false);

    const handleShowCheckingModal = () => {
        setShowModal(true)
    };
    const handleCloseCheckingModal = () => {
        console.log("closing checking modal");
        setShowModal(false);
    };
    const [checkingReport, setCheckingReport] = useState();
    const [showModal, setShowModal] = useState(false);
    const [checked, setChecked] = useState(false);


    const [totals, setTotals] = useState({
        "total,Coal": 0,
        "total,Oil": 0,
        "total,Gas": 0
    }
    );

    //API call runs only once, after the initial render
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getProductAndForms('0', 'NETC');
                setProductList(result);
                console.log('ic: ' + JSON.stringify(result, null, 2))
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const inputStyle = {
        textAlign: "right",
        width: "100%",
        height: "30px",
        marginRight: "5px",
        borderRadius: "5px"
    };


    const [fuelValues, setFuelValues] = useState([]);

    const handleFuelValueChange = (e, index, productName, parentFormFieldCode, formFieldName, formFieldCode, subtractive) => {
        console.log("icchange: " + index + ", " + productName + ", " + parentFormFieldCode + ", " + formFieldName + ", " + formFieldCode);
        const { floatValue = 0 } = e || {};

        // Ensure the value is a valid number or fallback to 0
        let value = !isNaN(floatValue) ? parseFloat(floatValue) : 0;

        // Make value negative based on form type
        if (subtractive) {
            value = -Math.abs(value);
        }

        setFuelValues(prevFuelValues => {
            // Check if an object with matching formFieldCode exists
            const exists = prevFuelValues.some(item => item.productName === productName);

            if (exists) {
                // Update the existing object
                return prevFuelValues.map(item => {
                    if (item.index === index) {
                        // Find the form
                        let formIndex = item.forms.findIndex(form => form.formCode === parentFormFieldCode);
                        let updatedForms;

                        if (formIndex !== -1) {
                            // Update the existing form
                            updatedForms = item.forms.map((form, idx) => {
                                if (idx === formIndex) {
                                    let updatedCategories = form.categories.map(category => {
                                        if (category.formCode === formFieldCode) { // Changed `name` to `formFieldCode`
                                            return { formCode: formFieldCode, value: value, description: formFieldName }; // Changed `name` to `formFieldCode`
                                        } else {
                                            return category;
                                        }
                                    });

                                    // If the category doesn't exist, add it
                                    if (!updatedCategories.some(category => category.formCode === formFieldCode)) { // Changed `name` to `formFieldCode`
                                        updatedCategories.push({ formCode: formFieldCode, value: value, description: formFieldName }); // Changed `name` to `formFieldCode`
                                    }

                                    return { ...form, categories: updatedCategories };
                                } else {
                                    return form;
                                }
                            });
                        } else {
                            // Add a new form
                            updatedForms = [...item.forms, { formCode: parentFormFieldCode, categories: [{ formCode: formFieldCode, value: value, description: formFieldName }] }];
                        }

                        // Calculate the new balance
                        const balance = updatedForms.reduce((sum, form) => {
                            return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
                        }, 0);

                        return { ...item, forms: updatedForms, balance: balance };
                    } else {
                        return item;
                    }
                });
            } else {
                // Add a new object
                const newItem = {
                    index: index,
                    balance: value,
                    productName: productName,
                    forms: [
                        {
                            formCode: parentFormFieldCode,
                            categories: [
                                { formCode: formFieldCode, value: value, description: formFieldName } // Changed `name` to `formFieldCode`
                            ]
                        }
                    ]
                };

                // Calculate the new balance
                const balance = newItem.forms.reduce((sum, form) => {
                    return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
                }, 0);

                return [
                    ...prevFuelValues,
                    { ...newItem, balance: balance }
                ];
            }
        });
    };

    //calculate totals as input elements' value changes
    const [categoryTotals, setCategoryTotals] = useState([]);

    useEffect(() => {
        // Grouping products by category and calculating the total balance for each category
        const categoryMap = fuelValues.reduce((acc, product) => {
            const category = product.productName.split(',')[1]; // Extract category after the comma
            if (!acc[category]) {
                acc[category] = { products: [], total: 0 };
            }
            acc[category].products.push(product);
            acc[category].total += product.balance;
            return acc;
        }, {});

        // Build the final output array
        let finalOutput = [];
        for (const [category, { products, total }] of Object.entries(categoryMap)) {
            finalOutput = [...finalOutput, ...products, { [`total,${category}`]: total }];
        }

        setCategoryTotals(finalOutput);
    }, [fuelValues]);

    useEffect(() => {
        console.log("cat: " + JSON.stringify(categoryTotals, null, 2))
    }, [categoryTotals])

    const [productAndUnit, setProductAndUnit] = useState([]);

    const handleUnitChange = (e, index, productName) => {
        const { value: unit } = e.target;

        setProductAndUnit(prevProductAndUnit => {
            // Check if an object with matching productName exists
            const exists = prevProductAndUnit.some(item => item.fieldName === productName);

            if (exists) {
                // Update the existing object
                return prevProductAndUnit.map(item => {
                    if (item.fieldName === productName) {
                        return {
                            ...item,
                            unit: unit
                        };
                    } else {
                        return item;
                    }
                });
            } else {
                // Add a new object
                const newItem = {
                    fieldName: productName,
                    unit: unit
                };

                return [
                    ...prevProductAndUnit,
                    newItem
                ];
            }
        });
    };

    useEffect(() => {
        console.log("debug Updated productAndUnit: ", JSON.stringify(productAndUnit, null, 2));
    }, [productAndUnit]);


    useEffect(() => {
        if (productAndFormsList.length === 0) return;

        // Group products by their category (split by comma)
        const groupedProducts = productAndFormsList.reduce((acc, product) => {
            const [productType, category] = product.productName.split(',');
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(product);
            return acc;
        }, {});

        const dynamicRows = [];
        let categoryIndex = 1;

        console.log("productAndFormsList: " + JSON.stringify(productAndFormsList, null, 2));

        // Iterate over the grouped products by category
        for (const category in groupedProducts) {
            // Add a row for the category with colSpan extended to cover all columns
            dynamicRows.push({
                category: (
                    <span>
                        <strong>{category}</strong>
                    </span>
                )
            });

            // Add the rows for each product under the current category
            groupedProducts[category].forEach((product, index) => {
                const fuelObject = fuelValues.find(fuel => fuel.index === index + 1);
                dynamicRows.push({
                    no: index + 1,
                    product: product.productName.split(',')[0], // Display only the product name
                    ...product.forms.reduce((acc, form) => {
                        acc[form.formFieldCode] = (
                            <NumericFormat
                                className='no-spinner'
                                disabled={isDisabled}
                                key={`${index + 1}_${form.formFieldCode}`}
                                id={`num_netc_draft_${product.productName}_${form.formFieldCode}`}
                                style={inputStyle}
                                type="text"
                                name={form.formFieldCode}
                                customInput={ClayInput}
                                thousandSeparator
                                onValueChange={(values) => {
                                    handleFuelValueChange(
                                        values, product.productName, product.productName, form.parentFormFieldCode, form.formFieldName, form.formFieldCode, form.subtractive
                                    );
                                }}
                            />
                        );
                        return acc;
                    }, {}),
                    unit: (
                        // <ClaySelect onChange={(e) => handleUnitChange(e, index, product.productName, product.units[e.target.selectedIndex - 1].inKtoe)}>
                        //     <option value="">Select...</option>
                        //     {product.units.map((unit, unitIndex) => (
                        //         <option key={unitIndex} value={unit.productUnitName}>
                        //             {unit.productUnitName}
                        //         </option>
                        //     ))}
                        // </ClaySelect>
                        <ClaySelect id={`selectUnit_netc_draft_${product.productName}`} onChange={(e) => handleUnitChange(e, index, product.productName, 1)} disabled={isDisabled}>
                            <option value="">Select...</option>
                            {product.units.map((unit, unitIndex) => (
                                <option key={unitIndex} value={unit.productUnitName}>
                                    {unit.productUnitName}
                                </option>
                            ))}
                        </ClaySelect>
                    ),
                });
            });

            // // Add the total row for the category
            // const totalForCategory = categoryTotals.find(item => item[`total,${category}`]);
            // if (totalForCategory) {
            //     console.log("found!");
            //     dynamicRows.push({
            //         category: (
            //             // <span>
            //             //     <strong>TOTAL</strong>
            //             //     <ClayInput
            //             //         key={`total,${category}`}
            //             //         style={inputStyle}
            //             //         type="number"
            //             //         name={`total,${category}`}
            //             //         disabled={true}
            //             //         value={totalForCategory[`total,${category}`]}
            //             //     />

            //             // </span>
            //             <span>
            //                 <strong>Total {category}: {totalForCategory[`total,${category}`]}</strong>
            //             </span>
            //         )
            //     });
            // }

            // categoryIndex++; // Increment category number
        }

        setRows(dynamicRows);
    }, [categoryTotals, productAndFormsList, fuelValues]);


    //After rows changes, headers is formed
    useEffect(() => {
        if (productAndFormsList.length === 0) return;

        const dynamicHeaders = [
            //{ id: 'no', name: 'No.' },
            { id: 'product', name: 'Product' },
            ...productAndFormsList[0].forms.map(form => ({
                id: form.formFieldCode,
                name: `${form.formFieldName}`
            })),
            { id: 'unit', name: 'Unit' },

        ];
        setHeaders(dynamicHeaders);
        console.log("dyn: " + JSON.stringify(dynamicHeaders, null, 2))
    }, [rows]);

    //merge

    // const [combinedStructure, setCombinedStructure] = useState(null);

    useEffect(() => {
        const formsMap = {};

        fuelValues.forEach(fuelItem => {
            fuelItem.forms.forEach(form => {
                if (!formsMap[form.formCode]) {
                    formsMap[form.formCode] = {
                        formCode: form.formCode,
                        categories: []
                    };
                }

                form.categories.forEach(category => {
                    const product = {
                        fieldName: fuelItem.productName,
                        value: Math.abs(category.value), // ensure always positive
                        unit: productAndUnit.find(item => item.fieldName === fuelItem.productName)?.unit || ''
                    };

                    const categoryIndex = formsMap[form.formCode].categories.findIndex(cat => cat.formCode === category.formCode);

                    if (categoryIndex !== -1) {
                        formsMap[form.formCode].categories[categoryIndex].products.push(product);
                    } else {
                        formsMap[form.formCode].categories.push({
                            formCode: category.formCode,
                            description: category.description,
                            products: [product],
                            total: totals[category.formCode]
                        });
                    }
                });
            });
        });

        const formsArray = Object.values(formsMap);

        console.log("fb cat, reportMap.forms : ", JSON.stringify(...reportMap.forms, null, 2));
        console.log("fb cat, formsArray : ", JSON.stringify(...formsArray, null, 2));

        //reportMap.forms = [...reportMap.forms, ...formsArray];
        //setCombinedStructure(reportMap);

        setReportMap(prevState => ({
            ...prevState,
            reportPeriodYear: selectedYear,
            reportQuarter: selectedQuarter,
            forms: [
                // Remove existing forms with the same formCode
                ...prevState.forms.filter(form => !formsArray.some(newForm => newForm.formCode === form.formCode)),
                // Add the updated forms
                ...formsArray
            ]
        }));
    }, [totals, fuelValues, productAndUnit, selectedQuarter, selectedYear]);

    useEffect(() => {
        setCombinedStructure(reportMap);
        setCheckingReport(reportMap);
        //Get report basic info
        setReportBasicInfo(
            {
                "year": reportMap.reportPeriodYear,
                "quarter": reportMap.reportQuarter,
                "agency": reportMap.agency.agencyName,
                "dataProvider": reportMap.dataProvider.name,
                "status": reportMap.reportStatus,
                "type": "Net Calorific"
            }
        )
    }, [reportMap]);

    useEffect(() => {
        setProductAndUnitNETChecking(productAndUnit);
    }, [productAndUnit]);

    useEffect(() => {
        setFuelValuesNETChecking(fuelValues);
    }, [fuelValues]);

    //after finish forming rows and headers, try fetch report. if got result, fill into the inputs.
    useEffect(() => {
        if (fetchedReport && productAndFormsList.length > 0 && !inputsPopulated && (viewMode === "draft" || viewMode === "pending")) {
            // Iterate through the forms and categories in fetchedData
            fetchedReport.forms.forEach((form) => {
                form.categories.forEach((category) => {
                    category.products.forEach((product) => {

                        // NUMERICAL INPUTS
                        // Construct the id to match the <input> elements
                        const numInputId = `num_netc_draft_${product.fieldName}_${category.formCode}`;
                        const numInputElement = document.getElementById(numInputId);

                        if (numInputElement) {
                            const value = product.value;
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
                            nativeInputValueSetter.call(numInputElement, value);

                            // Dispatch the 'input' event to trigger onChange in React
                            const event = new Event('input', { bubbles: true });
                            numInputElement.dispatchEvent(event);
                        }

                        // DROPDOWN INPUTS
                        // Construct the id to match the <select> elements
                        const selectInputId = `selectUnit_netc_draft_${product.fieldName}`;
                        const selectElement = document.getElementById(selectInputId);

                        if (selectElement) {
                            const value = product.unit;
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, 'value').set;
                            nativeInputValueSetter.call(selectElement, value);

                            // Dispatch the 'input' event to trigger onChange in React
                            const event = new Event('change', { bubbles: true });
                            selectElement.dispatchEvent(event);
                        }
                    });
                });
            });
            setInputsPopulated(true);
        }
    }, [fetchedReport, headers]);


    return (
        <div>
            <Table id="net-calorific-table" responsive bordered hover size="sm" className="mt-1" style={{width: '45em'}}>
            <thead>
                <tr>
                {headers.map((column) => (
                    <th
                        key={column.id}
                        className="table-cell-minw-200"
                        style={column.id === 'balance' ? {
                            position: 'sticky',
                            left: 0,
                            backgroundColor: 'white',
                            zIndex: 1,
                            borderRight: '1px solid #dee2e6'
                        } : {}}
                    >
                        {column.name}
                    </th>
                ))}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.category ? (
                            // Render category row with colSpan
                            <td colSpan={headers.length} className='pt-4'>
                                {row.category}
                            </td>
                        ) : (
                            // Render product rows
                            headers.map((column) => (
                                <td
                                    key={column.id}
                                    className="table-cell-minw-200"
                                    style={column.id === 'balance' ? {
                                        position: 'sticky',
                                        left: 0,
                                        backgroundColor: 'white',
                                        zIndex: 1,
                                        borderRight: '1px solid #dee2e6'
                                    } : {}}
                                >
                                    {row[column.id]}
                                </td>
                            ))
                        )}
                    </tr>
                ))}
            </tbody>
            </Table>
            <br />

            {/* Export to PDF */}
            <FontAwesomeIcon
                title="Download as PDF"
                style={{ cursor: 'pointer' }}
                onClick={() => exportToPdf("net-calorific-table", reportBasicInfo)}
                className='mr-2 text-primary'
                size='xl'
                icon={faFilePdf}
            />

            {/* Export to Excel */}
            <FontAwesomeIcon
                title="Download as Excel"
                style={{ cursor: 'pointer' }}
                onClick={() => exportToExcel("net-calorific-table", reportBasicInfo)}
                className='text-primary'
                size='xl'
                icon={faFileExcel}
            />
            <br /><br />
            {/* <ClayButton onClick={handleShowCheckingModal}>Check</ClayButton > */}
                
            <Modal size='lg' show={showModal} onHide={handleCloseCheckingModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body><span>Check all numbers and units in your Net Calorific. </span>
                    {/* <table>
                        <tr>
                            <td>
                                <ClayToggle
                                    label="Checked"
                                    onToggle={(newVal) => newVal === true ? (updateCheckedFormsCount(+1), setChecked(newVal)) : (updateCheckedFormsCount(-1), setChecked(newVal))} // Explicitly pass the new toggle state
                                    toggled={checked}
                                />
                            </td>
                        </tr>
                    </table> */}
                    <div>

                        <NetCalorificTableCheckingDP
                            checkingReport={checkingReport} />
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    {/* <button className="btn btn-outline-secondary mr-1" onClick={handleCloseCheckingModal}>
            Close
          </button>
          <button
                onClick={() => submitReport("Draft")}
                className="btn btn-outline-primary mr-1"
              >
                Save As Draft
              </button>
          <button className="btn btn-primary mr-1" onClick={handleShowConfirmationModal}>
            Submit
          </button> */}

                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default NetCalorificTableDP;
