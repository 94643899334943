import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { ClaySelect } from "@clayui/form";
import { getDTAResult } from "../../../../api/nebReportService";
import { ClayToggle } from "@clayui/form";
import { Row, Col, Card, Form, InputGroup, Modal, Button, Table, Badge } from 'react-bootstrap';

const DataTrendAnalysisDP = ({
  selectedYear,
  selectedQuarter,
  productList,
  formList,
  productAndFormsList,
  agencyId,
}) => {
  const [categories, setCategories] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedForm, setSelectedForm] = useState("");
  const [series, setSeries] = useState([]);
  const [dtaFetchResult, setDTAFetchResult] = useState({});

  const [showKtoe, setShowKtoe] = useState(true);
  const handleKtoeToggle = (newVal) => {
    //(newVal) => setShowKtoe(newVal);
    setShowKtoe(newVal);

    document.getElementById('DTA_unitoriginal').style.fontWeight = (newVal ? 'normal':'bold');
    document.getElementById('DTA_unitktoe').style.fontWeight = (newVal ? 'bold':'normal');
  };

  const [originalUnit, setOriginalUnit] = useState("Original Unit");

  useEffect(() => {
    if (productList) {
      setSelectedProduct(productList[0]);
      setSelectedForm(formList[0]);
    }
    console.log("fb dta: " + JSON.stringify(productAndFormsList, null, 2));
  }, [productList, formList]);

  // UseEffect to generate dummy data
  useEffect(() => {
    const fetchProductStats = async () => {
      try {
        const result = await getDTAResult(
          selectedQuarter,
          selectedYear,
          selectedProduct,
          selectedForm.split(":")[0].trim(),
          agencyId
        );
        console.log(
          "dta result: " +
            selectedQuarter +
            selectedYear +
            "\n" +
            JSON.stringify(result.data, null, 2)
        );
        setDTAFetchResult(result.data);
      } catch (error) {
        console.error("Error generating dummy data:", error);
      }
    };

    fetchProductStats();
  }, [selectedQuarter, selectedYear, selectedProduct, selectedForm, agencyId]);

  useEffect(() => {
    console.log("selectedProduct: ", selectedProduct);
    console.log("selectedForm: ", selectedForm);
    console.log("dtaFetchResult: ", dtaFetchResult);

    if (selectedProduct && dtaFetchResult && dtaFetchResult[0]) {
      console.log("dtaFetchResult.products: ", dtaFetchResult[0].products);

      const selectedProductDataF = dtaFetchResult[0].products.find(
        (product) => {
          console.log("product.productName: ", product.productName);
          return product.productName === selectedProduct;
        }
      );

      let selectedProductDataC1 = null;
      if (dtaFetchResult[0].forms) {
        dtaFetchResult[0].forms.forEach((form) => {
          form.categories.forEach((category) => {
            const product = category.products.find(
              (product) => product.fieldName === selectedProduct
            );
            if (product) {
              selectedProductDataC1 = product;
            }
          });
        });
      }

      console.log("selectedProductDataF: ", selectedProductDataF);
      console.log("selectedProductDataC1: ", selectedProductDataC1);

      if (selectedProductDataF) {
        setOriginalUnit(selectedProductDataF.unit);
        console.log("unit from dtaFetchResult: ", selectedProductDataF.unit);
      } else if (selectedProductDataC1) {
        setOriginalUnit(selectedProductDataC1.unit);
        console.log("unit from dtaFetchResult: ", selectedProductDataC1.unit);
      } else {
        setOriginalUnit("Original Unit");
        console.log("no unit found, set to 'Original Unit'");
      }
    } else {
      console.log("dtaFetchResult or product not found");
    }
  }, [selectedProduct, dtaFetchResult, showKtoe, selectedForm]);

  useEffect(() => {
    if (dtaFetchResult && dtaFetchResult.length > 0) {
      const years = new Set();
      const values = {};

      dtaFetchResult.forEach((report) => {
        const dtaFetchReport = showKtoe ? convertToKtoe(report) : report;
        const year = dtaFetchReport.reportPeriodYear;

        if (Array.isArray(dtaFetchReport.forms)) {
          dtaFetchReport.forms.forEach((form) => {
            if (Array.isArray(form.categories)) {
              form.categories.forEach((category) => {
                if (category.formCode === selectedForm.split(":")[0].trim()) {
                  if (Array.isArray(category.products)) {
                    category.products.forEach((product) => {
                      if (product.fieldName === selectedProduct) {
                        if (!values[year]) {
                          values[year] = product.value;
                        } else {
                          values[year] += product.value;
                        }
                        years.add(year);
                      }
                    });
                  }
                }
              });
            }
          });
        }

        if (Array.isArray(dtaFetchReport.products)) {
          dtaFetchReport.products.forEach((product) => {
            if (Array.isArray(product.forms)) {
              product.forms.forEach((form) => {
                if (Array.isArray(form.categories)) {
                  form.categories.forEach((category) => {
                    if (
                      category.formCode === selectedForm.split(":")[0].trim()
                    ) {
                      if (product.productName === selectedProduct) {
                        if (!values[year]) {
                          values[year] = category.value;
                        } else {
                          values[year] += category.value;
                        }
                        years.add(year);
                      }
                    }
                  });
                }
              });
            }
          });
        }
      });

      const xAxisLabels = Array.from(years).sort();
      console.log("xAxisLabels:", xAxisLabels);
      const series = xAxisLabels.map((year) => values[year] || 0);
      console.log("series :", series);

      setSeries([
        {
          name: selectedProduct,
          data: series,
        },
      ]);
      setCategories(xAxisLabels);
    } else {
      setSeries([]);
      setCategories([]);
    }
  }, [dtaFetchResult, showKtoe]);

  useEffect(() => {
    console.log("dta series: " + JSON.stringify(series, null, 2));
    console.log("dta series: cat: " + JSON.stringify(categories, null, 2));
    if (series.length < 0) {
    }
  }, [series, categories]);

  // Highcharts options
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: `${selectedProduct} comparison for ${
        selectedForm?.replace(/^([^:]+?)-[^:]+:(.+)$/, "$1: $2") || ""
      } in ${selectedQuarter} (${showKtoe ? "ktoe" : originalUnit})`,
    },

    xAxis: {
      categories: categories,
      title: {
        text: "",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: showKtoe ? "ktoe" : originalUnit,
      },
    },
    tooltip: {
      formatter: function () {
        const fractionDigits = showKtoe ? 4 : 2;
        return `<b>${this.series.name}</b>: ${this.y.toLocaleString(undefined, {
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits,
        })}`;
      },
    },
    series: series,
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  };

  const handleProductSelectChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  const handleSelectedFormChange = (event) => {
    setSelectedForm(event.target.value);
  };

  function convertToKtoe(report) {
    if (!report) return report; // Early return if report is null or undefined

    // Deep clone the report to avoid mutating the original state
    const updatedReport = {
      ...report,
      // Handle nested `forms`
      forms: report.forms?.map((form) => ({
        ...form,
        categories: form.categories.map((category) => ({
          ...category,
          products: category.products.map((product) => {
            const matchingProduct = productAndFormsList.find(
              (p) => p.productName === product.fieldName
            );

            if (matchingProduct) {
              const matchingUnit = matchingProduct.units.find(
                (unit) =>
                  unit.productUnitName === product.unit ||
                  unit.productUnitName === "ktoe"
              );

              if (matchingUnit?.inKtoe !== undefined) {
                return {
                  ...product,
                  value: parseFloat(
                    (product.value * matchingUnit.inKtoe).toFixed(4)
                  ),
                  unit: "ktoe",
                };
              } else {
                console.error(
                  `Matching unit or ktoe value not found for product: ${product.fieldName}`
                );
              }
            } else {
              console.error(
                `Matching product not found for product: ${product.fieldName}`
              );
            }
            return { ...product }; // Return original product if no matching product is found
          }),
        })),
      })),

      // Handle top-level `products`
      products: report.products?.map((product) => {
        const matchingProduct = productAndFormsList.find(
          (p) => p.productName === product.productName
        );

        if (matchingProduct) {
          return {
            ...product,
            forms: product.forms.map((form) => ({
              ...form,
              categories: form.categories.map((category) => {
                const ktoeValue = matchingProduct.units.find(
                  (unit) =>
                    unit.productUnitName === product.unit ||
                    unit.productUnitName === "ktoe"
                )?.inKtoe;

                if (ktoeValue !== undefined) {
                  return {
                    ...category,
                    value: parseFloat((category.value * ktoeValue).toFixed(4)),
                    unit: "ktoe",
                  };
                } else {
                  console.error(
                    `No ktoe conversion available for category: ${category.formCode} in product: ${product.productName}`
                  );
                  return { ...category }; // Return original category if no ktoe conversion
                }
              }),
            })),
          };
        } else {
          console.error(
            `Matching product not found for product: ${product.productName}`
          );
          return { ...product }; // Return original product if no matching product is found
        }
      }),
    };

    return updatedReport;
  }

  return (
    <div>
      <Form.Group className="row form-group-sm mb-2">
        <div className="col-12">
          <span className="mr-2" id="DTA_unitoriginal" style={{fontWeight: showKtoe ? 'normal':'bold'}}>Original unit</span>
          <ClayToggle
            onToggle={handleKtoeToggle}
            toggled={showKtoe}
          />
          <span className="ml-1" id="DTA_unitktoe" style={{fontWeight: showKtoe ? 'bold':'normal'}}>In ktoe</span>
        </div>
      </Form.Group>

      <Form.Group className="row form-group-sm mb-2">
        <Form.Label htmlFor="product" className="col-sm-3 col-lg-2">Product:</Form.Label>
        <Form.Select
          bsPrefix={'form-control'}
          className="col-sm-9 col-lg-10"
          id="product"
          value={selectedProduct}
          onChange={handleProductSelectChange} >
          <option value="" disabled>
            Select Product...
          </option>
          {productList.map((product, index) => (
            <option key={index} value={product}>
              {product}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group className="row form-group-sm mb-2">
        <Form.Label htmlFor="form" className="col-sm-3 col-lg-2">Form:</Form.Label>
        <Form.Select
          bsPrefix={'form-control'}
          className="col-sm-9 col-lg-10"
          id="form"
          value={selectedForm}
          onChange={handleSelectedFormChange}
        >
          <option value="" disabled>
            Select Form...
          </option>
          {formList.map((form, index) => {
            const match = form.match(/^([^:]+?)-[^:]+:(.+)$/);
            const cleanedForm = match ? `${match[1]}: ${match[2]}` : form;

            return (
              <option key={index} value={form}>
                {cleanedForm}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
      <br />

      <HighchartsReact highcharts={Highcharts} options={options} />
      <div style={{ textAlign: "center" }}>
        {series && series.length === 0 ? (
          <i style={{ color: "#9a9a9a" }}>
            Data is not available yet. <br /> Kindly select other quarter,
            product or form combinations.
          </i>
        ) : null}
      </div>
    </div>
  );
};

export default DataTrendAnalysisDP;
