import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

// Export data to PDF
const exportToPdf = async (tableId, reportInfo) => {
  const input = document.getElementById(tableId);

  // Measure the width of the table
  const tableWidth = input.offsetWidth; // Get the rendered table width in pixels
  const pdfWidth = tableWidth * 0.264583; // Convert to mm

  const canvas = await html2canvas(input, { scale: 1, useCORS: true });
  const imgData = canvas.toDataURL('image/png', 0.5);

  let pdfHeight;
  if (reportInfo.type === "Installed Capacity" || reportInfo.type === "Net Calorific") {
    pdfHeight = canvas.height * 0.264583;
  } else {
    pdfHeight = 148; // Fixed height
  }

  const pdf = new jsPDF('l', 'mm', [pdfWidth, pdfHeight]);

  // Add report info at the top
  pdf.setFontSize(12);
  pdf.setFont("helvetica", "bold");
  pdf.text(`Report Period Year: ${reportInfo.year}`, 10, 20);
  pdf.text(`Report Quarter: ${reportInfo.quarter}`, 10, 30);
  pdf.text(`Agency: ${reportInfo.agency}`, 10, 40);
  pdf.text(`Data Provider: ${reportInfo.dataProvider}`, 10, 50);
  pdf.text(`Report Status: ${reportInfo.status}`, 10, 60);
  pdf.text(`Report Type: ${reportInfo.type}`, 10, 70);
  pdf.line(10, 75, pdfWidth - 10, 75);

  const imgWidth = canvas.width * 0.264583;
  const imgHeight = canvas.height * 0.264583;
  const scaleFactor = 0.7;
  const scaledWidth = imgWidth * scaleFactor;
  const scaledHeight = imgHeight * scaleFactor;

  pdf.addImage(imgData, 'PNG', 0, 80, scaledWidth, scaledHeight);
  pdf.save(`${reportInfo.type}-${reportInfo.agency} (${reportInfo.year}-${reportInfo.quarter}).pdf`);
};

// Export data to Excel
const exportToExcel = (tableId, reportInfo) => {
  const table = document.getElementById(tableId);

  // Loop through all rows in the table
  const rows = table.querySelectorAll('tr');
  rows.forEach(row => {
    const cells = row.querySelectorAll('td, th');

    // Loop through each cell
    cells.forEach(cell => {
      // If the cell contains an <input> or <select>, replace its value
      const input = cell.querySelector('input');
      const select = cell.querySelector('select');

      if (input) {
        cell.textContent = input.value; // Replace with the value of the input
      } else if (select) {
        cell.textContent = select.options[select.selectedIndex]?.text || ''; // Replace with the selected option text
      }
    });
  });

  // Now create the worksheet with the updated content
  const worksheet = XLSX.utils.table_to_sheet(table); // Convert the table with updated content to a worksheet
  const workbook = XLSX.utils.book_new(); // Create a new workbook

  XLSX.utils.book_append_sheet(workbook, worksheet, "Report Data"); // Append worksheet to workbook

  // Generate Excel file name
  const fileName = `${reportInfo.type}-${reportInfo.agency} (${reportInfo.year}-${reportInfo.quarter}).xlsx`;

  // Write the file
  XLSX.writeFile(workbook, fileName);
};

export { exportToPdf, exportToExcel }; // Named export (or use default export if preferred)