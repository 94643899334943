import React, { useState, useEffect } from 'react';
import { ClaySelect, ClayInput } from '@clayui/form';
import ClayButton from '@clayui/button';
import InstalledCapacityTableCheckingDP from './InstalledCapacityTableCheckingDP';
import { getProductAndForms } from "../../../../api/nebProductService";
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { exportToPdf, exportToExcel } from './exportToPdf';
import { NumericFormat } from 'react-number-format';
import "../../../../App.css";
import { Row, Col, Card, Form, InputGroup, Modal, Button, Table, Badge, Accordion } from 'react-bootstrap';

const InstalledCapacityTableDP = ({ viewMode, fetchedReport, updateCheckedFormsCount, selectedQuarter, selectedYear, setCombinedStructure, reportMap, setReportMap, reportStatus,isDisabled }) => {

    const [headers, setHeaders] = useState([]);
    const [rows, setRows] = useState([]);
    const [productAndFormsList, setProductList] = useState([]);
    let otherSetProductName = "";
    const handleShowCheckingModal = () => {
        setShowModal(true)
    };
    const handleCloseCheckingModal = () => {
        console.log("closing checking modal");
        setShowModal(false);
    };
    const [checkingReport, setCheckingReport] = useState();
    const [checkingReportKtoe, setCheckingReportKtoe] = useState();
    const [showKtoe, setShowKtoe] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [checked, setChecked] = useState(false);
    const [inputsPopulated, setInputsPopulated] = useState(false);
    const [totals, setTotals] = useState({
        "IC-TIC": 0,
        "IC-TAC": 0,
        "IC-PEA": 0
    }
    );
    const [otherSetName, setOtherSetName] = useState("");
    const [otherSetKeyedIn, setOtherSetKeyedIn] = useState(false);
    const [reportBasicInfo, setReportBasicInfo] = useState();


    //API call runs only once, after the initial render
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getProductAndForms('0', 'IC');
                setProductList(result);
                console.log('ic: ' + JSON.stringify(result, null, 2))
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const inputStyle = {
        textAlign: "right",
        width: "100%",
        height: "30px",
        marginRight: "5px",
        borderRadius: "5px"
    };


    const [fuelValues, setFuelValues] = useState([]);

    const handleFuelValueChange = (
        e,
        index,
        productName,
        parentFormFieldCode,
        formFieldName,
        formCode,
        subtractive,
        inKtoe
    ) => {
        // Extract floatValue from e, with a fallback to 0
        const { floatValue = 0 } = e || {};

        // Ensure the value is a valid number or fallback to 0
        let value = !isNaN(floatValue) ? parseFloat(floatValue) : 0;

        console.log("inktoe: " + inKtoe);

        // Make value negative based on form type (subtractive)
        if (subtractive) {
            value = -Math.abs(value);
        }

        // Update state
        setFuelValues((prevFuelValues) => {
            // Check if an object with the matching index exists
            const exists = prevFuelValues.some((item) => item.index === index);

            if (exists) {
                // Update the existing object
                return prevFuelValues.map((item) => {
                    if (item.index === index) {
                        // Find the form based on parentFormFieldCode
                        let formIndex = item.forms.findIndex(
                            (form) => form.formCode === parentFormFieldCode
                        );

                        let updatedForms;

                        if (formIndex !== -1) {
                            // Update the existing form
                            updatedForms = item.forms.map((form, idx) => {
                                if (idx === formIndex) {
                                    let updatedCategories = form.categories.map((category) => {
                                        if (category.formCode === formCode) {
                                            // Update the category with the new value
                                            return {
                                                formCode: formCode,
                                                value: value,
                                                description: formFieldName,
                                            };
                                        }
                                        return category;
                                    });

                                    // If the category doesn't exist, add it
                                    if (!updatedCategories.some((category) => category.formCode === formCode)) {
                                        updatedCategories.push({
                                            formCode: formCode,
                                            value: value,
                                            description: formFieldName,
                                        });
                                    }

                                    return { ...form, categories: updatedCategories };
                                }
                                return form;
                            });
                        } else {
                            // Add a new form if it doesn't exist
                            updatedForms = [
                                ...item.forms,
                                {
                                    formCode: parentFormFieldCode,
                                    categories: [
                                        {
                                            formCode: formCode,
                                            value: value,
                                            description: formFieldName,
                                        },
                                    ],
                                },
                            ];
                        }

                        // Recalculate the balance based on the updated forms
                        const balance = updatedForms.reduce(
                            (sum, form) =>
                                sum + form.categories.reduce((acc, category) => acc + (category.value || 0), 0),
                            0
                        );

                        return {
                            ...item,
                            forms: updatedForms,
                            balance: isNaN(balance) ? 0 : balance,
                        };
                    }
                    return item;
                });
            } else {
                // Add a new object if it doesn't exist
                const newItem = {
                    index: index,
                    productName: productName,
                    balance: value,
                    forms: [
                        {
                            formCode: parentFormFieldCode,
                            categories: [
                                {
                                    formCode: formCode,
                                    value: value,
                                    description: formFieldName,
                                },
                            ],
                        },
                    ],
                    inKtoe: inKtoe,
                };

                // Recalculate the balance for the new item
                const balance = newItem.forms.reduce(
                    (sum, form) =>
                        sum + form.categories.reduce((acc, category) => acc + (category.value || 0), 0),
                    0
                );

                return [...prevFuelValues, { ...newItem, balance: isNaN(balance) ? 0 : balance }];
            }
        });
    };


    //calculate totals as input elements' value changes
    useEffect(() => {
        console.log("icfuel: ", JSON.stringify(fuelValues, null, 2));

        let _totalInstalledCapacitySum = 0;
        let _totalAvailableCapacitySum = 0;
        let _peakDemandSum = 0;

        fuelValues.forEach((product) => {
            product.forms.forEach((form) => {
                form.categories.forEach((category) => {
                    if (category.formCode === "IC-TIC") {
                        _totalInstalledCapacitySum += category.value;
                    } else if (category.formCode === "IC-TAC") {
                        _totalAvailableCapacitySum += category.value;
                    } else if (category.formCode === "IC-PEA") {
                        _peakDemandSum += category.value;
                    }
                });
            });
        });

        setTotals({
            "IC-TIC": _totalInstalledCapacitySum,
            "IC-TAC": _totalAvailableCapacitySum,
            "IC-PEA": _peakDemandSum
        }
        )
    }, [fuelValues]);

    const [productAndUnit, setProductAndUnit] = useState([]);

    const handleUnitChange = (e, index, productName) => {
        const { value: unit } = e.target;

        setProductAndUnit(prevProductAndUnit => {
            // Check if an object with matching productName exists
            const exists = prevProductAndUnit.some(item => item.fieldName === productName);

            if (exists) {
                // Update the existing object
                return prevProductAndUnit.map(item => {
                    if (item.fieldName === productName) {
                        return {
                            ...item,
                            unit: unit
                        };
                    } else {
                        return item;
                    }
                });
            } else {
                // Add a new object
                const newItem = {
                    fieldName: productName,
                    unit: unit
                };

                return [
                    ...prevProductAndUnit,
                    newItem
                ];
            }
        });
    };

    const handleOtherSetNameChange = (e) => {
        let value = e.target.value;
        setOtherSetName(value);
    }

    useEffect(() => {
        console.log("othersetname: " + otherSetName);
        // Loop through the forms array
        if (reportMap.forms && reportMap.forms.length > 0) {
            const updatedReportMap = {
                ...reportMap,
                forms: reportMap.forms.map((form) => ({
                    ...form,
                    categories: form.categories.map((category) => ({
                        ...category,
                        products: category.products.map((product) =>
                            product.fieldName.includes("Other Sets")
                                ? { ...product, fieldName: `${otherSetName},Other Sets` }
                                : product
                        )
                    }))
                }))
            };

            // Update the state with the new reportMap
            setReportMap(updatedReportMap);
        }
    }, [otherSetName])

    useEffect(() => {
        console.log("debug Updated productAndUnit: ", JSON.stringify(productAndUnit, null, 2));
    }, [productAndUnit]);

    useEffect(() => {
        if (productAndFormsList.length === 0) return;

        // Group products by their category (split by comma)
        const groupedProducts = productAndFormsList.reduce((acc, product) => {
            const [productType, category] = product.productName.split(',');
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(product);
            return acc;
        }, {});

        const dynamicRows = [];
        let categoryIndex = 1;
        let productIndex = 1;

        // Iterate over the grouped products by category
        for (const category in groupedProducts) {
            // Add a row for the category with colSpan extended to cover all columns
            dynamicRows.push({
                //no: categoryIndex,
                category:
                    (
                        <span>
                            <strong>{categoryIndex}. {category}</strong>
                        </span>
                    )
            });

            // Add the rows for each product under the current category
            groupedProducts[category].forEach((product, index) => {
                const fuelObject = fuelValues.find(fuel => fuel.index === index + 1);
                dynamicRows.push({
                    no: index + 1,
                    product: product.productName.split(',')[0], // Display only the product name
                    ...product.forms.reduce((acc, form) => {
                        //need only one peak demand input, not for every product/row

                        acc[form.formFieldCode] = (
                            <NumericFormat
                                disabled={isDisabled}
                                key={`${index + 1}_${form.formFieldCode}`}
                                style={inputStyle} // Use the custom input style
                                valueIsNumericString={true} // Ensure the value is passed as a numeric string
                                id={`num_${product.productName}_${form.formFieldCode}`} // Unique ID
                                customInput={ClayInput} // Use ClayInput as the custom input component
                                thousandSeparator // Add thousand separator if required
                                onValueChange={(values) => {
                                    const { floatValue } = values; // Extract the numeric value
                                    handleFuelValueChange(
                                        values,
                                        index + 1, // Index for product
                                        product.productName, // Product name
                                        form.parentFormFieldCode, // Parent form field code
                                        form.formFieldName, // Form field name
                                        form.formFieldCode, // Form field code
                                        form.subtractive // Subtractive value (if any)
                                    );
                                }}
                            />
                        );


                        return acc;
                    }, {}),
                    balance: (
                        <span style={{ paddingRight: "10px", display: "block", textAlign: "right" }}>
                            {fuelObject ? fuelObject.balance : 0}
                        </span>
                    ) // Handle undefined case
                });
            });

            categoryIndex++; // Increment category number
        }

        //Add "Other Sets" as the last category
        dynamicRows.push({
            no: categoryIndex,
            category: (
                <span>
                    <strong>{categoryIndex}. Other Sets</strong>
                </span>
            )
        });

        // Add one child product under "Other Sets" with a text ClayInput for the product name
        const otherForms = productAndFormsList[0].forms; // Assuming "Other Sets" has the same form structure as other products

        dynamicRows.push({
            no: categoryIndex + 1,
            product: (
                <ClayInput
                    id={`name_other_sets_ic}`}
                    type="text"
                    //disabled={!otherSetKeyedIn}
                    //onChange={(e) => handleOtherSetNameChange(e)}
                    disabled={true}
                    value="Other Set"
                />
            ),
            unit: '',
            ...otherForms.reduce((acc, form) => {
                acc[form.formFieldCode] = (
                    <NumericFormat
                        className='no-spinner'
                        disabled={isDisabled}
                        key={`other_${form.formFieldCode}`}
                        id={`num_other_ic_${form.formFieldCode}`}
                        placeholder=''
                        style={inputStyle}
                        customInput={ClayInput}
                        name={form.formFieldCode}
                        onValueChange={(values) => {
                            handleFuelValueChange(values, categoryIndex, "Other Sets", form.parentFormFieldCode, form.formFieldName, form.subtractive);
                        }}
                        thousandSeparator
                    />
                );
                return acc;
            }, {}),
            balance: ''
        });

        console.log("ofs: " + JSON.stringify(fuelValues, null, 2))


        //add "TOTAL" row
        dynamicRows.push({
            no: categoryIndex + 1,
            product: (
                <span>
                    <strong>TOTAL</strong>
                </span>
            ),
            unit: '',
            ...otherForms.reduce((acc, form) => {
                console.log(`total_${form.formFieldCode}: ${totals[form.formFieldCode]}`)
                acc[form.formFieldCode] = (
                    <div style={{ textAlign: 'center' }}> {/* Centering the text */}
                        <strong>
                            <NumericFormat displayType="text" value={totals[form.formFieldCode]} thousandSeparator />
                        </strong>
                    </div>
                    // <ClayInput
                    //     key={`total_${form.formFieldCode}`}
                    //     style={inputStyle}
                    //     type="number"
                    //     disabled={true}
                    //     value={totals[form.formFieldCode]}
                    //     name={`total_${form.formFieldCode}`}
                    // />
                );
                //console.log("acc: " + JSON.stringify(acc, null, 2))
                return acc;
            }, {}),
            balance: '' // Empty balance (if needed)
        });

        console.log("dyr: " + JSON.stringify(otherForms, null, 2));


        setRows(dynamicRows);
    }, [totals, productAndFormsList, fuelValues]);

    //After rows changes, headers is formed
    useEffect(() => {
        if (productAndFormsList.length === 0) return;

        const dynamicHeaders = [
            //{ id: 'no', name: 'No.' },
            { id: 'product', name: 'Types of Prime Mover' },
            ...productAndFormsList[0].forms.map(form => ({
                id: form.formFieldCode,
                name: `${form.formFieldName}`
            }))
        ];
        setHeaders(dynamicHeaders);
        console.log("hello: " + JSON.stringify(dynamicHeaders, null, 2))
    }, [rows]);

    //merge

    // const [combinedStructure, setCombinedStructure] = useState(null);

    useEffect(() => {
        const formsMap = {};

        fuelValues.forEach(fuelItem => {
            fuelItem.forms.forEach(form => {
                if (!formsMap[form.formCode]) {
                    formsMap[form.formCode] = {
                        formCode: form.formCode,
                        categories: []
                    };
                }

                form.categories.forEach(category => {
                    const product = {
                        fieldName: fuelItem.productName,
                        value: Math.abs(category.value), // ensure always positive
                        unit: productAndUnit.find(item => item.fieldName === fuelItem.productName)?.unit || 'MW'
                    };

                    const categoryIndex = formsMap[form.formCode].categories.findIndex(cat => cat.formCode === category.formCode);

                    if (categoryIndex !== -1) {
                        formsMap[form.formCode].categories[categoryIndex].products.push(product);
                    } else {
                        formsMap[form.formCode].categories.push({
                            formCode: category.formCode,
                            description: category.description,
                            products: [product],
                            total: totals[category.formCode]
                        });
                    }
                });
            });
        });

        const formsArray = Object.values(formsMap);

        console.log("fb cat, reportMap.forms : ", JSON.stringify(...reportMap.forms, null, 2));
        console.log("fb cat, formsArray : ", JSON.stringify(...formsArray, null, 2));

        //reportMap.forms = [...reportMap.forms, ...formsArray];
        //setCombinedStructure(reportMap);

        setReportMap(prevState => ({
            ...prevState,
            reportPeriodYear: selectedYear,
            reportQuarter: selectedQuarter,
            forms: [
                // Remove existing forms with the same formCode
                ...prevState.forms.filter(form => !formsArray.some(newForm => newForm.formCode === form.formCode)),
                // Add the updated forms
                ...formsArray
            ]
        }));
    }, [totals, fuelValues, productAndUnit, selectedQuarter, selectedYear]);

    useEffect(() => {
        setCombinedStructure(reportMap);
        setCheckingReport(reportMap);
        //Get report basic info
        setReportBasicInfo(
            {
                "year": reportMap.reportPeriodYear,
                "quarter": reportMap.reportQuarter,
                "agency": reportMap.agency.agencyName,
                "dataProvider": reportMap.dataProvider.name,
                "status": reportMap.reportStatus,
                "type": "Installed Capacity"
            }
        )
    }, [reportMap]);

    //after finish forming rows and headers, try fetch report. if got result, fill into the inputs.
    useEffect(() => {
        if (fetchedReport && productAndFormsList.length > 0 && !inputsPopulated && (viewMode === "draft" || viewMode === "pending")) {
            console.log("received ic: " + JSON.stringify(fetchedReport, null, 2))
            // Iterate through the forms and categories in fetchedData
            fetchedReport.forms.forEach((form) => {
                form.categories.forEach((category) => {
                    category.products.forEach((product) => {

                        // NUMERICAL INPUTS
                        // Construct the id to match the <input> elements
                        const numInputId = `num_ic_draft_${product.fieldName}_${category.formCode}`;
                        const numInputElement = document.getElementById(numInputId);

                        if (numInputElement) {
                            const value = product.value;
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
                            nativeInputValueSetter.call(numInputElement, value);

                            // Dispatch the 'input' event to trigger onChange in React
                            const event = new Event('input', { bubbles: true });
                            numInputElement.dispatchEvent(event);
                        }

                        // PEAK DEMAND NUMERICAL INPUT
                        // Construct the id to match the <input> elements
                        const pdInputId = `num_ic_draft_all_IC-PEA`;
                        const pdInputElement = document.getElementById(pdInputId);

                        if (pdInputElement && category.formCode === "IC-PEA") {
                            const value = product.value;
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
                            nativeInputValueSetter.call(pdInputElement, value);

                            // Dispatch the 'input' event to trigger onChange in React
                            const event = new Event('input', { bubbles: true });
                            pdInputElement.dispatchEvent(event);
                        }

                        //num_other_ic_${form.formFieldCode}
                        // other set NUMERICAL INPUTs
                        // Construct the id to match the <input> elements
                        const otherSetInputId = `num_other_ic_${category.formCode}`;
                        const otherSetInputElement = document.getElementById(otherSetInputId);

                        if (otherSetInputElement) {
                            const value = product.value;
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
                            nativeInputValueSetter.call(otherSetInputElement, value);

                            // Dispatch the 'input' event to trigger onChange in React
                            const event = new Event('input', { bubbles: true });
                            otherSetInputElement.dispatchEvent(event);
                        }
                    });
                });
            });
            setInputsPopulated(true);
        }
    }, [fetchedReport, headers]);


    return (
        <div>
            <Table id="installed-capacity-table" responsive bordered hover size="sm" className="mt-1" style={{width: '45em'}}>
            <thead>
                <tr>
                {headers.map((column) => (
                    <th
                        key={column.id}
                        className="table-cell-minw-200"
                        style={column.id === 'balance' ? {
                            position: 'sticky',
                            left: 0,
                            backgroundColor: 'white',
                            zIndex: 1,
                            borderRight: '1px solid #dee2e6'
                        } : {}} >
                        {column.name}
                    </th>
                ))}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.category ? (
                            // Render category row with colSpan
                            <td colSpan={headers.length} className='pt-4'>
                                {row.category}
                            </td>
                        ) : (
                            // Render product rows
                            headers.map((column) => (
                                <td
                                    key={column.id}
                                    className="table-cell-minw-200"
                                    style={column.id === 'balance' ? {
                                        position: 'sticky',
                                        left: 0,
                                        backgroundColor: 'white',
                                        zIndex: 1,
                                        borderRight: '1px solid #dee2e6'
                                    } :
                                        {}
                                    }
                                >
                                    {row[column.id]}
                                </td>

                            ))
                        )}
                    </tr>
                ))}
            </tbody>
            </Table>
            <br />

            <Table responsive bordered hover size="sm" style={{width: '45em'}}>
            <tbody>
                <tr>
                    <td key="peakDemandKey" className="table-cell-minw-200">Peak Demand (MW)</td>
                    <td key="peakDemandValue" className="table-cell-minw-200">
                        <NumericFormat
                            className='no-spinner'
                            key={`IC-PEA`}
                            id={`num_ic_draft_all_IC-PEA`}
                            style={inputStyle}
                            customInput={ClayInput}
                            name={`IC-PEA`}
                            thousandSeparator
                            onChange={(e) => handleFuelValueChange(e, "-1", "all", "IC", "Peak Demand", "IC-PEA", false)}
                        />
                    </td>
                </tr>
            </tbody>
            </Table>
            
            {/* Export to PDF */}
            <FontAwesomeIcon
                title="Download as PDF"
                style={{ cursor: 'pointer' }}
                onClick={() => exportToPdf("installed-capacity-table", reportBasicInfo)}
                className='mr-2 text-primary'
                size='xl'
                icon={faFilePdf}
            />

            {/* Export to Excel */}
            <FontAwesomeIcon
                title="Download as Excel"
                style={{ cursor: 'pointer' }}
                onClick={() => exportToExcel("installed-capacity-table", reportBasicInfo)}
                className='text-primary'
                size='xl'
                icon={faFileExcel}
            />
            <br /><br />

            {/* <ClayButton onClick={handleShowCheckingModal}>Check</ClayButton > */}
                
            <Modal size='xl' show={showModal} onHide={handleCloseCheckingModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body><span>Check all numbers in your Installed Capacity. </span>
                    <div className="text-center mt-0 mb-1 d-flex justify-content-start">
                        <table>
                            <tr>
                                {/* <td>
                    <ClayToggle
                      label="Checked"
                      onToggle={(newVal) => newVal === true ? (updateCheckedFormsCount(+1), setChecked(newVal)) : (updateCheckedFormsCount(-1),setChecked(newVal))} // Explicitly pass the new toggle state
                      toggled={checked}
                    />
                  </td> */}
                            </tr>
                        </table>
                    </div>
                    <div>

                        <InstalledCapacityTableCheckingDP
                            checkingReport={checkingReport} />
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    {/* <button className="btn btn-outline-secondary mr-1" onClick={handleCloseCheckingModal}>
            Close
          </button>
          <button
                onClick={() => submitReport("Draft")}
                className="btn btn-outline-primary mr-1"
              >
                Save As Draft
              </button>
          <button className="btn btn-primary mr-1" onClick={handleShowConfirmationModal}>
            Submit
          </button> */}

                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default InstalledCapacityTableDP;
