import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { ClayInput } from "@clayui/form";
import ClayButton from "@clayui/button";
import {
  Form,
  Card,
  Pagination,
  Row,
  Col,
  Button,
  Modal,
  InputGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import {
  fetchTicketById,
  updateTicket,
  addFeedback,
  fetchFeedback,
  searchAdmin,
  sendEmailStatus,
} from "../../../api/ticketService"; // Adjust service import
import {
  fetchLookupParameters,
  getLookupParametersByCategory,
} from "../../../api/lookupService";
import ClayCard from "@clayui/card";
import { getAgencyByLiferayId } from "../../../api/nebAgencyService";
import { getDataProviderByAgencyId } from "../../../api/nebDataProviderService";
import { listFiles, downloadFile } from "../../../api/fileSystemService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImage, faDownload } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ClayAlert from "@clayui/alert";
import useAuthStore from "../../../store/useAuthStore";
import { toast, Bounce } from "react-toastify";
import {
  saveTicketAssignee,
  getTicketAssigneeListByTicketId,
} from "../../../api/nebTicketAssigneeService";
import { fetchCurrentUser } from "../../../api/userService";

const TicketFormView = () => {
  const navigate = useNavigate();
  const { ticketId } = useParams();
  const [ticketDetails, setTicketDetails] = useState(null);
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("NEW");
  const [refNo, setRefNo] = useState("");
  const [category, setCategory] = useState("");
  const [feedbackContent, setFeedbackContent] = useState("");
  const [feedbacks, setFeedbacks] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [categories, setCategories] = useState([]); // State for category dropdown
  const [categoryList, setCategoryList] = useState([]);
  const [categoryDescriptions, setCategoryDescriptions] = useState({});
  const [fileToDownload, setFileToDownload] = useState("");
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [isAccept, setAccept] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [assigneeByTicketId, setAssigneeByTicketId] = useState(null);
  const [adminListing, setAdminListing] = useState([]);
  const [assignee, setAssignee] = useState({
    ticketId: "",
    name: "",
    email: "",
  });

  // List of assignees (this would come from your API or state) temporary
  const assignees = [
    { id: 1, initials: "AM", name: "Ahmad Mukhsin" },
    { id: 2, initials: "AS", name: "Asyraf Solehin" },
    { id: 3, initials: "NS", name: "Nur Sharifah" },
    { id: 4, initials: "NF", name: "Nurul Fatihah" },
  ];

  const { roles } = useAuthStore((state) => ({ roles: state.roles })); // Get roles from Zustand store

  const toastContainerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 1050, // Ensure it's above other elements
    display: "flex",
    justifyContent: "center", // Center the alert content
  };

  const toastContentStyle = {
    width: "100%",
    maxWidth: "1200px", // Optional: limit the maximum width
  };

  // get current user and admin list
  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        const user = await fetchCurrentUser();
        console.log("Current User", user);

        // Set the user's name and email
        setCurrentUser(user.name);

        // Safely access the first email address if available
        if (
          user.userAccountContactInformation &&
          user.userAccountContactInformation.emailAddresses.length > 0
        ) {
          setCurrentUserEmail(
            user.userAccountContactInformation.emailAddresses[0]
          );
        } else {
          setCurrentUserEmail("No email address available");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getCurrentUser();
    fetchAdmin();
  }, []);

  useEffect(() => {
    const fetchTicketDetails = async () => {
      if (isEdit) {
        try {
          const ticket = await fetchTicketById(ticketId);
          setTicketDetails({
            ...ticket,
            previousStatus: ticket.status, 
          });
          setStatus(ticket.status); 
        } catch (error) {
          console.error("Error fetching ticket details:", error);
        }
      }
    };
  
    fetchTicketDetails();
  }, [isEdit, ticketId]);

  // Load ticket details if editing
  useEffect(() => {
    if (ticketId) {
      fetchAssigneeDetails(ticketId);
      setIsEdit(true);
      const loadTicket = async () => {
        try {
          const ticket = await fetchTicketById(ticketId);
          setTicketDetails(ticket);
          const agency = await getAgencyByLiferayId(ticket.createdBy);
          const dp = await getDataProviderByAgencyId(agency.id);
          const options = { day: "numeric", month: "short", year: "numeric" };
          const formattedDate = new Date(ticket.createdDate).toLocaleDateString(
            "en-GB",
            options
          ); // Adjust locale as needed

          setTitle(ticket.title);
          setDescription(ticket.description);
          setStatus(ticket.status);
          setRefNo(ticket.refNo);
          setCategory(ticket.category);
          setDateCreated(formattedDate);
          setName(dp.name);
          setEmail(dp.email);
        } catch (error) {
          console.error("Error fetching ticket:", error);
        }
      };
      loadTicket();
    }
  }, [ticketId, isAccept]);

  // Fetch category descriptions
  useEffect(() => {
    const loadCategoryDescriptions = async () => {
      try {
        const response = await getLookupParametersByCategory("TICKET-CATEGORY");
        const categoryMap = response.reduce((acc, category) => {
          acc[category.code] = category.description;
          return acc;
        }, {});
        setCategoryDescriptions(categoryMap);
      } catch (error) {
        console.error("Error fetching status descriptions:", error);
      }
    };
    loadCategoryDescriptions();
  }, []);

  // Load feedbacks
  const loadFeedbacks = useCallback(async () => {
    try {
      const response = await fetchFeedback(ticketId, page, 5);
      console.log("fetching feedbacks:", response);
      setFeedbacks(response.content);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error("Error fetching feedbacks:", error);
    }
  }, [ticketId, page]);

  useEffect(() => {
    loadFeedbacks();
  }, [loadFeedbacks]);

  // Fetch categories for dropdown
  useEffect(() => {
    const loadCategories = async () => {
      try {
        const response = await fetchLookupParameters("FEEDBACK-STATUS"); // Example category
        setCategories(response); // Assuming API returns a list of {code, description}
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const loadCategoryList = async () => {
      try {
        const response = await fetchLookupParameters("TICKET-CATEGORY");
        setCategoryList(response);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const handleListFiles = async () => {
      const folderPath = `upload/${ticketId}`.replace(/\\/g, "/");
      try {
        const filesList = await listFiles(folderPath);

        // Extract file names from file paths
        const fileNames = filesList.map((filePath) => {
          const parts = filePath.split(/[/\\]/); // handle both '/' and '\'
          return parts[parts.length - 1]; // get the file name
        });

        setFiles(filesList); // Set the full file paths
        setFileName(fileNames); // Set only file names
        console.log("File names:", fileNames);
      } catch (error) {
        console.error("Error listing files:", error);
      }
    };
    loadCategories();
    loadCategoryList();
    handleListFiles();
  }, []);

  // fetch admin listing
  const fetchAdmin = async () => {
    try {
      const adminList = await searchAdmin("roleBriefs.name = 'NEB_ADMIN'");
      console.log("list of admin: ", adminList);
      setAdminListing(adminList);
    } catch (error) {
      console.log(error);
    }
  };

  // Handle form submission for updating a ticket
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (status === ticketDetails.previousStatus) {
      setAlertType("warning");
      setAlertMessage("The status is the same with the previous status.");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 2000);
      return; 
    }

    const ticketData = { refNo, title, description, status, category, previousStatus: ticketDetails.status };
    try {
      let ticket = null;
      if (isEdit) {
        await updateTicket(ticketId, ticketData);
        ticket = await fetchTicketById(ticketId);
        console.log("tickettt :", ticket);
      }

      setAlertType("success");
      setAlertMessage("Your ticket status has been updated successfully.");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
        navigate("/ticket/issues-listing");
      }, 2000);

      console.log("Sending email with ticket:", ticket);
      await sendEmailStatus(ticket);
    } catch (error) {
      console.error("Error submitting ticket:", error);
    }
  };

  // Handle feedback submission
  const handleFeedbackSubmit = async () => {
    try {
      await addFeedback(ticketId, { comment: feedbackContent });
      setFeedbackContent(""); // Reset input
      loadFeedbacks(); // Reload feedbacks after submission
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleDownload = async (filePath) => {
    try {
      console.log("File path: ", filePath);
      await downloadFile(filePath);
      console.log("File downloaded successfully");
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const onCancelClick = () => {
    const confirmation = window.confirm(
      "Are you sure you want to cancel this process?"
    );
    if (confirmation) {
      toast.success(`The process have been cancel`);
      setTimeout(() => {
        navigate(`/ticket/issues-listing`);
      }, 2000);
    }
  };

  const onAcceptClick = () => setShowConfirmModal(true);

  const handleCancelConfirm = () => setShowConfirmModal(false);

  const handleCancelAssignee = () => setShowAssignModal(false);

  // const onAssignToOther = () => setShowAssignModal(true);

  const handleConfirm = async () => {
    try {
      let assigneeToSave = assignee;

      assigneeToSave = {
        ...assigneeToSave,
        ticketId: parseInt(ticketId),
        name: currentUser,
        email: currentUserEmail,
      };

      const response = await saveTicketAssignee(assigneeToSave);
      console.log("Assignee: ", response);
      setAccept(true);
    } catch (error) {
      console.error("Error saving assignee:", error);

      toast.error(`Failed to assignee.`);
    }
    setShowConfirmModal(false);
  };

  const fetchAssigneeDetails = async (ticketId) => {
    try {
      console.log("ticketId fetch:", ticketId);
      const resultAssignee = await getTicketAssigneeListByTicketId(ticketId);
      console.log("resultAssignee :", resultAssignee);

      if (resultAssignee.length > 0) {
        setAccept(true);

        // Dapatkan assignee yang terbaru (entri terakhir)
        const latestAssignee = resultAssignee[resultAssignee.length - 1];

        const options = { day: "numeric", month: "short", year: "numeric" };
        const formattedDate = latestAssignee.createdDate
          ? new Date(latestAssignee.createdDate).toLocaleDateString(
              "en-GB",
              options
            )
          : "";

        console.log("name", latestAssignee.name);
        console.log("email", latestAssignee.email);
        console.log("createdDate", formattedDate);

        setAssignee({
          name: latestAssignee.name || "",
          email: latestAssignee.email || "",
          createdDate: formattedDate || "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
  };

  return (
    <>
      <div className="ticket-form container">
        <h2 className="mb-4 text-primary">
          {isEdit
            ? `${refNo} ${
                categoryDescriptions[category] || category
              } - ${status}`
            : "Create New Ticket"}
        </h2>
        {alertVisible && (
          <div style={toastContainerStyle}>
            <div style={toastContentStyle}>
              <ClayAlert
                displayType={alertType}
                title={alertType === "success" ? "Success" : "Error"}
              >
                {alertMessage}
              </ClayAlert>
            </div>
          </div>
        )}

        <Card className="mb-4">
          <Row>
            <Col md={isAccept ? 6 : 8}>
              <Card.Body>
                <h3 className="text-muted mb-3">{title.toUpperCase()}</h3>

                <div className="mb-5" style={{ whiteSpace: "pre-wrap" }}>
                  {description}
                </div>

                <ul className="list-unstyled">
                  {files.map((file, index) => (
                    <Card
                      key={index}
                      className="mb-3 p-2 d-flex flex-row align-items-center"
                      style={{ width: "300px" }}
                    >
                      <FontAwesomeIcon
                        icon={faFileImage}
                        size="2x"
                        className="me-3 mr-2"
                      />
                      <div className="flex-grow-1">
                        <p className="mb-0">{fileName}</p>
                      </div>
                      <FontAwesomeIcon
                        icon={faDownload}
                        size="lg"
                        className="cursor-pointer"
                        onClick={() => handleDownload(file)}
                      />
                    </Card>
                  ))}
                </ul>
              </Card.Body>
            </Col>

            <Col md={isAccept ? 3 : 4}>
              <Card.Body>
                <Card
                  className="p-3 mb-4"
                  style={{ backgroundColor: "#F0F7FF", borderRadius: "10px" }}
                >
                  <Card.Body>
                    <Card.Title
                      className="text-primary"
                      style={{ fontWeight: "bold" }}
                    >
                      Requester's Details
                    </Card.Title>
                    <Card.Text className="mb-2" style={{ fontSize: "14px" }}>
                      <strong>REQUESTER</strong>
                      <br />
                      {name}
                    </Card.Text>
                    <Card.Text className="mb-2" style={{ fontSize: "14px" }}>
                      <strong>EMAIL</strong>
                      <br />
                      {email}
                    </Card.Text>
                    <Card.Text style={{ fontSize: "14px" }}>
                      <strong>DATE CREATED</strong>
                      <br />
                      {dateCreated}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Col>

            {isAccept && (
              <Col md={3}>
                {/* Third column content */}
                <Card.Body>
                  <Card
                    className="p-3 mb-4"
                    style={{ backgroundColor: "#F0F7FF", borderRadius: "10px" }}
                  >
                    <Card.Body>
                      <Card.Title
                        className="text-primary"
                        style={{ fontWeight: "bold" }}
                      >
                        Assignee Details
                      </Card.Title>
                      <Card.Text className="mb-2" style={{ fontSize: "14px" }}>
                        <strong>ASSIGNEE</strong>
                        <br />
                        {assignee.name}
                      </Card.Text>
                      <Card.Text className="mb-2" style={{ fontSize: "14px" }}>
                        <strong>EMAIL</strong>
                        <br />
                        {assignee.email}
                      </Card.Text>
                      <Card.Text style={{ fontSize: "14px" }}>
                        <strong>DATE ACCEPTED</strong>
                        <br />
                        {assignee.createdDate}
                      </Card.Text>
                      {/* this code is for Select New Assignee - temporary comment until API ready */}
                      {/* <div className="d-flex justify-content-end mt-3">
                        <Button
                          style={{ fontSize: "14px" }}
                          variant="link"
                          onClick={onAssignToOther}
                          className="btn"
                        >
                          Assign to Others
                        </Button>
                      </div> */}
                    </Card.Body>
                  </Card>
                </Card.Body>
              </Col>
            )}
          </Row>
        </Card>

        {roles.includes("NEB_ADMIN") && (
          <Form onSubmit={handleSubmit}>
            {/* Category Dropdown */}
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => setStatus(e.target.value)}
                value={status}
              >
                <option value="">Select Status</option>
                {categories.map((category) => (
                  <option key={category.code} value={category.code}>
                    {category.description}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <div className="d-flex justify-content-end mt-3">
              <ClayButton type="submit">
                {isEdit ? "Update Status" : "Create Ticket"}
              </ClayButton>
            </div>
          </Form>
        )}

        {/* Feedback Section */}
        {isEdit && (
          <div className="feedback-section mt-4">
            <h3>Comment</h3>
            <Card className="mb-3">
              <Card.Body>
                <Form.Group>
                  <Form.Control
                    id="feedbackContent"
                    as="textarea"
                    rows={3}
                    value={feedbackContent}
                    onChange={(e) => setFeedbackContent(e.target.value)}
                    placeholder="Type a comment.."
                  />
                </Form.Group>
                <div className="d-flex justify-content-end mt-3">
                  <ClayButton onClick={handleFeedbackSubmit}>Send</ClayButton>
                </div>
              </Card.Body>
            </Card>

            {/* Feedback List */}
            {feedbacks.map((feedback, index) => (
              <Card key={index} className="mb-2">
                <Card.Body>
                  <ClayCard.Description displayType="title">
                    <strong>{feedback.author}</strong> -{" "}
                    {new Date(feedback.createdDate).toLocaleString()}
                  </ClayCard.Description>
                  <ClayCard.Description truncate={false} displayType="text">
                    <p>{feedback.comment}</p>
                  </ClayCard.Description>
                </Card.Body>
              </Card>
            ))}

            {/* Pagination */}
            <Pagination>
              <Pagination.Prev
                onClick={() => setPage(page > 0 ? page - 1 : 0)}
                disabled={page === 0}
              />
              {[...Array(totalPages)].map((_, idx) => (
                <Pagination.Item
                  key={idx}
                  active={idx === page}
                  onClick={() => setPage(idx)}
                >
                  {idx + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => setPage(page < totalPages - 1 ? page + 1 : page)}
                disabled={page === totalPages - 1}
              />
            </Pagination>
          </div>
        )}

        {/* Button Cancel and Accept */}
        {roles.includes("NEB_ADMIN") && !isAccept && (
          <div className="d-flex justify-content-end mt-3">
            <Button
              variant="link"
              onClick={onCancelClick}
              className="btn btn-outline-primary"
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={onAcceptClick} className="ml-2">
              Accept
            </Button>
          </div>
        )}
      </div>

      {/* Modal Confirm */}
      <Modal show={showConfirmModal}>
        <Modal.Header closeButton>
          <Modal.Title>Accept Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are about to accept the ticket #
          {String(ticketId).padStart(5, "0")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCancelConfirm}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Assignee */}
      <Modal show={showAssignModal} onHide={handleCancelAssignee}>
        <Modal.Header closeButton>
          <Modal.Title>Change Assignee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Current Assignee</Form.Label>
              <InputGroup className="mb-3">
                {/* <InputGroup.Text>
                <img src="current-user-image.jpg" alt="Current Assignee" style={{ width: '30px', borderRadius: '50%' }} />
              </InputGroup.Text> */}
                <Form.Control type="text" value={assignee.name} disabled />
              </InputGroup>
            </Form.Group>
            {/* this code is for Select New Assignee - temporary comment until API ready */}
            {/* <Form.Group>
              <Form.Label>Select New Assignee</Form.Label>
              <Form.Control
                as="select"
                name="sectorId"
                aria-label="Select Energy Sector"
                value={assignee.name}
                onChange={handleChange}
              >
                <option value="">Please Select.. </option>
                {adminListing.map((admin) => (
                  <option key={admin.code} value={admin.code}>
                    {admin.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCancelAssignee}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TicketFormView;
