import React from 'react';
import GridLayout from "react-grid-layout";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import useAuthStore from '../store/useAuthStore'; // Import your Zustand store
import Ticketing from './widgets/Ticketing'; // Adjust the import path based on your directory structure
import FormAndProductList from '../views/neb/user-profile/FormAndProductList';
import FormsStatus from '../views/neb/admin-dashboard-content/FormsStatus';

const Dashboard = () => {
  const { roles } = useAuthStore(state => ({ roles: state.roles })); // Get roles from Zustand store

  // Define layouts based on user roles
  const layouts = {
    "ADMINISTRATOR": [
      { i: "a", x: 0, y: 0, w: 2, h: 2 },
      { i: "b", x: 2, y: 0, w: 2, h: 2 },
      { i: "ticketing", x: 0, y: 2, w: 4, h: 2 }, // Add Ticketing layout
    ],
    "USER": [
      { i: "a", x: 0, y: 0, w: 2, h: 2 },
      { i: "b", x: 2, y: 0, w: 2, h: 2 },
    ],
    "NEB_DATA_PROVIDER": [
      { i: "a", x: 0, y: 0, w: 2, h: 2 },
      { i: "b", x: 2, y: 0, w: 2, h: 2 },
      { i: "c", x: 4, y: 0, w: 2, h: 2 },
    ],
    "NEB_ADMIN": [
      { i: "a", x: 0, y: 0, w: 2, h: 2 },
      { i: "b", x: 2, y: 0, w: 2, h: 2 },
      { i: "c", x: 4, y: 0, w: 2, h: 2 },
      { i: "ticketing", x: 0, y: 2, w: 4, h: 2 }, // Add Ticketing layout
    ]
  };

  // Determine the layout based on the first role (or however you want to handle multiple roles)
  const currentLayout = layouts[roles[0]] || layouts.USER; // Default to user layout

  return (
    <div className="layout dashboard-layout mt-4">
      {/* <div key="a" data-grid={{ i: 'a', x: 0, y: 0, w: 2, h: 2 }}>Widget A</div>
      <div key="b" data-grid={{ i: 'b', x: 2, y: 0, w: 2, h: 2 }}>Widget B</div> */}
      {(roles.includes("ADMINISTRATOR")) && (
        <div key="ticketing" data-grid={{ i: 'ticketing', x: 0, y: 2, w: 12, h: 3 }}>
          <Ticketing />
        </div>
      )}

      {(roles.includes("NEB_DATA_PROVIDER")) && (
        <div key="formProductList" data-grid={{ i: 'formProductList', x: 0, y: 5, w: 12, h: 2 }}>
          <FormAndProductList />
        </div>
      )}

      {(roles.includes("NEB_ADMIN")) && (
        <div key="nebAdminDashboardContent" data-grid={{ i: 'nebAdminDashboardContent', x: 0, y: 2, w: 12, h: 2 }}>
          <FormsStatus />
        </div>
      )}
    </div>

  );
};

export default Dashboard;