import React, { useState, useCallback, useEffect } from "react";
import { ClayToggle } from "@clayui/form";
import { ClaySelect, ClayInput } from "@clayui/form";
import ClayButton from "@clayui/button";
import C1TableCheckingDP from "./C1TableCheckingDP";
import C1TableCheckingKtoeDP from "./C1TableCheckingKtoeDP";
import { getProductAndForms } from "../../../../api/nebProductService";
import { NumericFormat } from "react-number-format";
import FuelBalanceChartTabsDP from "../FuelBalanceChartTabsDP";
import "../../../../App.css";
import {
  Row,
  Col,
  Card,
  Form,
  InputGroup,
  Modal,
  Button,
  Table,
  Badge,
  Accordion,
} from "react-bootstrap";

const C1TableDP = ({
  energySector,
  viewMode,
  fetchedReport,
  updateCheckedFormsCount,
  agencyId,
  setFuelValuesC1Checking,
  setProductAndUnitC1Checking,
  selectedQuarter,
  selectedYear,
  reportStatus,
  combinedStructure,
  setCombinedStructure,
  reportMap,
  setReportMap,
  totalSellouts,
  isDisabled,
}) => {
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [productAndFormsList, setProductAndFormsList] = useState([]);
  const [checkingReport, setCheckingReport] = useState();
  const [checkingReportKtoe, setCheckingReportKtoe] = useState();

  const [showKtoe, setShowKtoe] = useState(false);
  const handleKtoeToggle = (newVal) => {
    //(newVal) => setShowKtoe(newVal);
    setShowKtoe(newVal);

    document.getElementById('C1_unitoriginal').style.fontWeight = (newVal ? 'normal':'bold');
    document.getElementById('C1_unitktoe').style.fontWeight = (newVal ? 'bold':'normal');
  };

  const [showModal, setShowModal] = useState(false);
  const handleShowCheckingModal = () => setShowModal(true);
  const handleCloseCheckingModal = () => setShowModal(false);
  const [checked, setChecked] = useState(false);
  const [inputsPopulated, setInputsPopulated] = useState(false);
  const [c1Loading, setC1Loading] = useState(false);
  const [productAndUnit, setProductAndUnit] = useState([]);
  const [fuelValues, setFuelValues] = useState([]);
  const [plantRatio, setPlantRatio] = useState(0);
  const [productList, setProductList] = useState([]);
  const [formList, setFormList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getProductAndForms(agencyId, "C1-FECS");
        setProductAndFormsList(result);
        console.log("debug c1: FETCHING P&F" + JSON.stringify(result, null, 2));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const inputStyle = {
    textAlign: "right",
    width: "100%",
    height: "30px",
    marginRight: "5px",
    borderRadius: "5px",
  };

  const createRows = () => {
    return productAndFormsList.map((product, index) => {
      const fuelObject = fuelValues.find((fuel) => fuel.index === index + 1);
      const isStandalone = product.standalone;
      let totalSelloutBalance = 0;

      if (isStandalone) {
        const totalSellout = totalSellouts.find(
          (sellout) => sellout[product.productName] !== undefined
        );
        totalSelloutBalance = totalSellout
          ? totalSellout[product.productName]
          : 0;
      }

      const row = {
        no: index + 1,
        product: product.productName,
        unit: (
          <ClaySelect
            id={`draft_c1_selectUnit_${product.productName}`}
            onChange={(e) => handleUnitChange(e, index, product.productName)}
            disabled={isDisabled}
          >
            <option value="">Select...</option>
            {product.units.map((unit, unitIndex) => (
              <option key={unitIndex} value={unit.productUnitName}>
                {unit.productUnitName}
              </option>
            ))}
          </ClaySelect>
        ),
        ...product.forms.reduce((acc, form) => {
          acc[form.formFieldCode] = (
            <NumericFormat
              className="no-spinner"
              key={`${index + 1}_${form.formFieldCode}`}
              disabled={isDisabled}
              style={inputStyle}
              id={`draft_c1_num_${product.productName}_${form.formFieldCode}`}
              onValueChange={(values) => {
                handleFuelValueChange(
                  values,
                  index + 1,
                  product.productName,
                  form.parentFormFieldCode,
                  form.formFieldName,
                  form.formFieldCode,
                  form.subtractive
                );
              }}
              valueIsNumericString={true}
              customInput={ClayInput}
              thousandSeparator
            />
          );
          return acc;
        }, {}),
        balance: (
          <span
            style={{
              paddingRight: "10px",
              display: "block",
              textAlign: "right",
            }}
          >
            {fuelObject ? (
              <NumericFormat
                displayType="text"
                value={(fuelObject.balance + totalSelloutBalance).toFixed(2)}
                thousandSeparator
              />
            ) : (
              <NumericFormat
                displayType="text"
                value={(0).toFixed(2)}
                thousandSeparator
              />
            )}
          </span>
        ),
      };

      return row;
    });
  };

  const populateData = (rows) => {
    console.log("totalSellOuts >>>>> ", totalSellouts);

    const newFuelValues = []; // Initialize new fuelValues

    if (fetchedReport && (viewMode === "draft" || viewMode === "pending")) {
      const initialProductAndUnit = [];

      //set Product And Unit Initially

      if (productAndUnit.length == 0) {
        fetchedReport.forms.forEach((formCategory) => {
          formCategory.categories.forEach((category) => {
            category.products.forEach((fetchedProduct) => {
              const existingProduct = initialProductAndUnit.find(
                (item) => item.fieldName === fetchedProduct.fieldName
              );
              if (!existingProduct) {
                initialProductAndUnit.push({
                  fieldName: fetchedProduct.fieldName,
                  unit: fetchedProduct.unit || "",
                });
              }
            });
          });
        });
        console.log("Fetched ProductAndUnit:", initialProductAndUnit); // Debugging log
        setProductAndUnit(initialProductAndUnit);
      }

      const updatedRows = rows.map((row, rowIndex) => {
        const product = productAndFormsList[rowIndex];

        // Fetch selected unit from productAndUnit (directly from state)
        const selectedUnit =
          productAndUnit.find((item) => item.fieldName === product.productName)
            ?.unit || "";

        // Process fetched report data
        fetchedReport.forms.forEach((formCategory) => {
          formCategory.categories.forEach((category) => {
            category.products.forEach((fetchedProduct) => {
              if (fetchedProduct.fieldName === product.productName) {
                product.forms.forEach((form) => {
                  if (category.formCode === form.formFieldCode) {
                    const formValue = fetchedProduct.value;

                    // Add or update `fuelValues`
                    let productFuel = newFuelValues.find(
                      (fv) => fv.index === rowIndex + 1
                    );
                    if (!productFuel) {
                      productFuel = {
                        index: rowIndex + 1,
                        productName: product.productName,
                        balance: 0, // Temporary, balance will be updated later
                        forms: [],
                      };
                      newFuelValues.push(productFuel);
                    }

                    // Find or add the form
                    let fuelForm = productFuel.forms.find(
                      (f) => f.formCode === form.parentFormFieldCode
                    );
                    if (!fuelForm) {
                      fuelForm = {
                        formCode: form.parentFormFieldCode,
                        categories: [],
                      };
                      productFuel.forms.push(fuelForm);
                    }

                    // Add the category
                    fuelForm.categories.push({
                      formCode: form.formFieldCode,
                      value: formValue || 0,
                      description: form.formFieldName,
                    });

                    // Update the row for UI rendering
                    row[form.formFieldCode] = (
                      <NumericFormat
                        className="no-spinner"
                        key={`${rowIndex + 1}_${form.formFieldCode}`}
                        disabled={isDisabled}
                        style={inputStyle}
                        id={`draft_c1_num_${product.productName}_${form.formFieldCode}`}
                        value={formValue}
                        onValueChange={(values) => {
                          handleFuelValueChange(
                            values,
                            rowIndex + 1,
                            product.productName,
                            form.parentFormFieldCode,
                            form.formFieldName,
                            form.formFieldCode,
                            form.subtractive
                          );
                        }}
                        valueIsNumericString={true}
                        customInput={ClayInput}
                        thousandSeparator
                      />
                    );
                  }
                });
              }
            });
          });
        });

        // Handle unit selection in rows
        console.log("selectedUnit >>>>", selectedUnit, productAndUnit);
        row.unit = (
          <ClaySelect
            id={`draft_c1_selectUnit_${product.productName}`}
            onChange={(e) =>
              handleUnitChange(e, rowIndex + 1, product.productName)
            }
            disabled={isDisabled}
            value={selectedUnit}
          >
            <option value="">Select...</option>
            {product.units.map((unit, unitIndex) => (
              <option key={unitIndex} value={unit.productUnitName}>
                {unit.productUnitName}
              </option>
            ))}
          </ClaySelect>
        );

        // Calculate balance for standalone products
        const totalSellout = totalSellouts.find(
          (sellout) => sellout[product.productName] !== undefined
        );
        const totalSelloutBalance = totalSellout
          ? totalSellout[product.productName]
          : 0;

        const sumOfValues = newFuelValues
          .filter((fuelItem) => fuelItem.productName === product.productName)
          .reduce((sum, fuelItem) => {
            return (
              sum +
              fuelItem.forms.reduce((formSum, form) => {
                return (
                  formSum +
                  form.categories.reduce((categorySum, category) => {
                    return categorySum + Math.abs(category.value || 0);
                  }, 0)
                );
              }, 0)
            );
          }, 0);

        const balance = totalSelloutBalance - sumOfValues;

        row.balance = (
          <span
            style={{
              paddingRight: "10px",
              display: "block",
              textAlign: "right",
            }}
          >
            <NumericFormat
              displayType="text"
              value={balance.toFixed(2)}
              thousandSeparator
            />
          </span>
        );

        const fuelItem = newFuelValues.find(
          (fuel) => fuel.productName === product.productName
        );
        if (fuelItem) {
          fuelItem.balance = balance;
        }

        return row;
      });

      // Update `fuelValues` state after processing all rows
      setFuelValues(newFuelValues);

      return updatedRows;
    }
    return rows;
  };

  const handleFuelValueChange = (
    values,
    index,
    productName,
    parentFormFieldCode,
    formFieldName,
    formCode,
    subtractive
  ) => {
    const { floatValue = 0 } = values;
    let value = isNaN(Number(floatValue)) ? 0 : Number(floatValue);

    /* if (subtractive) {
      value = -Math.abs(value);
    } */

    const product = productAndFormsList.find(
      (p) => p.productName === productName
    );
    const isStandalone = product ? product.standalone : false;

    // Update fuelValues
    setFuelValues((prevFuelValues) => {
      const exists = prevFuelValues.some((item) => item.index === index);

      let updatedFuelValues;

      if (exists) {
        updatedFuelValues = prevFuelValues.map((item) => {
          if (item.index === index) {
            const totalSellout = totalSellouts.find(
              (sellout) => sellout[productName] !== undefined
            );
            const totalSelloutBalance = isStandalone
              ? totalSellout?.[productName] ?? 0
              : 0;

            let formIndex = item.forms.findIndex(
              (form) => form.formCode === parentFormFieldCode
            );
            let updatedForms;

            if (formIndex !== -1) {
              updatedForms = item.forms.map((form, idx) => {
                if (idx === formIndex) {
                  let updatedCategories = form.categories.map((category) => {
                    if (category.formCode === formCode) {
                      return {
                        formCode: formCode,
                        value: value,
                        description: formFieldName,
                      };
                    } else {
                      return category;
                    }
                  });

                  if (
                    !updatedCategories.some(
                      (category) => category.formCode === formCode
                    )
                  ) {
                    updatedCategories.push({
                      formCode: formCode,
                      value: value,
                      description: formFieldName,
                    });
                  }

                  return { ...form, categories: updatedCategories };
                } else {
                  return form;
                }
              });
            } else {
              updatedForms = [
                ...item.forms,
                {
                  formCode: parentFormFieldCode,
                  categories: [
                    {
                      formCode: formCode,
                      value: value,
                      description: formFieldName,
                    },
                  ],
                },
              ];
            }

            const originalBalance = updatedForms.reduce((sum, form) => {
              return (
                sum +
                form.categories.reduce(
                  (acc, category) => acc + category.value,
                  0
                )
              );
            }, 0);

            const balance = originalBalance + totalSelloutBalance;

            return { ...item, forms: updatedForms, balance: balance };
          } else {
            return item;
          }
        });
      } else {
        const totalSellout = totalSellouts.find(
          (sellout) => sellout[productName] !== undefined
        );
        const totalSelloutBalance = isStandalone
          ? totalSellout?.[productName] ?? 0
          : 0;

        const newItem = {
          index: index,
          balance: value + totalSelloutBalance,
          productName: productName,
          forms: [
            {
              formCode: parentFormFieldCode,
              categories: [
                {
                  formCode: formCode,
                  value: value,
                  description: formFieldName,
                },
              ],
            },
          ],
        };

        updatedFuelValues = [...prevFuelValues, newItem];
      }

      // Update rows based on the modified fuelValues
      setRows((prevRows) => {
        const updatedRows = [...prevRows];
        const updatedRow = updatedRows.find((row) => row.no === index);

        if (updatedRow) {
          // Update the field for the specific formCode
          updatedRow[formCode] = (
            <NumericFormat
              className="no-spinner"
              key={`${index}_${formCode}`}
              disabled={isDisabled}
              style={inputStyle}
              id={`draft_c1_num_${productName}_${formCode}`}
              value={value}
              onValueChange={(values) => {
                handleFuelValueChange(
                  values,
                  index,
                  productName,
                  parentFormFieldCode,
                  formFieldName,
                  formCode,
                  subtractive
                );
              }}
              valueIsNumericString={true}
              customInput={ClayInput}
              thousandSeparator
            />
          );

          // Recalculate balance and update
          const newBalance = updatedFuelValues
            .filter((fuelItem) => fuelItem.productName === productName)
            .reduce((sum, fuelItem) => {
              return (
                sum +
                fuelItem.forms.reduce((formSum, form) => {
                  return (
                    formSum +
                    form.categories.reduce(
                      (categorySum, category) => categorySum + category.value,
                      0
                    )
                  );
                }, 0)
              );
            }, 0);

          const totalSellout = totalSellouts.find(
            (sellout) => sellout[productName] !== undefined
          );
          const totalSelloutBalance = totalSellout
            ? totalSellout[productName]
            : 0;

          updatedRow.balance = (
            <span
              style={{
                paddingRight: "10px",
                display: "block",
                textAlign: "right",
              }}
            >
              <NumericFormat
                displayType="text"
                value={(totalSelloutBalance - newBalance).toFixed(2)}
                thousandSeparator
              />
            </span>
          );
        }

        return updatedRows;
      });

      return updatedFuelValues;
    });
  };

  const handleUnitChange = (event, rowIndex, productName) => {
    const selectedUnit = event.target.value;
    console.log(
      `handleUnitChange triggered for productName: ${productName}, rowIndex: ${rowIndex}, selectedUnit: ${selectedUnit}`
    );

    // Directly set or update the unit for the product
    setProductAndUnit((prevUnits) => {
      // Create a copy of the current state
      const updatedUnits = [...prevUnits];

      // Find the index of the product in the array
      const unitIndex = updatedUnits.findIndex(
        (unit) => unit.fieldName === productName
      );

      if (unitIndex > -1) {
        // Update existing entry
        updatedUnits[unitIndex] = {
          ...updatedUnits[unitIndex],
          unit: selectedUnit,
        };
        console.log(`Updated unit for productName: ${productName}`);
      } else {
        // Add new entry if it doesn't exist
        updatedUnits.push({ fieldName: productName, unit: selectedUnit });
        console.log(`Added new unit for productName: ${productName}`);
      }

      console.log("Updated productAndUnit state:", updatedUnits);
      return updatedUnits; // Return the updated array
    });
  };

  //After rows changes, headers is formed
  useEffect(() => {
    if (productAndFormsList.length === 0) return;

    const dynamicHeaders = [
      { id: "no", name: "No." },
      { id: "product", name: "Product" },
      { id: "unit", name: "Unit" },
      { id: "balance", name: "Balance" },
      ...productAndFormsList[0].forms.map((form) => ({
        id: form.formFieldCode,
        name: `${form.formFieldName}`,
      })),
      // totalSellouts
      //   ?
      //   { id: 'totalSellout', name: 'Total Sell Out' }
      //   : null,
    ];
    setHeaders(dynamicHeaders);
  }, [rows]);

  //merge

  // const [combinedStructure, setCombinedStructure] = useState(null);

  useEffect(() => {
    const formsMap = {};

    fuelValues.forEach((fuelItem) => {
      fuelItem.forms.forEach((form) => {
        if (!formsMap[form.formCode]) {
          formsMap[form.formCode] = {
            formCode: form.formCode,
            categories: [],
          };
        }

        form.categories.forEach((category) => {
          const product = {
            fieldName: fuelItem.productName,
            value: Math.abs(category.value), // ensure always positive
            unit:
              productAndUnit.find(
                (item) => item.fieldName === fuelItem.productName
              )?.unit || "",
          };

          const categoryIndex = formsMap[form.formCode].categories.findIndex(
            (cat) => cat.formCode === category.formCode
          );

          if (categoryIndex !== -1) {
            formsMap[form.formCode].categories[categoryIndex].products.push(
              product
            );
          } else {
            formsMap[form.formCode].categories.push({
              formCode: category.formCode,
              description: category.description,
              products: [product],
            });
          }
        });
      });
    });

    const formsArray = Object.values(formsMap);

    setReportMap((prevState) => ({
      ...prevState,
      reportPeriodYear: selectedYear,
      reportQuarter: selectedQuarter,
      forms: [
        // Remove existing forms with the same formCode
        ...prevState.forms.filter(
          (form) =>
            !formsArray.some((newForm) => newForm.formCode === form.formCode)
        ),
        // Add the updated forms
        ...formsArray,
      ],
    }));
  }, [fuelValues, productAndUnit, selectedQuarter, selectedYear]);

  useEffect(() => {
    setCombinedStructure(reportMap);
  }, [reportMap]);

  //changes in F4-SEU standalone product in FB will be detected here for balance calculation since cannot be detected in handleFuelValueChange
  // Update fuelValues when totalSellouts changes to reflect the changes in balance

  useEffect(() => {
    if (productAndFormsList.length !== 0) {
      // Ensure ktoe conversion runs only when `combinedStructure` changes
      const ktoeReport = convertToKtoe(combinedStructure);
      setCheckingReport(combinedStructure); // Original report
      setCheckingReportKtoe(ktoeReport); // ktoe-converted report
    }
  }, [combinedStructure]); // Updated dependency to fix potential rerender issues

  function convertToKtoe(report) {
    if (!report?.forms) return report; // Early return if forms don't exist

    // Deep clone the report to avoid mutating the original state
    const updatedReport = {
      ...report,
      forms: report.forms.map((form) => ({
        ...form,
        categories: form.categories.map((category) => ({
          ...category,
          products: category.products.map((product) => {
            const matchingProduct = productAndFormsList.find(
              (p) => p.productName === product.fieldName
            );

            if (matchingProduct) {
              const matchingUnit = matchingProduct.units.find(
                (unit) =>
                  unit.productUnitName === product.unit ||
                  unit.productUnitName === "ktoe"
              );

              if (matchingUnit?.inKtoe !== undefined) {
                return {
                  ...product,
                  value: product.value * matchingUnit.inKtoe,
                  unit: "ktoe",
                };
              } else {
                console.log("debugc1: matching unit not found");
                console.error(
                  `Matching unit or ktoe value not found for product: ${product.fieldName}`
                );
              }
            } else {
              console.log(
                "debugc1: matching product not found: " + product.fieldName
              );
              console.log(
                "debugc1: productAndFormsList: " +
                  JSON.stringify(productAndFormsList, null, 2)
              );
            }
            return { ...product }; // Return the original product if no matching product is found
          }),
        })),
      })),
    };
    return updatedReport;
  }

  //check if form is balanced and have no missing unit
  useEffect(() => {
    setFuelValuesC1Checking(fuelValues);
  }, [fuelValues]);

  useEffect(() => {
    setProductAndUnitC1Checking(productAndUnit);
  }, [productAndUnit]);

  useEffect(() => {
    // Step 1: Create basic rows dynamically
    const baseRows = createRows();
    const populatedRows = populateData(baseRows, true);

    // Update rows only if there's a difference
    setRows(populatedRows);
    setProductList(productAndFormsList.map((item) => item.productName));

    console.log("productAndFormsList is : ", productAndFormsList);

    if (productAndFormsList.length > 0 && productAndFormsList[0].forms) {
      setFormList(
        productAndFormsList[0].forms.map(
          (item) => item.formFieldCode + ": " + item.formFieldName
        )
      );
    } else {
      console.error("Forms data is missing or invalid.");
    }
  }, [productAndFormsList, totalSellouts, productAndUnit]);

  return (
    <div>
      {c1Loading ? <div style={{ textAlign: "center" }}>Loading...</div> : null}

      <Button
        variant="success"
        size="sm"
        style={{ minWidth: "7em" }}
        onClick={handleShowCheckingModal}
      >
        {reportStatus === "Approved" ? "View Report" : "Check"}
      </Button>
      <br /><br />

      <Accordion defaultActiveKey={["0"]} alwaysOpen>
        {rows.map((row) => (
          <div>
            <Accordion.Item eventKey={row.no} className="mb-1">
              <Accordion.Header className="accordion-bg-color1">
                {row.no}. {row.product}
              </Accordion.Header>
              <Accordion.Body>
                <Table
                  responsive
                  bordered
                  hover
                  size="sm"
                  className="mt-1"
                  style={{ width: "45em" }}
                >
                  <tbody>
                    {headers.map((column) =>
                      column.id === "no" ? (
                        ""
                      ) : (
                        <tr>
                          <td className="text-bold" style={{ width: "25em" }}>
                            {column.name}
                          </td>
                          <td
                            className={
                              column.id === "product"
                                ? "text-bold text-uppercase"
                                : ""
                            }
                          >
                            {row[column.id]}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </div>
        ))}
      </Accordion>
      <br />

      {/* 
      <div class="table-responsive">
        <table class="table-md table-bordered">
          <thead>
            <tr>
              {headers.map((column) => (
                <th
                  key={column.id}
                  className={
                    column.id === 'no' || column.id === 'balance' || column.id === 'totalSellout' ?
                      "table-cell-no-width"
                      : "table-cell-minw-200"}
                  style={
                    column.id === 'product'
                      ? {
                        position: 'sticky',
                        left: 0,  // First sticky column
                        backgroundColor: 'white',
                        zIndex: 1,  // Ensure it's above other cells
                      }
                      : column.id === 'balance'
                        ? {
                          position: 'sticky',
                          left: '199px',  // Adjust based on column width
                          backgroundColor: 'white',
                          zIndex: 1,  // Ensure it's above other cells
                        }
                        : {}
                  }
                >
                  {column.name}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {rows.map(row => (
              <tr key={row.no}>
                {headers.map(column => (
                  <td
                    key={column.id}
                    className={
                      column.id === 'no' || column.id === 'balance' || column.id === 'totalSellout' ?
                        "table-cell-no-width"
                        : "table-cell-minw-200"}
                    style={
                      column.id === 'product'
                        ? {
                          position: 'sticky',
                          left: 0,  // First sticky column
                          backgroundColor: 'white',
                          zIndex: 1,  // Ensure it's above other cells
                        }
                        : column.id === 'balance'
                          ? {
                            position: 'sticky',
                            left: '199px',  // Adjust based on column width
                            backgroundColor: 'white',
                            zIndex: 1,  // Ensure it's above other cells
                          }
                          : {}
                    }
                  >
                    {row[column.id]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>  */}

      <FuelBalanceChartTabsDP
        energySector={energySector}
        plantRatio={plantRatio}
        selectedYear={selectedYear}
        selectedQuarter={selectedQuarter}
        productList={productList}
        formList={formList}
        productAndFormsList={productAndFormsList}
        agencyId={agencyId}
      />

      <Modal size="xl" show={showModal} onHide={handleCloseCheckingModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {reportStatus === "Approved" ? "View Report" : "Confirm Submission"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {reportStatus !== "Approved" && (
              <div className="mb-3">
                Check all numbers, balances or units in your Fuel Balance.{" "}
              </div>
            )}
              
            <Form.Group className="row form-group-sm mb-2">
              <div className="col-12">
                <span className="mr-2" id="C1_unitoriginal" style={{fontWeight: showKtoe ? 'normal':'bold'}}>Original unit</span>
                <ClayToggle
                  onToggle={handleKtoeToggle}
                  toggled={showKtoe}
                />
                <span className="ml-1" id="C1_unitktoe" style={{fontWeight: showKtoe ? 'bold':'normal'}}>In ktoe</span>
              </div>
            </Form.Group>

            {!showKtoe ? (
              <C1TableCheckingDP
                agencyId={agencyId}
                checkingReport={checkingReport}
                totalSellouts={totalSellouts}
              />
            ) : (
              <C1TableCheckingKtoeDP
                agencyId={agencyId}
                checkingReportOriginalUnit={checkingReport}
                checkingReportKtoe={checkingReportKtoe}
                totalSellouts={totalSellouts}
              />
            )}
          </div>
        </Modal.Body>
        {/*<Modal.Footer>
           <button className="btn btn-outline-secondary mr-1" onClick={handleCloseCheckingModal}>
              Close
            </button>
            <button
                  onClick={() => submitReport("Draft")}
                  className="btn btn-outline-primary mr-1"
                >
                  Save As Draft
                </button>
            <button className="btn btn-primary mr-1" onClick={handleShowConfirmationModal}>
              Submit
            </button> 
        </Modal.Footer>*/}
      </Modal>
    </div>
  );
};

export default C1TableDP;
