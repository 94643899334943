import React, { useEffect, useState } from "react";
import { getSubmissionListYear, getDashboardDTAResult } from "../../../api/nebReportService";
import { Row, Col, Card, Form, InputGroup, Modal, Button, Table, Badge } from 'react-bootstrap';
import { Link } from "react-router-dom";
import GaugeChart from "./GaugeChart";
import PostAnnouncement from "./PostAnnouncement";
import PastAnnouncement from "./PastAnnouncement";
import AgencyBySector from "./AgencyBySector";
import CustomLegend from "./CustomLegend";
import FormUsageFrequencyChart from "./FormUsageFrequencyChart";
import YearComparisonChart from "./YearComparisonChart"; // New Chart Component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import * as solidIcons from '@fortawesome/free-solid-svg-icons';

const FormsStatus = () => {

    const [reports, setReports] = useState();
    const [selectedYear, setSelectedYear] = useState();
    const [statusCounts, setStatusCounts] = useState({});
    const [filteredData, setFilteredData] = useState();
    const [yearList, setYearList] = useState([]);
    const [q1GaugeData, setQ1GaugeData] = useState();
    const [q2GaugeData, setQ2GaugeData] = useState();
    const [q3GaugeData, setQ3GaugeData] = useState();
    const [q4GaugeData, setQ4GaugeData] = useState();
    const [totalSubmissionThatYear, setTotalSubmissionThatYear] = useState();
    const [newAnnPosted, setNewAnnPosted] = useState(0);
    const [dtaResult, setDTAResult] = useState([]);
    const [productList, setProductList] = useState([]);
    const [comparisonYear, setComparisonYear] = useState(); // New state for comparison year
    const [comparisonData, setComparisonData] = useState(); // New state for comparison data

    // get current year and quarter
    const date = new Date();
    const currentYear = date.getFullYear();

    const fetchList = async (year) => {
        try {
            const result = await getSubmissionListYear(year); // Fetch data for the specified year
            // Filter out Draft status reports
            const noDraft = result.data.filter(report => report.reportStatus !== "Draft");
            console.log(`Filtered result for year ${year}:`, noDraft);
            return noDraft; // Return the filtered data
        } catch (error) {
            console.error(`Error fetching list for year ${year}:`, error);
            return []; // Return an empty array in case of an error
        }
    };

    // get all reports
    useEffect(() => {
        const initializeData = async () => {
            const currentYearData = await fetchList(currentYear);
            setReports(currentYearData); // Set reports for the current year
            setSelectedYear(currentYear.toString());
            setComparisonYear((currentYear - 1).toString()); // Default comparison year

            const comparisonYearData = await fetchList(currentYear - 1);
            setComparisonData(comparisonYearData);

            // Generate year list dynamically from 2010 to the current year
            const startYear = 2010;
            const yearList = Array.from(
                { length: currentYear - startYear + 1 },
                (_, i) => (currentYear - i).toString()
            );
            setYearList(yearList);
        };

        initializeData();
    }, []);

    useEffect(() => {
        if (selectedYear) {
            console.log("selectedYear " + selectedYear);

            const fetchFilteredData = async () => {
                const filteredData = await fetchList(selectedYear);
                setFilteredData(filteredData);
                setReports(filteredData);
                const counts = countStatuses(filteredData);
                setStatusCounts(counts);
            };

            fetchFilteredData();
        }
    }, [selectedYear]);

    useEffect(() => {
        if (comparisonYear) {
            const fetchComparisonData = async () => {
                const comparisonYearData = await fetchList(parseInt(comparisonYear));
                setComparisonData(comparisonYearData);
            };

            fetchComparisonData();
        }
    }, [comparisonYear]);

    const handleYearSelectChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const handleComparisonYearSelectChange = (event) => {
        setComparisonYear(event.target.value);
    };

    const countStatuses = (data) => {
        console.log("data is " + data);
        return data.reduce((acc, report) => {
            let statusKey;

            switch (report.reportStatus) {
                case 'Pending for Approval':
                    statusKey = 'pendingForApproval';
                    break;
                case 'Pending for Amendment':
                    statusKey = 'pendingForAmendment';
                    break;
                case 'Approved':
                    statusKey = 'approved';
                    break;
                default:
                    return acc;
            }

            acc[statusKey] = (acc[statusKey] || 0) + 1;
            return acc;
        }, {});
    };

    useEffect(() => {
        const q1 = getReportsByStatusAndQuarter(filteredData, "Q1");
        setQ1GaugeData(q1);
        const q2 = getReportsByStatusAndQuarter(filteredData, "Q2");
        setQ2GaugeData(q2);
        const q3 = getReportsByStatusAndQuarter(filteredData, "Q3");
        setQ3GaugeData(q3);
        const q4 = getReportsByStatusAndQuarter(filteredData, "Q4");
        setQ4GaugeData(q4);
        setTotalSubmissionThatYear(calculateSum(statusCounts));
    }, [filteredData, statusCounts]);

    const calculateSum = (data) => {
        return Object.values(data).reduce((sum, value) => sum + value, 0);
    };

    const getReportsByStatusAndQuarter = (filteredData, targetQuarter) => {
        if (filteredData && filteredData.length > 0) {
            const initialStatusCounts = {
                "Approved": undefined,
                "Pending for Approval": undefined,
                "Pending for Amendment": undefined,
            };

            return filteredData.reduce((acc, report) => {
                if (report.reportQuarter === targetQuarter) {
                    acc[report.reportStatus] = (acc[report.reportStatus] || 0) + 1;
                }
                return acc;
            }, initialStatusCounts);
        }
    };

    return (
        <div className="mt-4">
            {/* Card content */}
            <Card>
                <Card.Header>FORM STATUS</Card.Header>
                <Card.Body>
                    <Form.Group className="row">
                        <Form.Label bsPrefix="col-form-label" htmlFor="year" className="col-sm-3 col-lg-2">Year :</Form.Label>
                        <Col className="col-sm-3 col-lg-4">
                            <Form.Select
                                bsPrefix={'form-control'}
                                id="year" 
                                value={selectedYear} onChange={handleYearSelectChange}>
                                {yearList.map(year => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>

                        <Form.Label bsPrefix="col-form-label" htmlFor="comparisonYear" className="col-sm-3 col-lg-2">Compare With :</Form.Label>
                        <Col className="col-sm-3 col-lg-4">
                            <Form.Select
                                bsPrefix={'form-control'}
                                id="comparisonYear" 
                                value={comparisonYear} onChange={handleComparisonYearSelectChange}>
                                {yearList.map(year => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Form.Group>

                    {/* Additional content */}
                    <Row className="mt-4">
                        <Col className="col-lg-4 col-md-6">
                            <div className="small-box color-palette bg-yellow">
                                <div className="inner">
                                    <h3>{statusCounts.pendingForApproval ? statusCounts.pendingForApproval : 0}</h3>
                                    <p>Pending for Approval</p>
                                </div>
                                <div className="icon">
                                    <FontAwesomeIcon icon={solidIcons['faWindowRestore']} />
                                </div>
                            </div>
                        </Col>
                        <Col className="col-lg-4 col-md-6">
                            <div className="small-box color-palette bg-pink">
                                <div className="inner">
                                    <h3>{statusCounts.pendingForAmendment ? statusCounts.pendingForAmendment : 0}</h3>
                                    <p>Pending for Amendment</p>
                                </div>
                                <div className="icon">
                                    <FontAwesomeIcon icon={solidIcons['faSyncAlt']} />
                                </div>
                            </div>
                        </Col>
                        <Col className="col-lg-4 col-md-6">
                            <div className="small-box color-palette bg-success">
                                <div className="inner">
                                    <h3>{statusCounts.approved ? statusCounts.approved : 0}</h3>
                                    <p>Approved</p>
                                </div>
                                <div className="icon">
                                    <FontAwesomeIcon icon={solidIcons['faCalendarCheck']} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col className="col-lg-12">
                            <YearComparisonChart
                                selectedYear={selectedYear}
                                comparisonYear={comparisonYear}
                                reports={reports}
                                reportsToCompare={comparisonData} // Pass reportsToCompare
                            />
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col>
                            <Badge pill text="white" className="badge-color1 text-md">
                                <FontAwesomeIcon icon={solidIcons['faTags']} className="mr-2" /> Submitted Forms by Quarter
                            </Badge>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col className="col-lg-3 col-md-6">
                            <GaugeChart totalSubmissionThatYear={totalSubmissionThatYear} quarter={"Quarter 1"} gaugeData={q1GaugeData} />
                        </Col>
                        <Col className="col-lg-3 col-md-6">
                            <GaugeChart totalSubmissionThatYear={totalSubmissionThatYear} quarter={"Quarter 2"} gaugeData={q2GaugeData} />
                        </Col>
                        <Col className="col-lg-3 col-md-6">
                            <GaugeChart totalSubmissionThatYear={totalSubmissionThatYear} quarter={"Quarter 3"} gaugeData={q3GaugeData} />
                        </Col>
                        <Col className="col-lg-3 col-md-6">
                            <GaugeChart totalSubmissionThatYear={totalSubmissionThatYear} quarter={"Quarter 4"} gaugeData={q4GaugeData} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {/* Additional Card content */}
            <Card className="mt-4">
                <Card.Header>POST ANNOUNCEMENT</Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <PostAnnouncement setNewAnnPosted={setNewAnnPosted} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className="mb-0 p-1 flat-border">
                                <PastAnnouncement newAnnPosted={newAnnPosted} />
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {/* Additional Cards for Trend Analysis and Agency by Sector */}
            <Card className="mt-4">
                <Card.Header>TOTAL AGENCY REGISTERED BY SECTOR</Card.Header>
                <Card.Body>
                    <AgencyBySector />
                </Card.Body>
            </Card>

            <Card className="mt-4">
                <Card.Header>TREND ANALYSIS</Card.Header>
                <Card.Body>
                    <FormUsageFrequencyChart dtaResult={dtaResult} selectedYear={selectedYear} />
                </Card.Body>
            </Card>

        </div>
    );
};

export default FormsStatus;