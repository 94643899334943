import React, { useState, useCallback, useEffect } from 'react';
import { getProductAndForms } from "../../../../api/nebProductService";
import { ClaySelect, ClayInput } from '@clayui/form';
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { exportToPdf, exportToExcel } from './exportToPdf';
import { NumericFormat } from 'react-number-format';
import { Row, Col, Card, Form, InputGroup, Modal, Button, Table, Badge, Accordion } from 'react-bootstrap';

const C1TableCheckingKtoeDP = ({ agencyId, checkingReportOriginalUnit, checkingReportKtoe, totalSellouts }) => {

  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [inputsPopulated, setInputsPopulated] = useState(false);
  const [productAndFormsList, setProductAndFormsList] = useState([]);
  const [reportBasicInfo, setReportBasicInfo] = useState();
  const [c1KtoeLoading, setC1KtoeLoading] = useState(false);

  const numInputStyle = {
    textAlign: "right",
    width: "100%",
    height: "40px",
    marginRight: "5px",
    borderRadius: "5px",
    background: "#f8f8f8",
    color: "black"
  };

  const selectInputStyle = {
    width: "100%",
    height: "40px",
    marginRight: "5px",
    borderRadius: "5px",
    background: "#f8f8f8",
    color: "black"
  }
  const [fuelValues, setFuelValues] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getProductAndForms(agencyId, 'C1-FECS');
        console.log('FETCHING P&F' + JSON.stringify(result, null, 2));
        setProductAndFormsList(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();

    console.log("debugc1: report" + JSON.stringify(checkingReportKtoe, null, 2))
    //console.log("debugc1: totalsellouts" + JSON.stringify(totalSellouts, null, 2))
  }, []);

  const getUnitByFieldName = (data, targetFieldName) => {
    for (const form of data.forms) {
      for (const category of form.categories) {
        for (const product of category.products) {
          if (product.fieldName === targetFieldName) {
            return product.unit;
          }
        }
      }
    }
    return ""; // if fieldName is not found
  };

  const handleFuelValueChange = (values, index, productName, parentFormFieldCode, formFieldName, formCode, subtractive) => {
    const { floatValue = 0 } = values;
    let value = isNaN(Number(floatValue)) ? 0 : Number(floatValue);

    // Make value negative based on form type
    if (subtractive) {
      value = -Math.abs(value);
    }

    // Find the product in productList to check if it's standalone
    const product = productAndFormsList.find(p => p.productName === productName);
    const isStandalone = product ? product.standalone : false;

    // Get the totalSellout value if standalone is true
    let totalSelloutBalance = 0;

    const totalSellout = totalSellouts.find(sellout => sellout[productName] !== undefined);
    totalSelloutBalance = totalSellout ? totalSellout[productName] : 0;

    setFuelValues(prevFuelValues => {
      const exists = prevFuelValues.some(item => item.index === index);

      if (exists) {
        return prevFuelValues.map(item => {
          if (item.index === index) {
            let formIndex = item.forms.findIndex(form => form.formCode === parentFormFieldCode);
            let updatedForms;

            if (formIndex !== -1) {
              updatedForms = item.forms.map((form, idx) => {
                if (idx === formIndex) {
                  let updatedCategories = form.categories.map(category => {
                    if (category.formCode === formCode) {
                      return { formCode: formCode, value: value, description: formFieldName };
                    } else {
                      return category;
                    }
                  });

                  if (!updatedCategories.some(category => category.formCode === formCode)) {
                    updatedCategories.push({ formCode: formCode, value: value, description: formFieldName });
                  }

                  return { ...form, categories: updatedCategories };
                } else {
                  return form;
                }
              });
            } else {
              updatedForms = [...item.forms, { formCode: parentFormFieldCode, categories: [{ formCode: formCode, value: value, description: formFieldName }] }];
            }

            // Calculate balance by adding both the form balance and totalSelloutBalance if standalone is true
            const originalBalance = updatedForms.reduce((sum, form) => {
              return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
            }, 0);

            const balance = originalBalance + (isStandalone ? totalSelloutBalance : 0);

            return { ...item, forms: updatedForms, balance: balance };
          } else {
            return item;
          }
        });
      } else {
        const newItem = {
          index: index,
          balance: value,
          productName: productName,
          forms: [
            {
              formCode: parentFormFieldCode,
              categories: [
                { formCode: formCode, value: value, description: formFieldName }
              ]
            }
          ]
        };

        const originalBalance = newItem.forms.reduce((sum, form) => {
          return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
        }, 0);

        const balance = originalBalance + (isStandalone ? totalSelloutBalance : 0);

        return [
          ...prevFuelValues,
          { ...newItem, balance: balance }
        ];
      }
    });
  };


  const [productAndUnit, setProductAndUnit] = useState([]);

  const handleUnitChange = (e, index, productName) => {
    const { value: unit } = e.target;

    setProductAndUnit(prevProductAndUnit => {
      // Check if an object with matching productName exists
      const exists = prevProductAndUnit.some(item => item.fieldName === productName);

      if (exists) {
        // Update the existing object
        return prevProductAndUnit.map(item => {
          if (item.fieldName === productName) {
            return {
              ...item,
              unit: unit
            };
          } else {
            return item;
          }
        });
      } else {
        // Add a new object
        const newItem = {
          fieldName: productName,
          unit: unit
        };

        return [
          ...prevProductAndUnit,
          newItem
        ];
      }
    });
  };

  // After productList changes, rows are formed
  useEffect(() => {
    if (productAndFormsList.length === 0) return;
    const dynamicRows = productAndFormsList.map((product, index) => {
      const fuelObject = fuelValues.find(fuel => fuel.index === index + 1);
      console.log("debugc1: fuelObject: " + JSON.stringify(fuelObject, null, 2))
      return {
        no: index + 1,
        product: product.productName,
        // totalSellout: (<span style={{ "padding-right": "10px", display: "block", textAlign: "right" }}>{totalSellouts.find(item => item[product.productName])?.[product.productName] ?? 0}</span>),
        unit: (
          <ClaySelect
            disabled
            style={selectInputStyle}
            key={`selectUnit_${product.productName}`}
            id={`selectUnit_${product.productName}`}
            onChange={(e) => handleUnitChange(e, index, product.productName)}>
            <option value="ktoe">ktoe</option>
            {product.units.map((unit, unitIndex) => (
              <option
                key={unitIndex}
                value={unit.productUnitName}
              >
                {unit.productUnitName}
              </option>
            ))}
          </ClaySelect>
        ),
        ...product.forms.reduce((acc, form) => {
          acc[form.formFieldCode] = (
            <NumericFormat
              disabled
              key={`${index + 1}_${form.formFieldCode}`}
              id={`num_${product.productName}_${form.formFieldCode}`}
              style={numInputStyle}
              name={form.formFieldCode}
              onValueChange={(values) => {
                const { floatValue } = values; // Extract the numeric value
                handleFuelValueChange(
                  values,
                  index + 1,
                  product.productName,
                  form.parentFormFieldCode,
                  form.formFieldName,
                  form.formFieldCode,
                  form.subtractive
                );
              }}
              valueIsNumericString={true}
              customInput={ClayInput}
              thousandSeparator
            />
          );
          return acc;
        }, {}),
        balance: (
          <span style={{ paddingRight: "10px", display: "block", textAlign: "right" }}>
            {fuelObject && typeof fuelObject.balance === "number" ? (
              <NumericFormat displayType="text" value={fuelObject.balance.toFixed(3)} thousandSeparator />
            ) : (
              0
            )}
          </span>
        ) // Handle undefined case
      }
    });

    setRows(dynamicRows);
  }, [totalSellouts, productAndFormsList, fuelValues]);

  //After rows changes, headers is formed
  useEffect(() => {
    if (productAndFormsList.length === 0) return;

    const dynamicHeaders = [
      { id: 'no', name: 'No.' },
      { id: 'product', name: 'Product' },
      { id: 'unit', name: 'Unit' },
      { id: 'balance', name: 'Balance' },
      ...productAndFormsList[0].forms.map(form => ({
        id: form.formFieldCode,
        name: `${form.formFieldName}`
      })),
      // totalSellouts
      //   ?
      //   { id: 'totalSellout', name: 'Total Sell Out' }
      //   : null,
    ];
    setHeaders(dynamicHeaders);
    console.log("dynamicHeaders: " + JSON.stringify(dynamicHeaders, null, 2))
  }, [rows]);

  //after finish forming rows and headers, try fetch report. if got result, fill into the inputs.
  useEffect(() => {
    if (checkingReportKtoe && productAndFormsList.length > 0 && !inputsPopulated) {
      console.log("received: " + JSON.stringify(productAndFormsList, null, 2));
      // Iterate through the forms and categories in fetchedData
      checkingReportKtoe.forms.forEach((form) => {
        form.categories.forEach((category) => {
          category.products.forEach((product) => {

            // NUMERICAL INPUTS
            // Construct the id to match the <input> elements
            const numInputId = `num_${product.fieldName}_${category.formCode}`;
            console.log("received: " + numInputId);
            const numInputElement = document.getElementById(numInputId);

            if (numInputElement) {
              // Wait for 3 seconds before executing the block of code
              const value = product.value;
              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
              nativeInputValueSetter.call(numInputElement, value);

              // Dispatch the 'input' event to trigger onChange in React
              const event = new Event('input', { bubbles: true });
              numInputElement.dispatchEvent(event);
              setC1KtoeLoading(false);
            }

            // DROPDOWN INPUTS
            // Construct the id to match the <select> elements
            const selectInputId = `selectUnit_${product.fieldName}`;
            const selectElement = document.getElementById(selectInputId);

            if (selectElement) {
              const value = product.unit;
              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, 'value').set;
              nativeInputValueSetter.call(selectElement, value);

              // Dispatch the 'input' event to trigger onChange in React
              const event = new Event('change', { bubbles: true });
              selectElement.dispatchEvent(event);
            }
          });
        });
      });
      setInputsPopulated(true);

      //Get report basic info
      setReportBasicInfo(
        {
          "year": checkingReportKtoe.reportPeriodYear,
          "quarter": checkingReportKtoe.reportQuarter,
          "agency": checkingReportKtoe.agency.agencyName,
          "dataProvider": checkingReportKtoe.dataProvider.name,
          "status": checkingReportKtoe.reportStatus,
          "type": "Final Energy Consumption Sectors (C1) (KTOE)"
        }
      )
    }
  }, [checkingReportKtoe, headers]);

  return (
    <div>
      {/* Export to PDF */}
      <FontAwesomeIcon
        title="Download as PDF"
        style={{ cursor: 'pointer' }}
        onClick={() => exportToPdf("c1-ktoe-table", reportBasicInfo)}
        className='mr-2 text-primary'
        size='xl'
        icon={faFilePdf}
      />

      {/* Export to Excel */}
      <FontAwesomeIcon
        title="Download as Excel"
        style={{ cursor: 'pointer' }}
        onClick={() => exportToExcel("c1-ktoe-table", reportBasicInfo)}
        className='text-primary'
        size='xl'
        icon={faFileExcel}
      />
      
      {c1KtoeLoading ? <div>Loading...</div> :
        <Table responsive bordered hover size="sm" className="mt-3" id="c1-ktoe-table">
          <thead>
            <tr>
              {headers.map((column) => (
                <th
                  key={column.id}
                  className={
                    column.id === 'no' || column.id === 'balance' || column.id === 'totalSellout' ?
                      "table-cell-no-width"
                      : "table-cell-minw-200"}
                  style={
                    column.id === 'product'
                      ? {
                        position: 'sticky',
                        left: 0,  // First sticky column
                        backgroundColor: '#ECECEF',
                        zIndex: 1,  // Ensure it's above other cells
                      }
                      : column.id === 'balance'
                        ? {
                          position: 'sticky',
                          left: '199px',  // Adjust based on column width
                          backgroundColor: '#ECECEF',
                          zIndex: 1,  // Ensure it's above other cells
                        }
                        : {}
                  }
                >
                  {column.name}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {rows.map(row => (
              <tr key={row.no}>
                {headers.map(column => (
                  <td
                    key={column.id}
                    className={
                      column.id === 'no' || column.id === 'balance' || column.id === 'totalSellout' ?
                        "table-cell-no-width"
                        : "table-cell-minw-200"}
                    style={
                      column.id === 'product'
                        ? {
                          position: 'sticky',
                          left: 0,  // First sticky column
                          backgroundColor: '#f7f7f7',
                          zIndex: 1,  // Ensure it's above other cells
                        }
                        : column.id === 'balance'
                          ? {
                            position: 'sticky',
                            left: '199px',  // Adjust based on column width
                            backgroundColor: '#f7f7f7',
                            zIndex: 1,  // Ensure it's above other cells
                          }
                          : {}
                    }
                  >
                    {row[column.id]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      }

    </div>

  );
};

export default C1TableCheckingKtoeDP;
