import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";

// Reusable component for loading spinner and iframe
const IframeWithLoader = ({ src }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <div style={{ padding: "20px" }}>
      {/* Loader */}
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
            position: "relative",
            zIndex: 1,
          }}
        >
          <i
            className="fas fa-spinner fa-spin"
            style={{ fontSize: "5rem", color: "#007bff" }}
          ></i>
        </div>
      )}

      {/* Iframe */}
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "0",
          paddingTop: "56.2500%",
          paddingBottom: "0",
          boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
          marginTop: "0",
          marginBottom: "0",
          overflow: "hidden",
          borderRadius: "5px",
          willChange: "transform",
          visibility: isLoading ? "hidden" : "visible",
        }}
      >
        <iframe
          loading="lazy"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            border: "none",
            padding: "0",
            margin: "0",
          }}
          src={src}
          onLoad={handleIframeLoad}
          allowFullScreen="allowfullscreen"
          allow="fullscreen"
        ></iframe>
      </div>
    </div>
  );
};

const NEBInteractiveManual = () => {
  // Define accordion items with titles and iframe sources
  const items = [
    { title: "My Profile", src: "https://www.canva.com/design/DAGbZ4S4y-4/g6Ew6t2GqZmWCCKg_xv5Mg/view?embed" },
    { title: "Dashboard", src: "https://www.canva.com/design/DAGbHqJIyAs/7IxCtytlRLKp2xBqVidIUQ/view?embed" },
    { title: "Data Submission", src: "https://www.canva.com/design/DAGbnAtaAdw/j_NSCGnX_ahfUBA9YQ8sug/view?embed" },
    { title: "Manual Submission", src: "https://www.canva.com/design/DAGbNYT_ANw/nCUHPjqaEl64l12_1S9qCw/view?embed" },
    { title: "Report & Resolve Centre", src: "https://www.canva.com/design/DAGbPKa4OVs/MDd7Ak9njnGI-Z12u4jX2Q/view?embed" },
    { title: "FAQ", src: "https://www.canva.com/design/DAGbh3FnYwY/vnJMCQ51DPwLyWYGOHiA-g/view?embed" },
  ];

  return (
    <Accordion>
      {items.map((item, index) => (
        <div key={index}>
          <Accordion.Item eventKey={index.toString()} className="mb-1">
            <Accordion.Header className="accordion-bg-color1">
              {index + 1}. {item.title}
            </Accordion.Header>
            <Accordion.Body>
              <IframeWithLoader src={item.src} />
            </Accordion.Body>
          </Accordion.Item>
        </div>
      ))}
    </Accordion>
  );
};

export default NEBInteractiveManual;
