import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getAgencyById } from "../../../api/nebAgencyService";
import { getDataProviderListByAgencyId } from "../../../api/nebDataProviderService";
import { getLookupParameterDetailsByCode } from "../../../api/lookupService";
import { Row, Col, Card, Form, InputGroup, Modal, Button, Table, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as solidIcons from '@fortawesome/free-solid-svg-icons';

function AgencyById() {
  const { id } = useParams();
  const [agencyDetails, setAgencyDetails] = useState(null);
  const [sectorDescription, setSectorDescription] = useState(null);
  const [latestDataProvider, setLatestDataProvider] = useState(null);

  useEffect(() => {
    const fetchAgencyDetails = async () => {
      try {
        const response = await getAgencyById(id);
        setAgencyDetails(response);
        console.log("response: ", response);

        // Get sector description based on sectorId
        const sectorResponse = await getLookupParameterDetailsByCode(
          response.sectorId
        );
        setSectorDescription(sectorResponse);
        console.log("sector description: ", sectorResponse);
      } catch (error) {
        console.error("Error fetching agency details:", error);
      }
    };

    fetchAgencyDetails();
  }, [id]);

  useEffect(() => {
    const fetchLatestDataProvider = async (id) => {
      try {
        const response = await getDataProviderListByAgencyId(id);
        if (response.length > 0) {
          const activeProvider = response.find(
            (provider) => provider.status === "ACTIVE"
          );
          if (activeProvider) {
            setLatestDataProvider(activeProvider);
          } else {
            const pendingProviders = response.filter(
              (provider) => provider.status === "PENDING"
            );
            if (pendingProviders.length > 0) {
              const sortedPendingProviders = pendingProviders.sort(
                (a, b) => new Date(b.updatedDate) - new Date(a.updatedDate)
              );
              setLatestDataProvider(sortedPendingProviders[0]);
            }
          }
        }

        console.log("data provider:", latestDataProvider);
      } catch (error) {
        console.error("Error fetching Data Provider details:", error);
      }
    };

    fetchLatestDataProvider(id);
  }, [id]);

  const isSameAsContactPerson =
    latestDataProvider &&
    agencyDetails &&
    latestDataProvider.name === agencyDetails.cpdName &&
    latestDataProvider.email === agencyDetails.cpdEmail &&
    latestDataProvider.phoneNo === agencyDetails.cpdPhoneNumber;

  return (
    <>
      <Card className="mt-4">  
        <Card.Header>
          <Link
            to={`/neb-agency/neb-agency-form-wizard/${id}`}
            className="btn btn-primary btn-sm"
          >
            Update Details
          </Link>
        </Card.Header>
        <Card.Body>
          {agencyDetails && (
              <div>
                <br />
                <h4>DATA PROVIDER SUMMARY</h4>
                <p>Check all the details before creating data provider's account</p>

                <Badge pill text="white" className="badge-color1 text-md mt-3">
                  <FontAwesomeIcon icon={solidIcons['faTags']} className="mr-2" /> AGENCY DETAILS
                </Badge>
              
                <dl className="row mt-3">
                  <dt className="col-sm-3 col-lg-2">Energy Sector :</dt>
                    <dd className="col-sm-9 col-lg-10">
                      {sectorDescription
                        ? sectorDescription.description
                        : "Loading..."}</dd>
                  <dt className="col-sm-3 col-lg-2">Agency :</dt>
                    <dd className="col-sm-9 col-lg-10">{agencyDetails.agencyName}</dd>
                  <dt className="col-sm-3 col-lg-2">Office Number :</dt>
                    <dd className="col-sm-9 col-lg-10">{agencyDetails.phoneNumber}</dd>
                  <dt className="col-sm-3 col-lg-2">Address :</dt>
                    <dd className="col-sm-9 col-lg-10">
                      {agencyDetails.address1}, {agencyDetails.address2},{" "}
                      {agencyDetails.address3}</dd>
                </dl>

                <Badge pill text="white" className="badge-color1 text-md mt-3">
                  <FontAwesomeIcon icon={solidIcons['faTags']} className="mr-2" /> CONTACT PERSON DETAILS
                </Badge>
              
                <dl className="row mt-3">
                  <dt className="col-sm-3 col-lg-2">Name :</dt>
                    <dd className="col-sm-9 col-lg-10">{agencyDetails.cpdName}</dd>
                  <dt className="col-sm-3 col-lg-2">Email :</dt>
                    <dd className="col-sm-9 col-lg-10">{agencyDetails.cpdEmail}</dd>
                  <dt className="col-sm-3 col-lg-2">Phone Number :</dt>
                    <dd className="col-sm-9 col-lg-10">{agencyDetails.cpdPhoneNumber}</dd>
                </dl>

                <Badge pill text="white" className="badge-color1 text-md mt-3">
                  <FontAwesomeIcon icon={solidIcons['faTags']} className="mr-2" /> LOGIN ID INFORMATION
                </Badge>
              
                <dl className="row mt-3">
                  <dt className="col-sm-3 col-lg-2">Login ID :</dt>
                    <dd className="col-sm-9 col-lg-10">{agencyDetails.loginId}</dd>
                </dl>

                <Badge pill text="white" className="badge-color1 text-md mt-3">
                  <FontAwesomeIcon icon={solidIcons['faTags']} className="mr-2" /> DATA PROVIDER DETAILS
                </Badge>

                {latestDataProvider ? (
                  <div className="row mb-4">
                    {isSameAsContactPerson ? (
                      <div className="font-italic mt-3 pl-3">
                        Data provider details same with contact person details
                      </div>
                    ) : (
                      <dl className="row mt-3">
                        <dt className="col-sm-3 col-lg-2">Name :</dt>
                          <dd className="col-sm-9 col-lg-10">{latestDataProvider.name}</dd>
                        <dt className="col-sm-3 col-lg-2">Email :</dt>
                          <dd className="col-sm-9 col-lg-10">{latestDataProvider.email}</dd>
                        <dt className="col-sm-3 col-lg-2">Phone Number :</dt>
                          <dd className="col-sm-9 col-lg-10">{latestDataProvider.phoneNo}</dd>
                      </dl>
                    )}
                  </div>
                ) : (
                  <div className="font-italic mt-3 pl-3">
                    No data provider available
                  </div>
                )}

                <Badge pill text="white" className="badge-color1 text-md mt-3">
                  <FontAwesomeIcon icon={solidIcons['faTags']} className="mr-2" /> PRODUCT & FORM
                </Badge>
                <br /><br />
              
                <h5>PRODUCT LIST</h5>
                <ol type="1">
                    {agencyDetails.categories.map((category) =>
                      category.products.map((product) => (
                        <>{product.productName != "" ? 
                          (<li key={product.id} className="text-sm">{product.productName}</li>) : ""
                          }
                        </>
                      ))
                    )}
                </ol>
                <br />

                <h5>FORM LIST</h5>
                <ol type="1">
                  {agencyDetails.forms.map((form) => (
                    <li key={form.id} className="mt-2">
                      <div>{form.formDescription}</div>
                      <ul type="disc">
                        {form.formFields.map((formField) => (
                          <li key={formField.id} className="text-sm">
                            {formField.formFieldName}
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
              </ol>

            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default AgencyById;
