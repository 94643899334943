import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { ClayInput } from "@clayui/form";
import ClayButton from "@clayui/button";
import { format } from "date-fns";
import { Form, Card, Pagination, Button, Row, Col } from "react-bootstrap";
import ClayCard from "@clayui/card";
import ClayAlert from "@clayui/alert";
import {
  fetchTicketById,
  updateTicket,
  createTicket,
  addFeedback,
  fetchFeedback,
  sendEmailCreateTicket,
} from "../../../api/ticketService";
import { getLookupParametersByCategory } from "../../../api/lookupService";
import Dropzone from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { uploadFile, createFolder } from "../../../api/fileSystemService";
import { getAgencyByLiferayId } from "../../../api/nebAgencyService";
import { getDataProviderByAgencyId } from "../../../api/nebDataProviderService";
import { toast, Bounce } from "react-toastify";
import useAuthStore from "../../../store/useAuthStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const TicketFormDP = () => {
  const { ticketId } = useParams();
  const [title, setTitle] = useState("");
  const [refNo, setRefNo] = useState("");
  const [description, setDescription] = useState("");
  const [ticketCreatedDate, setTicketCreatedDate] = useState("Not Submitted");
  const [status, setStatus] = useState("NEW");
  const [category, setCategory] = useState("");
  const [feedbackContent, setFeedbackContent] = useState("");
  const [feedbacks, setFeedbacks] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [categories, setCategories] = useState([]); // State for category dropdown
  const [categoryList, setCategoryList] = useState([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();

  const [requesterDetail, setRequesterDetails] = useState({
    name: "loading",
    email: "loading",
  });

  const user = useAuthStore((state) => state.user); // Zustand store for user data
  const logout = useAuthStore((state) => state.logout); // Zustand logout function

  const toastContainerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 1050, // Ensure it's above other elements
    display: "flex",
    justifyContent: "center", // Center the alert content
  };

  const toastContentStyle = {
    width: "100%",
    maxWidth: "1200px", // Optional: limit the maximum width
  };

  // get requester details
  useEffect(() => {
    const fetchRequestorDetails = async () => {
      if (!user?.id) return;

      try {
        console.log("user id profile: ", user.id);
        const agency = await getAgencyByLiferayId(user.id);
        const dp = await getDataProviderByAgencyId(agency.id);
        console.log("requestor dp: ", dp);

        setRequesterDetails({
          name: dp.name,
          email: dp.email,
        });

        console.log("requestor name: ", name);

        console.log("requestor email: ", email);
      } catch (error) {
        console.error("Error fetching requestor details:", error);
        logout(); // Handle token invalidation
      }
    };

    if (user?.id) {
      fetchRequestorDetails();
    }
  }, [user, logout]);

  // Load ticket details if editing
  useEffect(() => {
    if (ticketId) {
      setIsEdit(true);
      const loadTicket = async () => {
        try {
          const ticket = await fetchTicketById(ticketId);
          setTitle(ticket.title);
          setRefNo(ticket.refNo);
          setDescription(ticket.description);
          setStatus(ticket.status);
          setCategory(ticket.category);
          setTicketCreatedDate(formatDate(ticket.createdDate));
        } catch (error) {
          console.error("Error fetching ticket:", error);
        }
      };
      loadTicket();
    }
  }, [ticketId]);

  // Load feedbacks
  const loadFeedbacks = useCallback(async () => {
    try {
      const response = await fetchFeedback(ticketId, page, 5);
      setFeedbacks(response.content);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error("Error fetching feedbacks:", error);
    }
  }, [ticketId, page]);

  useEffect(() => {
    loadFeedbacks();
  }, [loadFeedbacks]);

  // Fetch categories for dropdown
  useEffect(() => {
    const loadCategories = async () => {
      try {
        const response = await getLookupParametersByCategory("FEEDBACK-STATUS"); // Example category
        setCategories(response); // Assuming API returns a list of {code, description}
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const loadCategoryList = async () => {
      try {
        const response = await getLookupParametersByCategory("TICKET-CATEGORY");
        setCategoryList(response);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    loadCategories();
    loadCategoryList();
  }, []);

  // date format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy h:mm:ss aa");
  };

  // Handle form submission for updating a ticket
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Get current month and year
    const currentDate = new Date();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Pad 2 digits
    const year = String(currentDate.getFullYear()).slice(-2); // Last 2 digits of year

    try {
      let ticket;
      let uploadedFiles = [];

      if (!isEdit) {
        // 1. Create the ticket first to get the auto-increment ID
        ticket = await createTicket({ title, description, status, category });
        const id = ticket.id;

        // 2. Generate refNo based on the auto-increment ID
        const refNo = `${String(id).padStart(4, "0")}/${month}/${year}`;
        console.log("Generated refNo:", refNo);

        // 3. Update the ticket with the generated refNo
        await updateTicket(id, { title, description, status, category, refNo });

        // Add refNo to the ticket object
        ticket = { ...ticket, refNo };
      } else {
        // Update logic for existing ticket
        await updateTicket(ticketId, {
          title,
          description,
          status,
          category,
          refNo,
        });
      }

      // 4. Create the folder for this ticket's uploads
      const folderPath = `upload/${ticket ? ticket.id : ticketId}`.replace(
        /\\/g,
        "/"
      );
      const folderCreationResponse = await createFolder(folderPath);
      console.log("Folder created at:", folderCreationResponse.relativePath);

      // 5. Upload the files
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const uploadResponse = await uploadFile(
            folderCreationResponse.relativePath.replace(/\\/g, "/"),
            file
          );
          uploadedFiles.push(uploadResponse.fileName);
        }
      }

      setAlertType("success");
      setAlertMessage(
        isEdit
          ? "Your ticket has been updated successfully."
          : "Your ticket has been submitted successfully."
      );
      setAlertVisible(true);

      // Send email for new tickets
      if (!isEdit) {
        await sendEmailCreateTicket(ticket);
      }

      console.log("List of uploaded files:", uploadedFiles);
    } catch (error) {
      console.error("Error submitting ticket:", error);
      setAlertType("error");
      setAlertVisible(true);
    }

    setTimeout(() => {
      setAlertVisible(false);
      navigate("/neb-data-provider-ticket/list");
    }, 2000);
  };

  // Handle feedback submission
  const handleFeedbackSubmit = async () => {
    try {
      await addFeedback(ticketId, { comment: feedbackContent });
      setFeedbackContent(""); // Reset input
      loadFeedbacks(); // Reload feedbacks after submission
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleDrop = (acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);

    console.log("Files selected for upload:", acceptedFiles);

    if (acceptedFiles.length > 0) {
      acceptedFiles.forEach((file) => {
        console.log("File Name: ", file.name);
        console.log("File Size: ", file.size);
      });
    } else {
      console.log("No files selected");
    }
  };

  const handleFileRemove = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleCancel = () => {
    setAlertType("success");
    setAlertMessage("Your ticket has been cancel.");
    setAlertVisible(true);
    setTimeout(() => {
      navigate(`/neb-data-provider-ticket/list`);
    }, 2000);
  };

  const { name, email } = requesterDetail;

  return (
    <div className="ticket-form container">
      <h2>{isEdit ? "Edit Ticket" : "Submit New Ticket"}</h2>

      {alertVisible && (
        <div style={toastContainerStyle}>
          <div style={toastContentStyle}>
            <ClayAlert
              displayType={alertType}
              title={alertType === "success" ? "Success" : "Error"}
            >
              {alertMessage}
            </ClayAlert>
          </div>
        </div>
      )}
      <div className="card">
        <div className="card-header">
          <div className="card-title"></div>
          <h4>Submit Details</h4>
          <p>
            Please fill in details<span className="text-danger"> * </span>
          </p>
        </div>
        <div className="card-body">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={8}>
                <Form.Group>
                  <Form.Label className="mb-2">
                    Category<span className="text-danger"> * </span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => setCategory(e.target.value)}
                    value={category}
                    required
                    className="mb-3"
                  >
                    <option value="">Select Category</option>
                    {categoryList.map((category) => (
                      <option key={category.code} value={category.code}>
                        {category.description}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ticketTitle" className="mb-2">
                    Title<span className="text-danger"> * </span>
                  </Form.Label>
                  <Form.Control
                    id="ticketTitle"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Enter ticket title"
                    required
                    className="mb-3"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ticketDescription" className="mb-2">
                    Description<span className="text-danger"> * </span>
                  </Form.Label>
                  <Form.Control
                    id="ticketDescription"
                    as="textarea"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Enter ticket description"
                    required
                    className="mb-3"
                  />
                </Form.Group>
                <Card.Body>
                  <Dropzone onDrop={handleDrop} multiple={true}>
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        style={{
                          border: "2px dashed #007bff",
                          borderRadius: "10px",
                          padding: "40px",
                          textAlign: "center",
                          backgroundColor: "#f8f9fa",
                          cursor: "pointer",
                        }}
                      >
                        <input {...getInputProps()} />
                        <p>Drag and drop files here or</p>
                        <Button variant="primary">Browse Files</Button>
                      </div>
                    )}
                  </Dropzone>

                  {files.length > 0 && (
                    <div className="mt-3">
                      <ul>
                        {files.map((file, index) => (
                          <li key={index}>
                            {file.name} - {file.size} bytes{" "}
                            <Button
                              variant="link"
                              onClick={() => handleFileRemove(file.name)}
                            >
                              Remove
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <div className="text-center mt-3">
                    <small className="text-muted">
                      <FontAwesomeIcon icon={faInfoCircle} /> Allowed file
                      types:{" "}
                      <strong>PDF, JPG, PNG, XLSX, DOCX, XLS, DOC</strong> only.
                    </small>
                  </div>
                </Card.Body>
              </Col>

              <Col md={4}>
                <Card.Body>
                  <Card
                    className="p-3 mb-4"
                    style={{ backgroundColor: "#F0F7FF", borderRadius: "10px" }}
                  >
                    <Card.Body>
                      <Card.Title
                        className="text-primary"
                        style={{ fontWeight: "bold" }}
                      >
                        Requester's Details
                      </Card.Title>
                      <Card.Text className="mb-2" style={{ fontSize: "14px" }}>
                        <strong>REQUESTER</strong>
                        <br />
                        {name}
                      </Card.Text>
                      <Card.Text className="mb-2" style={{ fontSize: "14px" }}>
                        <strong>EMAIL</strong>
                        <br />
                        {email}
                      </Card.Text>
                      <Card.Text style={{ fontSize: "14px" }}>
                        <strong>DATE CREATED</strong>
                        <br />
                        {ticketCreatedDate}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Card.Body>
              </Col>
            </Row>

            {isEdit && (
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => setStatus(e.target.value)}
                      value={status}
                    >
                      <option value="">Select Status</option>
                      {categories.map((category) => (
                        <option key={category.code} value={category.code}>
                          {category.description}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            )}

            <div className="d-flex justify-content-end mt-3">
              <ClayButton variant="secondary" onClick={handleCancel}>
                Cancel
              </ClayButton>
              <ClayButton type="submit" className="ml-2">
                {isEdit ? "Update Ticket" : "Submit"}
              </ClayButton>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default TicketFormDP;
