import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { ClaySelect, ClayInput } from '@clayui/form';
import { getSubmissionList, getSubmissionListYear, getDistinctYears } from "../../../api/nebReportService";
import ClayCard from "@clayui/card";
import { FormLabel, Row, Col, Card, Form, InputGroup, Modal, Button, Table, Badge } from 'react-bootstrap';

function VerificationList() {

  //get current year and quarter
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentQuarter = "Q" + Math.ceil((date.getMonth() + 1) / 3);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [allYearSelected, setAllYearSelected] = useState(false);
  const [listLoading, setListLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedQuarter, setSelectedQuarter] = useState();
  const [yearList, setYearList] = useState([]);
  const [quarterList, setQuarterList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  const fetchList = async (yearSelected) => {
    try {

      const result = await getSubmissionListYear(yearSelected);
      //filter out Draft status reports
      const noDraft = result.data.filter(report => report.reportStatus !== "Draft");
      setData(noDraft);
      console.log("ver result: " + JSON.stringify(noDraft, null, 2));
      setFilteredData(noDraft);
      console.log("getlist: " + JSON.stringify(result, null, 2));
    } catch (error) {
      console.error('Error fetching list:', error);
    } finally {
      setListLoading(false);
    }
  };

  useEffect(() => {
    fetchList(currentYear);
    setSelectedYear(currentYear.toString());
    setSelectedQuarter(currentQuarter);
    console.log("selectedYear: " + currentYear + typeof (currentYear));
  }, []);
  
  useEffect(() => {
    const fetchYears = async () => {
      let yearlistResult = await getDistinctYears();
      const yearSet = new Set();
      yearlistResult.data.forEach((year) => {
        yearSet.add(year);
      });
      setYearList(Array.from(yearSet).sort((a, b) => b - a));
    };
  
    fetchYears();
  }, []);
  
  useEffect(() => {
    if (searchKeyword.trim() === "") {
      const filtered = data.filter(
        (item) =>
          (!selectedYear || item.reportPeriodYear === selectedYear) &&
          (!selectedQuarter || item.reportQuarter === selectedQuarter)
      );
      setFilteredData(filtered);
    } else {
      const searchResult = filterReportsForSearch(data, searchKeyword);
      setFilteredData(searchResult);
    }
  }, [searchKeyword, data, selectedYear, selectedQuarter]);  


  const filterReportsForSearch = (reports, keyword) => {
    return reports.filter((report) => {
      const valuesToSearch = [
        report.id || '', // Handle undefined or null values
        report.modifiedDate || '',
        report.reportStatus || '',
        report.reportQuarter || '',
        report.reportPeriodYear || '',
        report.agency?.agencyName || '', // Optional chaining for nested properties
        report.dataProvider?.name || '', // Optional chaining for nested properties
      ].join(' ').toLowerCase();

      return valuesToSearch.includes(keyword.toLowerCase());
    });
  };

  const [goToSubmitNewReport, setGoToSubmitNewReport] = React.useState(false);

  if (goToSubmitNewReport) {
    return <Navigate to="/viewReport" />;
  }


  const handleYearChange = (event) => {
    let currentValue = event.target.value;
    setSelectedYear(currentValue);
    fetchList(currentValue);
    // if (currentValue === "all") {
    //   setAllYearSelected(true)
    //   setSelectedYear(`${yearList[0]} - ${yearList[yearList.length - 1]}`);
    // } else {
    //   setAllYearSelected(false)
    //   setSelectedYear(currentValue);

    // }
  };

  const handleSearchChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const handleQuarterChange = (event) => {
    setSelectedQuarter(event.target.value);
  }

  const formatDateTime = (dateTimeString) => {
    if (dateTimeString || dateTimeString !== "" || dateTimeString !== null || dateTimeString !== undefined) {
      const date = new Date(dateTimeString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      const time = date.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      });

      return `${day}/${month}/${year}`;
    } else {
      return "n/a";
    }
  };

  return (
    <>
      {/* <div className="main-search mb-3 mr-4">
        <div>
          <ClaySelect id="year" value={selectedYear} onChange={handleYearChange}>
            <option value="all">All</option>
            {yearList.map((year, index) => (
              <option key={index} value={year}>
                {year}
              </option>
            ))}
          </ClaySelect>
        </div>
      </div> */}
      
      <Card className="mt-4"> 
        <Card.Header>          
          <InputGroup className="mb-2">
            <InputGroup.Text>Year / Quarter :</InputGroup.Text>
            <Form.Select
              bsPrefix={'form-control'} 
              className="col-sm-3 col-lg-2"
              id="year" 
              value={selectedYear} 
              onChange={handleYearChange}
              >
              {yearList.map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
            </Form.Select>

            <Form.Select
              bsPrefix={'form-control'} 
              className="col-sm-3 col-lg-2"
              id="quarter" 
              value={selectedQuarter} 
              onChange={handleQuarterChange}>
              <option value="Q1">Q1</option>
              <option value="Q2">Q2</option>
              <option value="Q3">Q3 </option>
              <option value="Q4">Q4</option>
            </Form.Select>
          </InputGroup>

          <Form.Group className="row">
            <Col className="col-12">
              <Form.Control 
                value={searchKeyword}
                onChange={(event) => handleSearchChange(event)}
                placeholder="Search keyword..."
                />
            </Col>
          </Form.Group>
        </Card.Header>
        <Card.Body>
          {listLoading ? <p>Loading...</p> :
            <Table responsive bordered hover size="sm" className="mt-1">
              <thead>
                <tr>
                  <th style={{width:'3em'}}>No</th>
                  <th>Agency</th>
                  <th>Status</th>
                  <th>Approver</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length === 0 ? <tr><td colSpan={5}>No report found for {selectedQuarter}, {selectedYear} or from the search query.</td></tr> : filteredData.map((report, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{report.agency.agencyName}</td>
                    <td>
                      <span
                        className={`badge ${report.reportStatus === "Approved"
                          ? "bg-success"
                          : report.reportStatus === "Pending for Approval"
                            ? "bg-warning"
                            : report.reportStatus === "Pending for Amendment"
                              ? "bg-danger"
                              : "bg-secondary"
                          }`}
                      >
                        {report.reportStatus}
                      </span>
                    </td>
                    <td>Admin</td>

                    <td>
                      <div>
                        <Link
                          to={`/neb-verification/neb-verification-view/pending/${report.id}/${report.agency.id}/${report.reportQuarter}/${report.reportPeriodYear}/${report.reportStatus}`}
                          className=""
                        >
                          View
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }
        </Card.Body>
      </Card>

    </>
  );
}

export default VerificationList;